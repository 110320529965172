<template>
    <div class="sidebar-widget-list">
        <ul class="category" style="padding: 0; list-style: none; margin: 0;">
            <li v-if="filteredMarcas.length === 0">No hay resultados</li>
            <li class="cate-item" v-for="(marca, index) in paginatedMarcas" :key="index">
                <router-link :to="{ name: 'StorePage', query: { marca: marca.slug } }">
                    <i class="flaticon-next"></i>
                    {{ marca.nombre }}
                </router-link>
                <!-- <router-link :to="{ name: 'StorePageWithBrand', params: { marca: marca } }">
                    <i class="flaticon-next"></i>
                    {{ marca }}
                </router-link> -->
            </li>
        </ul>
        <div class="pagination-controls">
            <span @click="previousPage" :class="{ disabled: currentPage === 1 }">
                <i class="fas fa-chevron-left"></i>
            </span>
            <span @click="nextPage" :class="{ disabled: currentPage === totalPages }">
                <i class="fas fa-chevron-right"></i>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BrandNavigation',
    props: ['searchQuery', 'marca'],
    data() {
        return {
            marcas: [],
            currentPage: 1,
            marcaPage: 5,
        };
    },
    computed: {
        filteredMarcas() {
            const query = this.searchQuery;
            return this.marcas.filter(marca =>marca.slug.includes(query));
        },
        paginatedMarcas() {
            const start = (this.currentPage - 1) * this.marcaPage;
            const end = start + this.marcaPage;
            return this.filteredMarcas.slice(start, end);
        },
        totalPages() {
            return Math.ceil(this.filteredMarcas.length / this.marcaPage);
        }
    },
    created() {
        this.fetchMarcas();
    },
    methods: {
        fetchMarcas() {
            fetch(`${this.$apiUrl}/listar-marcas-productos`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    this.marcas = data;
                })
                .catch(error => {
                    console.error('Error al cargar datos:', error);
                });
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        normalizeText(text) {
            return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        }
    }
};
</script>

<style scoped>
.pagination-controls {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.pagination-controls span {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
    text-decoration: none;
}

.pagination-controls span i {
    font-size: 1em;
}

.pagination-controls span.disabled {
    cursor: not-allowed;
    color: gray;
}
</style>
