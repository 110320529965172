<template>
    <div class="sidebar-widget">
        <div class="widget-title">
            <h3 class="title">Últimas Noticias</h3>
        </div>
        <div class="recent-posts">
            <ul style="padding: 0; list-style: none; margin: 0;">
                <li v-for="(news, index) in ultimatenews" :key="index">
                    <a :href="`/news/${news.slug}`" class="post-link">
                        <div class="post-thumb">
                            <ImageWeb :src="'/' + news.imagen" alt="Imagen de la noticia" class="limited-img" width="100px"/>
                        </div>
                        <div class="post-text">
                            <h4 class="title">{{ news.titulo }}</h4>
                            <span class="post-meta"><i class="far fa-calendar-alt"></i> {{ formatDate(news.created_at) }}</span>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import ImageWeb from '@/components/ImagePublicWeb.vue';

export default {
    components: {
        ImageWeb,
    },
    data() {
        return {
            ultimatenews: []
        };
    },
    created() {
        this.cargarUltimasNoticias();
    },
    methods: {
        cargarUltimasNoticias() {
            fetch(`${this.$apiUrl}/listar-noticias-ultimos`)
                .then(response => response.json())
                .then(data => {
                    this.ultimatenews = data.slice(0, 3); 
                })
                .catch(error => {
                    console.error('Error al cargar últimas noticias:', error);
                });
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            return date.toLocaleDateString();
        }
    }
};
</script>

<style scoped>

.sidebar-widget {
    margin-top: 0px;
}
</style>