<template>
    <Preloader v-if="loading" />
    <div v-else>
        <div class="banner_outer" ref="headerSection">
            <NavbarMenu></NavbarMenu>
            <figure class="banner-sideshape mb-0">
                <Image src="/banner/banner-sideshape.png" alt="" class="img-fluid"
                    aria-label="Ir a la página principal" />
            </figure>
            <section class="banner-section">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
                            <div class="banner_content">
                                <h1 class="text-white typing">{{ home.titulo }}</h1>
                                <p class="text-white">{{ home.subtitulo }}</p>
                                <div class="banner-button">
                                    <ButtonGradient buttonText="Leer Más" :button1="true" :href="'/portfolio'" />
                                    <ButtonGradient buttonText="Contáctanos" :href="'/contact-us'" />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-12 col-sm-12 col-12">
                            <div class="banner_wrapper" data-aos="fade-right">
                                <figure class="mb-0 banner-image">
                                    <ImageWeb :src="'/' + home.imagen" alt="Imagen Mylcom"
                                        class="img-fluid fixed-size" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <figure class="banner-sideshape2 mb-0">
                    <Image src="/banner/banner-sideshape2.png" alt="" class="img-fluid" />
                </figure>
            </section>
        </div>
        <section class="counter-section position-relative" ref="carouselSection">
            <div class="container">
                <figure class="counter-sideimage mb-0">
                    <Image src="/counter-sideimage.png" alt="" class="img-fluid" />
                </figure>
                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner" style="padding: 0 5px">
                        <div v-for="(group, index) in groupedCounterBoxes" :key="index" class="carousel-item"
                            :class="{ active: index === 0 }">
                            <div class="row">
                                <CounterBox v-for="(item, subIndex) in group" :key="subIndex" :counterClass="item.class"
                                    :imageSrc="'/' + item.icono" :title="truncateText(item.titulo)"
                                    :route="'/portfolio/' + item.slug" :applyCustomStyle="true" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="videosection" data-aos="fade-up" ref="videoSection">
            <div class="container">
                <div class="row position-relative">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="position-relative">
                            <a class="popup-vimeo" :href="home.video" @click.prevent="openModal">
                                <figure class="mb-0 vediosession">
                                    <ImageWeb class="thumb img-fluid" style="cursor: pointer; border-radius: 2.7rem"
                                        :src="'/' + home.imagen_video"
                                        alt="Ver video sobre los 'casos de éxitos de MYL comunicaciones'" />
                                </figure>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="study-section" ref="caseStudySection">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="study_content">
                            <h2>Conoce nuestras soluciones en comunicaciones 4.0</h2>
                        </div>
                    </div>
                </div>
                <div class="row aos-init aos-animate" data-aos="fade-up">
                    <CaseBox v-for="solucion in soluciones" :key="solucion.id" :image-url="'/' + solucion.imagen_inicio"
                        :title="solucion.titulo" :subtitle="solucion.titulo"
                        :additional-info="'Más detalles sobre ' + solucion.titulo"
                        :link-url="'/solution-details/' + solucion.slug" />
                </div>
            </div>
        </section>
        <section class="news-section" ref="newsSection">
            <div class="container">
                <figure class="counter-sideimage mb-0">
                    <Image src="/counter-sideimage.png" alt="" class="img-fluid" />
                </figure>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="heading">
                            <h2>Descubre Nuestro Contenido</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-8" v-if="newsItems.length > 0">
                        <div class="main-item">
                            <a :href="`/news/${newsItems[0].slug}`">
                                <figure class="image-wrapper">
                                    <ImageWeb :src="'/' + newsItems[0].imagen" alt="News Image" class="main-image" />
                                </figure>
                            </a>
                            <div class="overlay">
                                <span class="category">Noticias</span>
                                <h3 class="news-title">{{ newsItems[0].titulo }}</h3>
                                <p class="date">{{ newsItems[0].fecha_texto }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4" v-if="blogs.length > 0">
                        <div class="secondary-item">
                            <a :href="`/blog-post/${blogs[0].slug}`">
                                <figure class="image-wrapper">
                                    <ImageWeb :src="'/' + blogs[0].imagen_card" alt="Blog Image"
                                        class="secondary-image" />
                                </figure>
                            </a>
                            <div class="overlay-secondary">
                                <span class="category">Blogs</span>
                                <h3 class="news-title-blog">{{ blogs[0].titulo }}</h3>
                                <p class="news-date">{{ blogs[0].fecha_texto }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8" v-if="newsItems.length > 1">
                        <div class="main-item">
                            <a :href="`/news/${newsItems[1].slug}`">
                                <figure class="image-wrapper">
                                    <ImageWeb :src="'/' + newsItems[1].imagen" alt="News Image" class="main-image" />
                                </figure>
                            </a>
                            <div class="overlay">
                                <span class="category">Noticias</span>
                                <h3 class="news-title">{{ newsItems[1].titulo }}</h3>
                                <p class="date">{{ newsItems[1].fecha_texto }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4" v-if="blogs.length > 1">
                        <div class="secondary-item">
                            <a :href="`/blog-post/${blogs[1].slug}`">
                                <figure class="image-wrapper">
                                    <ImageWeb :src="'/' + blogs[1].imagen_card" alt="Blog Image"
                                        class="secondary-image" />
                                </figure>
                            </a>
                            <div class="overlay-secondary">
                                <span class="category">Blogs</span>
                                <h3 class="news-title-blog">{{ blogs[1].titulo }}</h3>
                                <p class="news-date">{{ blogs[1].fecha_texto }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <FooterMenu></FooterMenu>
    </div>
</template>

<script>
import NavbarMenu from '../components/NavbarMenu.vue';
import FooterMenu from '../components/FooterMenu.vue';
import CounterBox from '../view/components/widgets/CounterBox.vue';
import CaseBox from '../view/components/widgets/CaseBox.vue';
import Image from '@/components/ImagePublic.vue';
import ImageWeb from '@/components/ImagePublicWeb.vue';
import ButtonGradient from '@/view/components/widgets/ButtonGradient.vue';
import Preloader from '@/components/Preloader.vue';
import { updateMetaTags } from '../metaUtils';

/* global $ */

export default {
    name: 'HomePage',
    components: {
        NavbarMenu,
        FooterMenu,
        CounterBox,
        CaseBox,
        Image,
        ButtonGradient,
        ImageWeb,
        Preloader
    },
    data() {
        return {
            loading: true,
            loadingFinished1: false,
            loadingFinished2: false,
            minLoadingTime: null,
            newsItems: [],
            blogs: [],
            typedText: "",
            home: {
                titulo: '',
                subtitulo: '',
                imagen: '',
                imagen_video: '',
                video: ''
            },
            isTablet: false,
            counterBoxes: [],
            soluciones: [] // Agregamos este estado para las soluciones
        };
    },
    created() {
        this.minLoadingTime = new Promise((resolve) => {
            setTimeout(resolve, 750);
        });
        this.cargarInicio();
        this.cargarNoticiasYBlogs();
        this.cargarCounterBoxes();
        this.cargarSoluciones(); // Llamamos al método para cargar soluciones
    },
    mounted() {
        this.checkIfTablet();
        window.addEventListener('resize', this.checkIfTablet);
    },
    unmounted() { // Usar 'unmounted' para Vue 3 o 'destroyed' para Vue 2
        window.removeEventListener('resize', this.checkIfTablet);
    },
    methods: {
        updateMeta(home) {
            const routeMeta = {
                title: 'Mylcom - Blog',
                description: home.titulo,
                image: `${this.$apiImgWeb}/images/web/${home.imagen}`, // Construye la URL completa de la imagen
                canonical: `${window.location.origin}${this.$route.fullPath}`,

            };
            updateMetaTags({ meta: routeMeta });
        },
        checkIfTablet() {
            this.isTablet = window.innerWidth >= 768 && window.innerWidth < 1024;
        },
        cargarInicio() {
            fetch(`${this.$apiUrl}/inicio/1`)
                .then((response) => response.json())
                .then((data) => {
                    this.home.titulo = data.titulo;
                    this.home.subtitulo = data.subtitulo;
                    this.home.imagen = data.imagen;
                    this.home.imagen_video = data.imagen_video;
                    this.home.video = data.video;
                    this.updateMeta(data); // Actualiza las etiquetas meta dinámicamente

                })
                .catch((error) => {
                    console.error('Error al cargar datos:', error);
                })
                .finally(() => {
                    this.loadingFinished1 = true;
                    this.checkLoading();
                });
        },
        cargarNoticiasYBlogs() {
            const noticiasPromise = fetch(`${this.$apiUrl}/listar-noticias-selected`)
                .then(response => response.json())
                .then(data => {
                    this.newsItems = data;
                })
                .catch(error => {
                    console.error('Error al cargar noticias:', error);
                });

            const blogsPromise = fetch(`${this.$apiUrl}/listar-posts-selected`)
                .then(response => response.json())
                .then(data => {
                    this.blogs = data;
                })
                .catch(error => {
                    console.error('Error al cargar blogs:', error);
                });

            Promise.all([noticiasPromise, blogsPromise])
                .finally(() => {
                    this.loadingFinished2 = true;
                    this.checkLoading();
                });
        },
        cargarCounterBoxes() {
            fetch(`${this.$apiUrl}/listar-cards-all`)
                .then((response) => response.json())
                .then((data) => {
                    this.counterBoxes = data;
                })
                .catch((error) => {
                    console.error('Error al cargar counter boxes:', error);
                });
        },
        cargarSoluciones() {
            fetch(`${this.$apiUrl}/list-cards-selected`)
                .then((response) => response.json())
                .then((data) => {
                    this.soluciones = data;
                })
                .catch((error) => {
                    console.error('Error al cargar soluciones:', error);
                });
        },
        checkLoading() {
            if (this.loadingFinished1 && this.loadingFinished2) {
                this.minLoadingTime.then(() => {
                    this.loading = false;
                    this.$nextTick(() => {
                        $('#carouselExampleControls').carousel(); // Inicializar el carrusel aquí
                    });
                });
            }
        },
        truncateText(text) {
            const maxLength = window.innerWidth <= 768 ? 20 : 37; // 20 para móvil, 37 para escritorio
            return text.length <= maxLength ? text : text.substring(0, maxLength) + '...';
        },
        openModal(event) {
            event.preventDefault();
            const videoUrl = this.getVideoEmbedUrl(this.home.video);

            // Inicializar el modal con la URL dinámica
            $.magnificPopup.open({
                items: {
                    src: videoUrl
                },
                type: 'iframe',
                iframe: {
                    patterns: {
                        youtube: {
                            index: 'youtube.com/',
                            id: (url) => {
                                const regExp = /(?:youtu\.be\/|youtube\.com\/(?:v\/|embed\/|watch\?v=|watch\?.+&v=))((\w|-){11})/;
                                const match = url.match(regExp);
                                return match ? match[1] : null;
                            },
                            src: 'https://www.youtube.com/embed/%id%?autoplay=1'
                        }
                    }
                }
            });
        },
        getVideoEmbedUrl(url) {
            const regExp = /(?:youtu\.be\/|youtube\.com\/(?:v\/|embed\/|watch\?v=|watch\?.+&v=))((\w|-){11})/;
            const match = url.match(regExp);
            const videoId = match ? match[1] : null;
           
            return videoId ? `https://www.youtube.com/embed/${videoId}?autoplay=1` : url;
        }
    },
    computed: {
        groupedCounterBoxes() {
            let itemsPerGroup = this.isTablet ? 3 : 4;
            let totalItems = [...this.counterBoxes];

            // Duplicar elementos para completar los grupos
            if (totalItems.length % itemsPerGroup !== 0) {
                let itemsToDuplicate = itemsPerGroup - (totalItems.length % itemsPerGroup);
                for (let i = 0; i < itemsToDuplicate; i++) {
                    totalItems.push(totalItems[i % totalItems.length]);
                }
            }

            const groups = [];
            for (let i = 0; i < totalItems.length; i += itemsPerGroup) {
                groups.push(totalItems.slice(i, i + itemsPerGroup));
            }
            return groups;
        }
    }
}
</script>


<style scoped>
.fixed-size {
    max-width: 580px;
    max-height: 685px;
    width: 100%;
    object-fit: contain;
    display: block;
    margin: 0 auto;
    margin-top: 1rem;
}

.card {
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
    opacity: 0;
    animation: fadeInUp 1s ease forwards;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(50px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.snap-container {
    height: 100vh;
    overflow: auto;
    scroll-snap-type: y mandatory;
}

.snap-section {
    scroll-snap-align: start;
    min-height: 60vh;
}

.banner_wrapper {
    position: relative;
}

.news-section {
    background-color: #eafcfe;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 109px 0 40px;
    position: relative;
    overflow: hidden;
}

.news-section .heading {
    text-align: center;
    margin-bottom: 30px;
}

.main-item {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    margin-bottom: 30px;
    max-height: 350px;
}

.main-item:hover {
    transform: scale(1.05);
}

.image-wrapper {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}


.main-image {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
}

.secondary-item {
    max-height: 350px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    margin-bottom: 20px;
}

.secondary-item:hover {
    transform: scale(1.05);
}

.secondary-image {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}

.overlay,
.overlay-secondary {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
}

.category {
    display: inline-block;
    background: linear-gradient(to right, #54abc8, var(--e-global-color-principal-alternative));
    color: #f9f9f9;
    padding: 2px 5px;
    margin-bottom: 5px;
    border-radius: 20px;
}

.overlay h3,
.overlay-secondary .news-title {
    margin: 0;
    color: #f9f9f9;
}

.news-title-blog {
    margin: 0;
    color: #f9f9f9;
    font-size: 20px;
    line-height: 1.4;
}

.overlay p,
.overlay-secondary p {
    margin-top: 5px;
    font-size: 0.9em;
    color: #fff;
}

@media screen and (max-width: 768px) {

    .main-item,
    .secondary-item {
        height: auto;
    }

    .main-item,
    .secondary-item {
        width: 100%;
        margin-bottom: 20px;
        height: auto;
    }

    .main-image,
    .secondary-image {
        width: 100%;
        height: auto;
    }

    .overlay,
    .overlay-secondary {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 10px;
        background: rgba(0, 0, 0, 0.5);
        color: white;
        text-align: center;
    }

    .category {
        display: inline-block;
        background: linear-gradient(to right, #54abc8, var(--e-global-color-principal-alternative));
        color: #f9f9f9;
        padding: 2px 5px;
        margin-bottom: 5px;
        border-radius: 20px;
    }

    .overlay h3,
    .overlay-secondary .news-title {
        margin: 0;
        color: #f9f9f9;
        font-size: 16px;
    }

    .overlay p,
    .overlay-secondary p {
        margin-top: 5px;
        font-size: 14px;
        color: #fff;
    }
}

@media screen and (max-width: 430px) {

    .main-item,
    .secondary-item {
        width: calc(100% - 20px);
        margin: 0 auto 20px;
        height: auto;
    }

    .main-image,
    .secondary-image {
        width: 100%;
        height: auto;
    }

    .overlay,
    .overlay-secondary {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 10px;
        background: rgba(0, 0, 0, 0.5);
        color: white;
        text-align: center;
    }

    .category {
        display: inline-block;
        background: linear-gradient(to right, #54abc8, var(--e-global-color-principal-alternative));
        color: #f9f9f9;
        padding: 2px 5px;
        margin-bottom: 5px;
        border-radius: 20px;
    }

    .overlay h3,
    .overlay-secondary .news-title {
        margin: 0;
        color: #f9f9f9;
        font-size: 16px;
    }

    .overlay p,
    .overlay-secondary p {
        margin-top: 5px;
        font-size: 14px;
        color: #fff;
    }
}

@media (min-width: 1200px) and (max-width: 1559px) {
    .banner-image .fixed-size {
        position: relative;
        top: -130px;
    }

    .banner-section {
        padding: 74px 115px 50px;
    }
}
</style>
