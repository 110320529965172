<template>
    <!-- <div class="sidebar-widget sidebar-widget-1">
        <form class="search-form" @submit.prevent>
            <input type="text" v-model="searchQuery" placeholder="Buscar" />
            <button type="submit"><i class="fas fa-search"></i></button>
        </form>
    </div> -->
    <UltimateNews></UltimateNews>
</template>

<script>

import UltimateNews from './navigation/UltimateNews';

export default {
    name: 'SidebarBlog',
    components: {
        UltimateNews
    },
    data() {
        return {
            searchQuery: ''
        }
    },
}

</script>

<style scoped>

.search-bar {
    margin-bottom: 20px;
}

.search-bar input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
}

</style>