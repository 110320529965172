<template>
    <Preloader v-if="loading" />
    <div v-else>
        <div class="sub-banner">
            <NavbarMenu></NavbarMenu>
            <figure class="sub-bannersideshape mb-0">
                <Image src="/banner/sub-bannersideshape.png" alt="" class="img-fluid" />
            </figure>
            <section class="banner-section banner-section-about">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 col-md-6 col-sm-12 col-12">
                            <div class="banner_content" data-aos="fade-right">
                                <h1 class="text-white custom-standard-title">{{ portafolio.titulo }}</h1>
                                <p class="text-white custom-standard-subtitle">{{ portafolio.subtitulo }}</p>
                                <div class="box custom-standard-box">
                                    <span class="mb-0">Inicio</span><i class="first fa-regular fa-angle-right"></i><i
                                        class="second fa-regular fa-angle-right"></i><span class="mb-0 box_span">{{
                                            portafolio.titulo }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-sm-12 col-12">
                            <div class="banner_wrapper">
                                <figure class="mb-0  d-flex justify-content-center align-items-center sub-bannerimage-about">
                                    <ImageWeb :src="'/' + portafolio.imagen" alt=""  class="img-fluid fixed-size-blog" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <figure class="sub-bannersideshape2 mb-0">
                    <Image src="/banner/sub-bannersideshape2.png" alt="" class="img-fluid" />
                </figure>
            </section>
        </div>
        <section v-for="(section, sectionIndex) in sections" :key="section.id" class="provide-section"
            :class="sectionIndex % 2 === 0 ? '' : 'bg-white'">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="provide_content aos-init aos-animate" data-aos="fade-right">
                            <h2>{{ section.nombre }}</h2>
                        </div>
                    </div>
                </div>
                <div class="service_contentbox">
                    <div class="row justify-content-center">
                        <div v-for="(card, itemIndex) in section.cards" :key="card.id"
                            class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <router-link :to="`/portfolio/${card.slug}`" class="service-box box-mb" :class="itemIndex">
                                <div class="box-image">
                                    <figure class="service-reboticon">
                                        <ImageWeb :src="'/' + card.icono" style="width: 68%;" alt=""
                                            class="img-fluid" />
                                    </figure>
                                </div>
                                <div class="box-content">
                                    <h4 aria-level="3">{{ card.titulo }}</h4>
                                    <p class="text-size-16">{{ card.resumen }}</p>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <FooterMenu></FooterMenu>
    </div>
</template>


<script>

import NavbarMenu from '@/components/NavbarMenu.vue'
import FooterMenu from '@/components/FooterMenu.vue'
import Image from '@/components/ImagePublic.vue'
import ImageWeb from '@/components/ImagePublicWeb.vue'
import Preloader from '@/components/Preloader.vue';
import { updateMetaTags } from '../metaUtils';

export default {
    name: 'PortafolioPage',
    components: {
        NavbarMenu,
        FooterMenu,
        Image,
        ImageWeb,
        Preloader,
    },
    data() {
        return {
            portafolio: {
                titulo: '',
                subtitulo: '',
                imagen: ''
            },
            sections: [],
            loading: true,
            loadingFinished1: false,
            loadingFinished2: false,
            minLoadingTime: null,
        }
    },
    created() {
        this.minLoadingTime = new Promise((resolve) => {
            setTimeout(resolve, 750);
        });
        this.cargarHeader();
        this.cargarSecciones();
    },
    methods: {
        updateMeta(portafolio) {
            const routeMeta = {
                title: 'Mylcom - Portfolio',
                description: portafolio.subtitulo,
                image: `${this.$apiImgWeb}/images/web/${portafolio.imagen}`, // Construye la URL completa de la imagen
                canonical: `${window.location.origin}${this.$route.fullPath}`,

            };
            updateMetaTags({ meta: routeMeta });
        },
        cargarHeader() {
            fetch(`${this.$apiUrl}/mostrar-portafolio/1`)
                .then((response) => { return response.json(); })
                .then((data) => {
                    this.portafolio.titulo = data.titulo;
                    this.portafolio.subtitulo = data.subtitulo;
                    this.portafolio.imagen = data.imagen;
                    this.updateMeta(data); // Actualiza las etiquetas meta dinámicamente
                })
                .catch((error) => {
                    console.error('Error al cargar datos:', error);
                })
                .finally(() => {
                    this.loadingFinished1 = true;
                    this.checkLoading();
                });

        },
        cargarSecciones() {
            fetch(`${this.$apiUrl}/listar-secciones-cards`)
                .then((response) => { return response.json(); })
                .then((data) => {
                    this.sections = data;
                })
                .catch((error) => {
                    console.error('Error al cargar datos:', error);
                })
                .finally(() => {
                    this.loadingFinished2 = true;
                    this.checkLoading();
                });
        },
        checkLoading() {
            if (this.loadingFinished1 && this.loadingFinished2) {
                this.minLoadingTime.then(() => {
                    this.loading = false;
                });
            }
        },
        descargarPDF() {
            const rutaPDF = "/assets/images/portafolio/C30527-ESPECIFICACIONES-TECNICAS-c.pdf";
            const nombreArchivo = "C30527-ESPECIFICACIONES-TECNICAS-c.pdf";

            fetch(rutaPDF)
                .then(response => response.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', nombreArchivo);

                    // Agregar el enlace al DOM y simular el clic
                    document.body.appendChild(link);
                    link.click();

                    // Limpiar el enlace del DOM
                    document.body.removeChild(link);
                })
                .catch(error => {
                    console.error('Error al descargar el archivo PDF:', error);
                });
        },
    }
}


</script>
