<template>
    <Preloader v-if="loading" />
    <div v-else>
        <div class="sub-banner">
            <NavbarMenu></NavbarMenu>
            <figure class="sub-bannersideshape mb-0">
                <Image src="/banner/sub-bannersideshape.png" alt="" class="img-fluid" />
            </figure>
            <section class="banner-section banner-section-about">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 col-md-6 col-sm-12 col-12">
                            <div class="banner_content" data-aos="fade-right">
                                <h1 class="text-white custom-standard-title">{{ portafolio.titulo }}</h1>
                                <p class="text-white custom-standard-subtitle">{{ portafolio.subtitulo }}</p>
                                <div class="box custom-standard-box">
                                    <span class="mb-0">Inicio</span>
                                    <i class="first fa-regular fa-angle-right"></i>
                                    <i class="second fa-regular fa-angle-right"></i>
                                    <span class="mb-0 box_span">{{ portafolio.titulo }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-sm-12 col-12">
                            <div class="banner_wrapper">
                                <figure
                                    class="mb-0 d-flex justify-content-center align-items-center sub-bannerimage-about">
                                    <ImageWeb :src="'/' + portafolio.imagen" alt="" class="img-fluid fixed-size-blog" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <figure class="sub-bannersideshape2 mb-0">
                    <Image src="/banner/sub-bannersideshape2.png" alt="" class="img-fluid" />
                </figure>
            </section>
        </div>
        <div class="section notice-details-section">
            <div class="container">
                <div class="blog-details-wrap">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="blog-sidebar">
                                <SidebarCards></SidebarCards>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="blog-details-post">
                                <div class="single-blog">
                                    <div class="blog-img">
                                        <a href="#">
                                            <ImageWeb :src="'/' + cardDetail.imagen" alt="img-fluid"
                                                class="limited-img centered-image" />
                                        </a>
                                    </div>
                                    <div class="blog-content">
                                        <div class="blog-meta">
                                            <span><i class="fas fa-user"></i> <a href="#" id="post-author">Mylcom</a></span>
                                        </div>
                                        <h3 class="title" aria-level="2">{{ cardDetail.titulo }}</h3>
                                        <div class="ql-editor" v-html="cardDetail.contenido"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="producs-rel section-padding">
            <div v-if="products.length > 0" class="container">
                <h2 class="text-center mt-5 mb-4">Productos Relacionados</h2>
                <div id="productCarousel" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">
                        <div v-for="(group, index) in groupedProducts" :key="index"
                            :class="['carousel-item', { active: index === 0 }]">
                            <div class="row justify-content-center px-4 mb-2">
                                <div v-for="(product, subIndex) in group" :key="subIndex"
                                    class="col-12 col-sm-6 col-md-6 col-lg-3 d-flex justify-content-center">
                                    <ProductItem :image-src="product.imageSrc" :product-name="product.productName"
                                        :productSlug="product.productSlug"></ProductItem>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <a class="carousel-control-prev" href="#productCarousel" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#productCarousel" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a> -->
                </div>
            </div>
        </div>
        <FooterMenu></FooterMenu>
    </div>
</template>


<script>
import NavbarMenu from '@/components/NavbarMenu.vue';
import FooterMenu from '@/components/FooterMenu.vue';
import Image from '@/components/ImagePublic.vue';
import Preloader from '@/components/Preloader.vue';
import ImageWeb from '@/components/ImagePublicWeb.vue';
import SidebarCards from '@/components/SidebarCards.vue';
import ProductItem from '@/view/components/widgets/ProductItem.vue';
/* global $ */

export default {
    props: {
        slug: {
            type: String,
            required: true
        }
    },
    name: 'CardDetail',
    components: {
        NavbarMenu,
        FooterMenu,
        Image,
        Preloader,
        ImageWeb,
        SidebarCards,
        ProductItem
    },
    data() {
        return {
            loading: true,
            portafolio: {
                titulo: '',
                subtitulo: '',
                imagen: ''
            },
            products: [
            ],
            cardDetail: {},
            loadingFinished1: false,
            loadingFinished2: false,
            minLoadingTime: null,
        };
    },
    watch: {
        slug: {
            immediate: true,
            handler() {
                this.cargarCardDetalle();
            }
        }
    },
    created() {
        this.minLoadingTime = new Promise((resolve) => {
            setTimeout(resolve, 750);
        });
        this.cargarHeader();
        this.cargarCardDetalle();
        window.addEventListener('resize', this.updateViewport);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateViewport);
    },
    methods: {
        updateViewport() {
            this.$forceUpdate();
        },
        cargarCardDetalle() {
            fetch(`${this.$apiUrl}/mostrar-card-portafolio/${this.slug}`)
                .then(response => response.json())
                .then(data => {
                    this.cardDetail = data;
                    this.products = data.productos.map(product => ({
                        imageSrc: product.pathUrl,
                        productName: product.nombre,
                        productSlug: product.slug
                    }));
                })
                .catch(error => {
                    console.error('Error al cargar datos:', error);
                })
                .finally(() => {
                    this.loadingFinished2 = true;
                    this.checkLoading();
                });
        },
        cargarHeader() {
            fetch(`${this.$apiUrl}/mostrar-portafolio/1`)
                .then(response => response.json())
                .then(data => {
                    this.portafolio.titulo = data.titulo;
                    this.portafolio.subtitulo = data.subtitulo;
                    this.portafolio.imagen = data.imagen;
                })
                .catch(error => {
                    console.error('Error al cargar datos:', error);
                })
                .finally(() => {
                    this.loadingFinished1 = true;
                    this.checkLoading();
                });
        },
        checkLoading() {
            if (this.loadingFinished1 && this.loadingFinished2) {
                this.minLoadingTime.then(() => {
                    this.loading = false;
                    this.$nextTick(() => {
                        $('#productCarousel').carousel();
                    });
                });
            }
        }
    },
    computed: {
        isTablet() {
            return window.innerWidth >= 577 && window.innerWidth <= 768;
        },
        isMobile() {
            return window.innerWidth <= 576;
        },
        groupedProducts() {
            let itemsPerGroup = this.isTablet ? 2 : (this.isMobile ? 1 : 4);
            let totalItems = [...this.products];

            // Duplicar elementos para completar los grupos
            if (totalItems.length % itemsPerGroup !== 0) {
                let itemsToDuplicate = itemsPerGroup - (totalItems.length % itemsPerGroup);
                for (let i = 0; i < itemsToDuplicate; i++) {
                    totalItems.push(totalItems[i % totalItems.length]);
                }
            }

            const groups = [];
            for (let i = 0; i < totalItems.length; i += itemsPerGroup) {
                groups.push(totalItems.slice(i, i + itemsPerGroup));
            }
            return groups;
        }
    }
}
</script>

<style scoped>
@media (max-width: 576px) {
    .carousel-inner .carousel-item .row .col-12 {
        max-width: 100%;
        flex: 0 0 100%;
        display: flex;
        justify-content: center;
    }
}

@media (min-width: 577px) and (max-width: 768px) {
    .carousel-inner .carousel-item .row .col-sm-6 {
        max-width: 50%;
        flex: 0 0 50%;
        display: flex;
        justify-content: center;
    }
}

@media (min-width: 769px) {
    .carousel-inner .carousel-item .row .col-lg-3 {
        max-width: 25%;
        flex: 0 0 25%;
    }
}

.notice-details-section {
    padding-top: 88px;
}

img {
    max-width: 100%;
    height: auto;
}

.product-placeholder {
    height: 367px;
    width: 258px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.centered-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    margin-bottom: 2.5rem;
}

.social-media {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 0 .75rem;
    margin-bottom: 1.25rem;
    margin-top: .375rem;
}

.product-item {
    /* height: 367px; */
    height: 410px;
    width: 240px;
}
</style>
