import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import { createMetaManager } from 'vue-meta';
import 'aos/dist/aos.css';
import './token'




AOS.init();

const appInstance = createApp(App);
const metaManager = createMetaManager();


//URL DE API
//const apiUrl = 'https://admin.mylcom.com.pe/api';
const apiUrl = process.env.VUE_APP_API_BASE_URL;
//const apiUrl = 'http://192.168.20.122:8080/api';



//URL DE Img
const apiImg = '/assets/images';
//const apiImgWeb = 'http://192.168.20.123:8000/assets';
//const apiImgWeb = 'http://192.168.20.122:8080/assets';
const apiImgWeb = process.env.VUE_APP_API_BASE_URL_ASSETS;
//const apiImgWeb = 'http://144.126.152.247:5170/assets';



// Hacer que la dirección base de la API esté disponible globalmente
appInstance.config.globalProperties.$apiUrl = apiUrl;
// Hacer que la dirección base de la API esté disponible globalmente
appInstance.config.globalProperties.$apiImg = apiImg;
// Hacer que la dirección base de la API esté disponible globalmente
appInstance.config.globalProperties.$apiImgWeb = apiImgWeb;

appInstance.use(router);
appInstance.mount('#app');
appInstance.use(metaManager);

