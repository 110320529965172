<template>
    <Preloader v-if="loading" />
    <div v-else>
        <div class="sub-banner">
            <NavbarMenu></NavbarMenu>
            <figure class="sub-bannersideshape mb-0">
                <Image src="/banner/sub-bannersideshape.png" alt="" class="img-fluid" />
            </figure>
            <section class="banner-section banner-section-about">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 col-md-6 col-sm-12 col-12">
                            <div class="banner_content" data-aos="fade-right">
                                <h1 class="text-white custom-standard-title"> {{ blogHead.titulo }} </h1>
                                <p class="text-white custom-standard-subtitle"> {{ blogHead.subtitulo }} </p>
                                <div class="box custom-standard-box">
                                    <span class="mb-0">Inicio</span><i class="first fa-regular fa-angle-right"></i><i
                                        class="second fa-regular fa-angle-right"></i><span class="mb-0 box_span">{{
                                            blogHead.titulo }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-sm-12 col-12">
                            <div class="banner_wrapper">
                                <figure
                                    class="mb-0 d-flex justify-content-center align-items-center sub-bannerimage-about">
                                    <ImageWeb :src="'/' + blogHead.imagen" :alt="blogHead.imagen"
                                        class="img-fluid fixed-size-blog" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <figure class="sub-bannersideshape2 mb-0">
                    <Image src="/banner/sub-bannersideshape2.png" alt="" class="img-fluid" />
                </figure>
            </section>
        </div>
        <div class="section blog-details-section section-padding">
            <div class="container">
                <div class="blog-details-wrap">
                    <div class="row sidebar-row">
                        <div class="col-lg-8">
                            <div class="blog-post-wrap">
                                <div class="blog-container">
                                    <div v-for="post in paginatedPosts" :key="post.id"
                                        class="single-blog single-blog-post">
                                        <div class="blog-img">
                                            <a :href="'blog-post/' + post.slug">
                                                <ImageWeb :src="'/' + post.imagen" :alt="post.imagen" />
                                            </a>
                                            <div class="top-meta">
                                                <span class="date"><span>{{ getDay(post.created_at) }}</span>{{
                                                    getMonth(post.created_at) }}</span>
                                            </div>
                                        </div>
                                        <div class="blog-content">
                                            <div class="blog-meta">
                                                <span><i class="fas fa-user"></i> <a href="#">{{ post.autor
                                                        }}</a></span>
                                                <span class="post-year">{{ getYear(post.created_at) }}</span>
                                            </div>
                                            <h3 class="title" aria-level="2"><a :href="'blog-post/' + post.slug">{{
                                                    post.titulo }}</a>
                                            </h3>
                                            {{ post.resumen }}
                                        </div>
                                        <div class="blog-btn">
                                            <a :href="'blog-post/' + post.slug" class="blog-btn-link">Leer el artículo
                                                completo<i class="fas fa-long-arrow-alt-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="techmax-pagination">
                                <ul class="pagination justify-content-center">
                                    <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                        <a class="page-link" href="#" @click.prevent="prevPage"
                                            aria-label="Página anterior">
                                            <i class="flaticon-back"></i>
                                        </a>
                                    </li>
                                    <li class="page-item" :class="{ active: page === currentPage }"
                                        v-for="page in visiblePages" :key="page">
                                        <a class="page-link" href="#" @click.prevent="goToPage(page)"
                                            :aria-label="'Ir a la página ' + page">
                                            {{ page }}
                                        </a>
                                    </li>
                                    <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                        <a class="page-link" href="#" @click.prevent="nextPage"
                                            aria-label="Página siguiente">
                                            <i class="flaticon-next"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="blog-sidebar">
                                <SidebarBlog></SidebarBlog>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterMenu></FooterMenu>
    </div>
</template>


<script>
import NavbarMenu from '../components/NavbarMenu.vue';
import FooterMenu from '../components/FooterMenu.vue';
import SidebarBlog from '../components/SidebarBlog.vue';
import Image from '@/components/ImagePublic.vue';
import ImageWeb from '@/components/ImagePublicWeb.vue';
import Preloader from '@/components/Preloader.vue';
import { updateMetaTags } from '../metaUtils';

export default {
    name: 'BlogPage',
    components: {
        NavbarMenu,
        FooterMenu,
        SidebarBlog,
        Image,
        ImageWeb,
        Preloader,
    },
    data() {
        return {
            blogHead: {
                id: '',
                titulo: '',
                subtitulo: '',
                imagen: ''
            },
            posts: [],
            currentPage: 1,
            commentsPerPage: 5,
            pageRange: 5,
            pageStart: 1,
            loading: true,
            loadingFinished1: false,
            loadingFinished2: false,
            minLoadingTime: null,
        }
    },
    created() {
        this.minLoadingTime = new Promise((resolve) => {
            setTimeout(resolve, 750);
        });
        this.cargarHeader();
        this.cargarPost();
    },
    methods: {
        updateMeta(blogHead) {
            const routeMeta = {
                title: 'Mylcom - Blog',
                description: blogHead.subtitulo,
                image: `${this.$apiImgWeb}/images/web/${blogHead.imagen}`, // Construye la URL completa de la imagen
                canonical: `${window.location.origin}${this.$route.fullPath}`,

            };
            updateMetaTags({ meta: routeMeta });
        },
        cargarHeader() {
            fetch(`${this.$apiUrl}/verblog/1`)
                .then((response) => response.json())
                .then((data) => {
                    this.blogHead.titulo = data.titulo;
                    this.blogHead.subtitulo = data.subtitulo;
                    this.blogHead.imagen = data.imagen;
                    this.updateMeta(data); // Actualiza las etiquetas meta dinámicamente

                })
                .catch((error) => {
                    console.error('Error al cargar datos:', error);
                })
                .finally(() => {
                    this.loadingFinished1 = true;
                    this.checkLoading();
                });
        },
        cargarPost() {
            fetch(`${this.$apiUrl}/listar-posts`)
                .then((response) => response.json())
                .then((data) => {
                    this.posts = data.data;
                })
                .catch((error) => {
                    console.error("Error al cargar datos", error)
                })
                .finally(() => {
                    this.loadingFinished2 = true;
                    this.checkLoading();
                });
        },
        getDay(dateString) {
            const date = new Date(dateString);
            return date.getUTCDate();
        },
        getMonth(dateString) {
            const date = new Date(dateString);
            const months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
            return months[date.getUTCMonth()];
        },
        getYear(dateString) {
            const date = new Date(dateString);
            return date.getUTCFullYear();
        },
        checkLoading() {
            if (this.loadingFinished1 && this.loadingFinished2) {
                this.minLoadingTime.then(() => {
                    this.loading = false;
                });
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                if (this.currentPage < this.pageStart) {
                    this.pageStart = Math.max(this.currentPage - this.pageRange + 1, 1);
                }
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                if (this.currentPage >= this.pageStart + this.pageRange) {
                    this.pageStart++;
                }
            }
        },
        goToPage(page) {
            this.currentPage = page;
            if (page >= this.pageStart + this.pageRange) {
                this.pageStart = page - this.pageRange + 1;
            } else if (page < this.pageStart) {
                this.pageStart = page;
            }
        }
    },
    computed: {
        totalPages() {
            return Math.ceil(this.posts.length / this.commentsPerPage);
        },
        paginatedPosts() {
            const start = (this.currentPage - 1) * this.commentsPerPage;
            const end = start + this.commentsPerPage;
            return this.posts.slice(start, end);
        },
        visiblePages() {
            const end = Math.min(this.pageStart + this.pageRange - 1, this.totalPages);
            const pages = [];
            for (let i = this.pageStart; i <= end; i++) {
                pages.push(i);
            }
            return pages;
        }
    }
}

</script>

<style scoped>
.comment-form {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 40px;
}

.comment-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.ejemplo {
    font-size: 14px;
    color: #777;
    margin-bottom: 20px;
}

.single-form {
    margin-bottom: 20px;
}

.form-label {
    display: block;
    font-weight: 600;
    margin-bottom: 5px;
    color: #555;
}

.form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    color: #555;
    background-color: #fff;
    transition: border-color 0.3s ease;
}

.form-control:focus {
    border-color: #007bff;
    outline: none;
}

.form-btn {
    text-align: right;
}

.btn-submit {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-submit:hover {
    background-color: #0056b3;
}

.single-comment {
    margin-bottom: 20px;
}

.comment-author {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.author-info h5 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
}

.author-info span {
    font-size: 14px;
    color: #999;
}

.login-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.login-form-container {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
}
</style>
