<template>
    <Preloader v-if="loading" />
    <div v-else>
        <div class="sub-banner">
            <NavbarMenu/>
            <figure class="sub-bannersideshape mb-0">
                <Image src="/banner/sub-bannersideshape.png" alt="image" />
            </figure>
            <section class="banner-section banner-section-about">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 col-md-6 col-sm-12 col-12">
                            <div class="banner_content" data-aos="fade-right">
                                <h1 class="text-white custom-standard-title">{{ solucion.titulo }}</h1>
                                <p class="text-white custom-standard-subtitle">{{ solucion.subtitulo }}</p>
                                <div class="box custom-standard-box">
                                    <span class="mb-0">Inicio</span>
                                    <i class="first fa-regular fa-angle-right"></i>
                                    <i class="second fa-regular fa-angle-right"></i>
                                    <span class="mb-0 box_span">{{ solucion.titulo }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-sm-12 col-12">
                            <div class="banner_wrapper">
                                <figure class="mb-0 d-flex justify-content-center align-items-center sub-bannerimage-about">
                                    <ImageWeb :src="'/' + solucion.imagen" alt="" class="img-fluid fixed-size-blog" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <figure class="sub-bannersideshape2 mb-0">
                    <Image src="/banner/sub-bannersideshape2.png" alt="" class="img-fluid" />
                </figure>
            </section>
        </div>
        <div class="section blog-details-section section-padding">
            <div class="container">
                <div class="blog-details-wrap">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="blog-details-post">
                                <div class="single-blog">
                                    <div class="blog-img">
                                        <a href="#">
                                            <ImageWeb :src="'/' + postsolution.imagen_completa" id="post-image" alt="img-fluid" class="specific-image" />
                                        </a>
                                        <div class="top-meta">
                                            <span class="date">
                                                <span id="date-day">{{ getDay(postsolution.created_at) }}</span>
                                                <div id="date-month">{{ getMonth(postsolution.created_at) }}</div>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="blog-content">
                                        <div class="blog-meta">
                                            <span><i class="fas fa-user"></i> <a href="#" id="post-author"> Mylcom </a></span>
                                            <span class="post-year">{{ getYear(postsolution.created_at) }}</span>
                                        </div>
                                        <h3 class="title" id="post-title">{{ postsolution.titulo }}</h3>
                                        <div  class="ql-editor" v-html="postsolution.contenido"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterMenu></FooterMenu>
    </div>
</template>

<script>
import NavbarMenu from '@/components/NavbarMenu.vue';
import FooterMenu from '@/components/FooterMenu.vue';
import Image from '@/components/ImagePublic.vue';
import ImageWeb from '@/components/ImagePublicWeb.vue';
import Preloader from '@/components/Preloader.vue';
import { updateMetaTags } from '../../metaUtils';


export default {
    props: ['slug'],
    name: 'SolutionDetails',
    components: {
        NavbarMenu,
        FooterMenu,
        Image,
        ImageWeb,
        Preloader,
    },
    data() {
        return {
            loading: true,
            solucion: {
                titulo: '',
                subtitulo: '',
                imagen: ''
            },
            postsolution: {
                id: '',
                titulo: '',
                imagen: '',
                created_at: '',
                contenido: ''
            }
        };
    },
    created() {
        this.cargarHeader();
        this.cargarCuerpo();
    },
    methods: {
        updateMeta(postsolution) {
            const routeMeta = {
                title: 'Mylcom - Solutions',
                description: postsolution.subtitulo,
                image: `${this.$apiImgWeb}/images/web/${postsolution.imagen}`, // Construye la URL completa de la imagen
                canonical: `${window.location.origin}${this.$route.fullPath}`,

            };
            updateMetaTags({ meta: routeMeta });
        },
        cargarHeader() {
            fetch(`${this.$apiUrl}/mostrar-solucion/1`)
                .then(response => response.json())
                .then(data => {
                    this.solucion = data;
                })
                .catch(error => {
                    console.error('Error al cargar la cabecera:', error);
                });
        },
        cargarCuerpo() {
            fetch(`${this.$apiUrl}/buscar-solucion/${this.slug}`)
                .then(response => response.json())
                .then(data => {
                    this.postsolution = data;
                    this.updateMeta(data); // Actualiza las etiquetas meta dinámicamente

                })
                .catch(error => {
                    console.error('Error al cargar el cuerpo:', error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getDay(dateString) {
            const date = new Date(dateString);
            return date.getUTCDate();
        },
        getMonth(dateString) {
            const date = new Date(dateString);
            const months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
            return months[date.getUTCMonth()];
        },
        getYear(dateString) {
            const date = new Date(dateString);
            return date.getUTCFullYear();
        }
    }
}
</script>

<style scoped>
.custom-comments-pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    padding: 3rem;
    justify-content: center;
}

.custom-comments-pagination .pagination .page-item {
    margin: 0 3px;
}

.custom-comments-pagination .pagination .page-item .page-link {
    width: 35px;
    height: 35px;
    padding: 0;
    line-height: 33px;
    font-size: 14px;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
    text-align: center;
    color: #6c7381;
    border: 0;
    background: transparent;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s linear;
}

.custom-comments-pagination .pagination .page-item .page-link i {
    font-size: 12px;
}

.custom-comments-pagination .pagination .page-item {
    margin: 0 3px;
}

.page-item:not(:first-child) .page-link {
    margin-left: -1px;
}

.page-link {
    padding: 0.375rem 0.75rem;
}

.page-link {
    position: relative;
    display: block;
    color: #0d6efd;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #dee2e6;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-comments-pagination .pagination .page-item.active .page-link {
    background: linear-gradient(to right, #193C88, #4D9EC1);
    color: #ffffff;
}

.page-item.active .page-link {
    /* z-index: 1; */
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.comment-form {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 40px;
}

.comment-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.ejemplo {
    font-size: 14px;
    color: #777;
    margin-bottom: 20px;
}

.single-form {
    margin-bottom: 20px;
}

.form-label {
    display: block;
    font-weight: 600;
    margin-bottom: 5px;
    color: #555;
}

.form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    color: #555;
    background-color: #fff;
    transition: border-color 0.3s ease;
}

.form-control:focus {
    border-color: #007bff;
    outline: none;
}

.form-btn {
    text-align: right;
}

.btn-submit {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-submit:hover {
    background-color: #0056b3;
}

.single-comment {
    margin-bottom: 20px;
}

.comment-author {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.author-info h5 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
}

.author-info span {
    font-size: 14px;
    color: #999;
}

.login-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.login-form-container {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
}
</style>
