<template>
    <div class="sub-banner">
        <NavbarMenu></NavbarMenu>
        <figure class="sub-bannersideshape mb-0">
            <img src="assets/images/sub-bannersideshape.png" alt="" class="img-fluid">
        </figure>
        <section class="banner-section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7 col-md-6 col-sm-12 col-12">
                        <div class="banner_content" data-aos="fade-right">
                            <h1 class="text-white">Carrito de Compras</h1>
                            <p class="text-white">¡Bienvenido a tu carrito de compras! Explora nuestra selección de
                                productos y añade tus artículos favoritos para comprarlos.</p>
                            <div class="box">
                                <span class="mb-0">Inicio</span>
                                <i class="first fa-regular fa-angle-right"></i>
                                <i class="second fa-regular fa-angle-right"></i>
                                <span class="mb-0">Tienda</span>
                                <i class="first fa-regular fa-angle-right"></i>
                                <i class="second fa-regular fa-angle-right"></i>
                                <span class="mb-0 box_span">Carrito de Compras</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-6 col-sm-12 col-12">
                        <div class="banner_wrapper">
                            <figure class="mb-0 sub-bannerimage">
                                <img src="assets/images/sub-bannerimage.png" alt="" class="animated">
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
            <figure class="sub-bannersideshape2 mb-0">
                <img src="assets/images/sub-bannersideshape2.png" alt="" class="img-fluid">
            </figure>
        </section>
    </div>
    <section class="section blog-details-section section-padding">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="shopping-cart-wrap">
                        <div class="cart-table table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="pro-thumbnail">Imagen</th>
                                        <th class="pro-title">Producto</th>
                                        <th class="pro-price">Precio</th>
                                        <th class="pro-quantity">Cantidad</th>
                                        <th class="pro-subtotal">Total</th>
                                        <th class="pro-remove">Eliminar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="pro-thumbnail">
                                            <a href="shop.html"><img src="/assets/images/store/Jabra-HUB-300x300.jpg"
                                                    alt="Image-HasTech"></a>
                                        </td>
                                        <td class="pro-title">
                                            <h4 class="title"><a href="shop.html">2. New badge product</a></h4>
                                            <span>m / gold</span>
                                        </td>
                                        <td class="pro-price">
                                            <span class="amount">Tk 80.00</span>
                                        </td>
                                        <td class="pro-quantity">
                                            <div class="pro-qty">
                                                <input type="text" id="quantity" title="Quantity" value="1">
                                                <div class="inc qty-btn">+</div>
                                                <div class="dec qty-btn">-</div>
                                            </div>
                                        </td>
                                        <td class="pro-subtotal">
                                            <span class="subtotal-amount">Tk 80.00</span>
                                        </td>
                                        <td class="pro-remove">
                                            <a class="remove" href="#/"><i class="fa fa-trash"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="pro-thumbnail">
                                            <a href="shop.html"><img src="/assets/images/store/Jabra-HUB-300x300.jpg"
                                                    alt="Image-HasTech"></a>
                                        </td>
                                        <td class="pro-title">
                                            <h4 class="title"><a href="shop.html">5. Simple product</a></h4>
                                        </td>
                                        <td class="pro-price">
                                            <span class="amount">Tk 50.00</span>
                                        </td>
                                        <td class="pro-quantity">
                                            <div class="pro-qty">
                                                <input type="text" id="quantity" title="Quantity" value="1">
                                                <div class="inc qty-btn">+</div>
                                                <div class="dec qty-btn">-</div>
                                            </div>
                                        </td>
                                        <td class="pro-subtotal">
                                            <span class="subtotal-amount">Tk 50.00</span>
                                        </td>
                                        <td class="pro-remove">
                                            <a class="remove" href="#/"><i class="fa fa-trash"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="cart-buttons">
                                    <a class="theme-default-button" href="shop.html">Seguir Comprando</a>
                                    <a class="theme-default-button" href="#/">Limpiar Carrito</a>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="cart-payment">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <!-- <div class="culculate-shipping">
                                                <h4 class="title">Get shipping estimatesss</h4>
                                                <div class="culculate-shipping-form">
                                                    <form action="#">
                                                        <div class="form-row">
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <label class="sr-only" for="address_country">Address
                                                                        Country</label>
                                                                    <select id="address_country" class="form-select"
                                                                        aria-label="Address Country">
                                                                        <option selected="">---</option>
                                                                        <option value="1">Afghanistan</option>
                                                                        <option value="2">Albania</option>
                                                                        <option value="3">Anguilla</option>
                                                                        <option value="4">Antigua &amp; Barbuda</option>
                                                                        <option value="5">Argentina</option>
                                                                        <option value="6">Armenia</option>
                                                                        <option value="7">Aruba</option>
                                                                        <option value="8">Ascension Island</option>
                                                                        <option value="9">Australia</option>
                                                                        <option value="10">Austria</option>
                                                                        <option value="11">Azerbaijan</option>
                                                                        <option value="12">Bahamas</option>
                                                                        <option value="13">Bahrain</option>
                                                                        <option value="14">Bangladesh</option>
                                                                        <option value="15">Barbados</option>
                                                                        <option value="16">Belarus</option>
                                                                        <option value="17">Belgium</option>
                                                                        <option value="18">Belize</option>
                                                                        <option value="19">Benin</option>
                                                                        <option value="20">Brazil</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <label class="sr-only" for="address_zip">Zip/Postal
                                                                        Code</label>
                                                                    <input type="text" class="form-control"
                                                                        id="address_zip" placeholder="Zip/Postal Code">
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <a class="btn-calculate-shop" href="#/">Calculate
                                                                        shipping</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="cart-total">
                                                <h4 class="title">Resumen de Compra</h4>
                                                <table>
                                                    <tbody>
                                                        <tr class="cart-subtotal">
                                                            <th>Subtotal</th>
                                                            <td><span class="amount"><span>S/ 130.00</span></span></td>
                                                        </tr>
                                                        <tr class="order-total">
                                                            <th>Total</th>
                                                            <td>
                                                                <span class="amount"><span>S/ 130.00</span></span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div class="proceed-to-checkout">
                                                    <a class="shop-checkout-button" href="shop-checkout.html">Proceder a
                                                        Pagar</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
   
    
    <FooterMenu></FooterMenu>
</template>


<script>

import NavbarMenu from '@/components/NavbarMenu.vue'
import FooterMenu from '@/components/FooterMenu.vue'

export default {
    name: 'StorePage',
    components: {
        NavbarMenu,
        FooterMenu,
    },
    data() {
        return {
            products: []
        }
    },
    mounted() {
        this.getProducts();
    },
    methods: {
        getProducts() {
            // Realiza la solicitud AJAX usando fetch
            const ajaxUrl = `${this.$apiUrl}/mostrar-productos`;

            // Realizar la solicitud AJAX usando fetch
            fetch(ajaxUrl)
                .then(response => response.json()) // Convierte la respuesta a JSON
                .then(data => {
                    this.products = data;
                })
                .catch(error => {
                    console.error("Error en la solicitud AJAX:", error);
                });
        }
    }

}
</script>
