<template>
    <Preloader v-if="loading" />
    <div v-else>
        <div class="sub-banner">
            <NavbarMenu />
            <figure class="sub-bannersideshape mb-0">
                <Image src="/banner/sub-bannersideshape.png" alt="image" />
            </figure>
            <section class="banner-section banner-section-about">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 col-md-6 col-sm-12 col-12">
                            <div class="banner_content" data-aos="fade-right">
                                <h1 class="text-white custom-standard-title">{{ noticeHead.titulo }}</h1>
                                <p class="text-white custom-standard-subtitle">
                                    {{ noticeHead.subtitulo }}
                                </p>
                                <div class="box custom-standard-box">
                                    <span class="mb-0">Inicio</span><i class="first fa-regular fa-angle-right"></i><i
                                        class="second fa-regular fa-angle-right"></i><span
                                        class="mb-0 box_span">Noticias</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-sm-12 col-12">
                            <div class="banner_wrapper">
                                <figure
                                    class="mb-0  d-flex justify-content-center align-items-center sub-bannerimage-about">
                                    <ImageWeb :src="'/' + noticeHead.imagen" :alt="noticeHead.imagen"
                                        class="img-fluid fixed-size-blog" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <figure class="sub-bannersideshape2 mb-0">
                    <Image src="/banner/sub-bannersideshape2.png" alt="img-fluid" />
                </figure>
            </section>
        </div>
        <section class="notice-section">
            <div class="container">
                <div class="filters">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-5 mb-2">
                                <label for="year-select" class="form-label">Seleccionar Año</label>
                                <select id="year-select" v-model="selectedYear" @change="filterByYear"
                                    class="form-control">
                                    <option value="Todos los años">Todos los años</option>
                                    <option v-for="year in filteredYears" :key="year" :value="year">{{ year }}</option>
                                </select>
                            </div>
                            <div class="col-md-5 mb-2">
                                <label for="search-input" class="form-label">Buscar por título</label>
                                <input id="search-input" type="text" v-model="searchQuery"
                                    placeholder="Buscar por título" class="form-control" @input="filterNews">
                            </div>
                            <div class="col-md-2 mb-2">
                                <button @click="searchByTitle" class="btn-default">Buscar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="service_contentbox">
                    <div v-if="noResults" class="text-center">No se encontraron noticias para la búsqueda actual.</div>
                    <div v-else class="row justify-content-center">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12" v-for="(news, index) in paginatedNewsList"
                            :key="index">
                            <router-link :to="`/news/${news.slug}`" class="service-box box-mb">
                                <div class="box-image">
                                    <figure class="service-reboticon">
                                        <ImageWeb :src="'/' + news.imagen" :alt="news.imagen" class="img-fluid" />
                                    </figure>
                                </div>
                                <div class="box-content">
                                    <h4 aria-level="2">{{ news.titulo }}</h4>
                                    <p class="text-size-16">{{ news.resumen }}</p>
                                    <a :href="`/buscar-noticias/${news.slug}`" class="read_more">Leer Más</a>
                                </div>
                            </router-link>
                        </div>
                        <div class="notice-pagination">
                            <ul class="pagination justify-content-center">
                                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                    <a class="page-link" href="#" @click.prevent="prevPage"
                                        aria-label="Página anterior">
                                        <i class="flaticon-back" aria-hidden="true"></i>
                                        <span class="sr-only">Página anterior</span>
                                    </a>
                                </li>
                                <li class="page-item" :class="{ active: page === currentPage }"
                                    v-for="page in visiblePages" :key="page">
                                    <a class="page-link" href="#" @click.prevent="goToPage(page)"
                                        :aria-label="'Ir a la página ' + page"
                                        :aria-current="page === currentPage ? 'page' : null">
                                        {{ page }}
                                    </a>
                                </li>
                                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                    <a class="page-link" href="#" @click.prevent="nextPage"
                                        aria-label="Página siguiente">
                                        <i class="flaticon-next" aria-hidden="true"></i>
                                        <span class="sr-only">Página siguiente</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <FooterMenu></FooterMenu>
    </div>
</template>

<script>
import NavbarMenu from '@/components/NavbarMenu.vue';
import FooterMenu from '@/components/FooterMenu.vue';
import Image from '@/components/ImagePublic.vue';
import Preloader from '@/components/Preloader.vue';
import ImageWeb from '@/components/ImagePublicWeb.vue';
import { updateMetaTags } from '../metaUtils';


export default {
    name: 'NewsPage',
    components: {
        NavbarMenu,
        FooterMenu,
        Image,
        Preloader,
        ImageWeb
    },
    data() {
        return {
            loading: true,
            loadingFinished1: false,
            loadingFinished2: false,
            minLoadingTime: null,
            newsList: [],
            filteredNewsList: [],
            currentPage: 1,
            commentsPerPage: 5,
            pageRange: 5,
            pageStart: 1,
            searchQuery: '',
            selectedYear: 'Todos los años',
            years: [],
            noResults: false,
            noticeHead: {
                id: '',
                titulo: '',
                subtitulo: '',
                imagen: ''
            },
        };
    },
    created() {
        this.minLoadingTime = new Promise((resolve) => {
            setTimeout(resolve, 750);
        });
        this.cargarAnios();
        this.cargarNoticias();
        this.cargarHeader();
    },
    methods: {
        updateMeta(noticeHead) {
            const routeMeta = {
                title: 'Mylcom - Partners',
                description: noticeHead.subtitulo,
                image: `${this.$apiImgWeb}/images/web/${noticeHead.imagen}`, // Construye la URL completa de la imagen
                canonical: `${window.location.origin}${this.$route.fullPath}`,

            };
            updateMetaTags({ meta: routeMeta });
        },
        cargarHeader() {
            fetch(`${this.$apiUrl}/mostrar-noticia/1`)
                .then((response) => response.json())
                .then((data) => {
                    this.noticeHead.titulo = data.titulo;
                    this.noticeHead.subtitulo = data.subtitulo;
                    this.noticeHead.imagen = data.imagen;
                    this.updateMeta(data); // Actualiza las etiquetas meta dinámicamente
                })
                .catch((error) => {
                    console.error('Error al cargar datos:', error);
                })
                .finally(() => {
                    this.loadingFinished1 = true;
                    this.checkLoading();
                });
        },
        cargarNoticias() {
            fetch(`${this.$apiUrl}/listar-noticias`)
                .then((response) => response.json())
                .then((data) => {
                    if (Array.isArray(data)) {
                        this.newsList = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                        this.filteredNewsList = this.newsList;
                        this.noResults = this.newsList.length === 0;
                    } else {
                        console.error('Invalid data format:', data);
                        this.noResults = true;
                    }
                })
                .catch((error) => {
                    console.error('Error al cargar datos:', error);
                    this.noResults = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        cargarAnios() {
            fetch(`${this.$apiUrl}/listar-anios-noticias`)
                .then(response => response.json())
                .then(data => {
                    if (Array.isArray(data)) {
                        this.years = ['Todos los años', ...data.filter(year => year >= 2020)];
                    } else {
                        console.error('Invalid data format:', data);
                    }
                })
                .catch(error => {
                    console.error('Error al cargar años:', error);
                });
        },
        filterByYear() {
            if (this.selectedYear && this.selectedYear !== 'Todos los años') {
                fetch(`${this.$apiUrl}/listar-noticias-por-anio/${this.selectedYear}`)
                    .then(response => response.json())
                    .then(response => {
                        if (response.success && Array.isArray(response.data)) {
                            this.newsList = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                            this.filteredNewsList = this.newsList;
                            this.noResults = this.newsList.length === 0;
                        } else {
                            console.error('Invalid data format:', response);
                            this.noResults = true;
                        }
                    })
                    .catch(error => {
                        console.error('Error al cargar noticias por año:', error);
                        this.noResults = true;
                    })
                    .finally(() => {
                        this.currentPage = 1;
                    });
            } else {
                this.cargarNoticias();
            }
        },
        filterNews() {
            const query = this.searchQuery.toLowerCase();
            this.filteredNewsList = this.newsList.filter(news =>
                news.titulo.toLowerCase().includes(query)
            );
            this.noResults = this.filteredNewsList.length === 0;
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                if (this.currentPage < this.pageStart) {
                    this.pageStart = Math.max(this.currentPage - this.pageRange + 1, 1);
                }
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                if (this.currentPage >= this.pageStart + this.pageRange) {
                    this.pageStart++;
                }
            }
        },
        goToPage(page) {
            this.currentPage = page;
            if (page >= this.pageStart + this.pageRange) {
                this.pageStart = page - this.pageRange + 1;
            } else if (page < this.pageStart) {
                this.pageStart = page;
            }
        },
        checkLoading() {
            if (this.loadingFinished1 && this.loadingFinished2) {
                this.minLoadingTime.then(() => {
                    this.loading = false;
                });
            }
        },
    },
    computed: {
        filteredYears() {
            return this.years.filter(year => year >= 2020);
        },
        totalPages() {
            return Math.ceil(this.filteredNewsList.length / this.commentsPerPage);
        },
        paginatedNewsList() {
            if (!Array.isArray(this.filteredNewsList)) {
                return [];
            }
            const start = (this.currentPage - 1) * this.commentsPerPage;
            const end = start + this.commentsPerPage;
            return this.filteredNewsList.slice(start, end);
        },
        visiblePages() {
            const totalPagesToShow = 5;
            let startPage = this.currentPage - Math.floor(totalPagesToShow / 2);
            startPage = Math.max(startPage, 1);
            let endPage = startPage + totalPagesToShow - 1;
            if (endPage > this.totalPages) {
                endPage = this.totalPages;
                startPage = Math.max(endPage - totalPagesToShow + 1, 1);
            }
            const pages = [];
            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }
            return pages;
        }
    }
}
</script>


<style scoped>
.filters {
    padding: 20px 0;
    background-color: #f7f7f7;
    margin-bottom: 20px;
}

.filters .form-control,
.filters .btn {
    margin-bottom: 10px;
}

.notice-section {
    background-color: #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 134px 0 124px;
}

.notice-section .team-sideimage {
    position: absolute;
    left: -110px;
    top: 148px;
}

.notice-section .provide_content {
    text-align: center;
}

.notice-section .provide_content h2 {
    margin-bottom: 35px;
    padding: 0 90px;
}

.notice-section .service_contentbox .service-box {
    background: var(--e-global-color-white);
    border-radius: 1.8em;
    padding: 40px 35px;
    margin-bottom: 27px;
    display: inline-block;
    transition: all 0.3s ease-in-out;
    border-bottom: 2px solid var(--e-global-color-cyan-alternative);
    box-shadow: 2px 2px 5px var(--e-global-color-cyan-alternative);
    width: 100%;
}

.notice-section .service_contentbox .service-box h4 {
    display: inline-block;
    margin-bottom: 6px;
}

.notice-section .service_contentbox .service-box p {
    margin-bottom: 22px;
}

.notice-section .service_contentbox .service-box .box-content {
    position: relative;
    padding-left: 16px;
    display: inline-block;
    width: 70%;
}

.notice-section .service_contentbox .service-box figure {
    background: #fff;
    border-radius: 10px;
    max-height: 200px;
    width: 100%;
    text-align: center;
    overflow: hidden;
    margin: auto;
}

.notice-section .service_contentbox .service-box figure img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
}

.notice-section .service_contentbox .service-box:hover {
    background-image: linear-gradient(to right, var(--e-global-color-principal) 0%, #336DA5 51%, #4C9DC0 100%);
    border-bottom: 2px solid #4C9DC0;
    transform: translateY(-5px);
}

.notice-section .service_contentbox .service-box:hover h4 {
    color: var(--e-global-color-white);
}

.notice-section .service_contentbox .service-box:hover p {
    color: var(--e-global-color-white);
}

.notice-section .service_contentbox .service-box .box-image {
    float: left;
    width: 30%;
}

/*.notice-section .service_contentbox .service-box figure:hover {
    transform: translateY(-5px);
}*/

.notice-section .service_contentbox .service-box .read_more {
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    font-family: "Manrope", sans-serif;
    color: var(--e-global-color-secondary);
    text-transform: uppercase;
}

.notice-pagination {
    display: flex;
    justify-content: center;
    padding-top: 20px;
}

.notice-pagination .pagination {
    display: flex;
    list-style: none;
}

.notice-pagination .pagination .page-item {
    margin: 0 3px;
}

.notice-pagination .pagination .page-item .page-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #dee2e6;
    color: #6c7381;
    background-color: #fff;
    transition: all 0.3s linear;
}

.notice-pagination .pagination .page-item.disabled .page-link {
    color: #ccc;
    cursor: not-allowed;
}

.notice-pagination .pagination .page-item.active .page-link {
    background: linear-gradient(to right, #193C88, #4D9EC1);
    color: #ffffff;
    border-color: #4D9EC1;
}


@media (max-width: 767px) {
    .notice-section .service_contentbox .service-box .box-image {
        width: 100%;
    }

    .notice-section .service_contentbox .service-box figure {
        height: 100%;
    }

    .notice-section .service_contentbox .service-box .box-content {
        width: 100%;
        margin-top: 1rem;
    }

}
</style>
