<template>
    <div class="sidebar-widget" :class="{ active: isActive }">
        <div class="widget-title">
            <!-- Aquí puedes poner el título del widget si es necesario -->
        </div>
        <ul class="category" style="padding: 0; list-style: none; margin: 0;">
            <li v-if="filteredCards.length === 0">No hay resultados</li>
            <li class="cate-item" v-for="(card, index) in paginatedCards" :key="index">
                <router-link :to="{ name: 'CardDetail', params: { slug: card.slug } }">
                    <i class="flaticon-next"></i>
                    {{ card.titulo }}
                </router-link>
            </li>
        </ul>
        <div class="pagination-controls">
            <span @click="previousPage" :class="{ disabled: currentPage === 1 }">
                <i class="fas fa-chevron-left"></i>
            </span>
            <span @click="nextPage" :class="{ disabled: currentPage === totalPages }">
                <i class="fas fa-chevron-right"></i>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CardListNavigation',
    props: {
        searchQuery: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            cards: [],
            allCards: [], // Lista completa de cards
            currentPage: 1,
            cardPage: 12,
            isActive: false, // Controla la visibilidad en móviles
        };
    },
    watch: {
        searchQuery(newQuery) {
            this.isActive = newQuery.length > 0;
        }
    },
    computed: {
        filteredCards() {
            const query = this.normalizeText(this.searchQuery.toLowerCase());
            return this.allCards.filter(card => this.normalizeText(card.titulo.toLowerCase()).includes(query));
        },
        paginatedCards() {
            const start = (this.currentPage - 1) * this.cardPage;
            const end = start + this.cardPage;
            return this.filteredCards.slice(start, end);
        },
        totalPages() {
            return Math.ceil(this.filteredCards.length / this.cardPage);
        }
    },
    created() {
        this.cargarTodasCards();
    },
    methods: {
        cargarTodasCards() {
            let page = 1;
            const fetchPage = (page) => {
                fetch(`${this.$apiUrl}/listar-cards-all?page=${page}&card_page=${this.cardPage}`)
                    .then(response => response.json())
                    .then(data => {
                        if (Array.isArray(data)) {
                            this.allCards = [...this.allCards, ...data];
                            if (page < data.last_page) {
                                fetchPage(page + 1);
                            } else {
                                this.cards = this.allCards;
                            }
                        } else if (data.data && Array.isArray(data.data)) {
                            this.allCards = [...this.allCards, ...data.data];
                            if (page < data.last_page) {
                                fetchPage(page + 1);
                            } else {
                                this.cards = this.allCards;
                            }
                        } else {
                            console.error('Estructura de datos inesperada', data);
                        }
                    })
                    .catch(error => {
                        console.error('Error al cargar datos:', error);
                    });
            };
            fetchPage(page);
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        normalizeText(text) {
            return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        }
    }
};
</script>

<style scoped>
.pagination-controls {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.pagination-controls span {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.pagination-controls span i {
    font-size: 1em;
}

.pagination-controls span.disabled {
    cursor: not-allowed;
    color: gray;
}

@media (max-width: 768px) {
    .sidebar-widget {
        display: none;
    }
    .sidebar-widget.active {
        display: block;
    }
}
</style>
