import { createRouter, createWebHistory } from "vue-router";

import AboutPage from '../view/AboutPage';
import HomePage from '../view/HomePage';
import BlogPage from "../view/BlogPage.vue";
import ContactPage from "../view/ContactPage.vue";
import PortafolioPage from "../view/PortafolioPage.vue";
import BlogPost from "../view/components/BlogPost.vue";
import BlogSearch from "../view/components/BlogSearch.vue";
import StorePage from "../view/StorePage.vue";
import SolutionPage from "../view/SolutionPage.vue";
import ProductInfo from "../view/components/ProductInfo.vue";
import ShopCart from "../view/components/ShopCart.vue";
import PartnerPage from "../view/PartnerPage.vue";
import SolutionDetails from "../view/components/SolutionDetails.vue";
import VerifyPage from "@/view/VerifyPage.vue";
import NewsPage from "../view/NewsPage.vue";
import NewsDetails from "../view/components/NewsDetails.vue";
import UnsubscribePage from "../view/components/widgets/UnsubscribePage.vue";
import CardDetail from "../view/components/CardDetail.vue";
import { updateMetaTags } from '../metaUtils';


const routes = [
    {
        path: "/",
        name: "HomePage",
        component: HomePage,
        // meta: {
        //     title: 'Mylcom - Home',
        //     description: 'Líder en soluciones tecnológicas para empresas. Desde centrales telefónicas y sistemas de comunicación hasta soluciones avanzadas de IoT e IA, MYL Comunicaciones ofrece una amplia gama de productos y servicios para satisfacer las necesidades de comunicación de tu empresa. Explora nuestras soluciones y lleva tu negocio al siguiente nivel con tecnología de vanguardia.',
        //     canonical: 'https://mylcom.com.pe/'
        // },
    },
    {
        path: "/about",
        name: "AboutPage",
        component: AboutPage,
        meta: {
            title: 'Mylcom - About',
            description: 'Conoce más sobre MYL Comunicaciones, nuestra historia, misión y valores. Descubre cómo nos hemos convertido en un líder en soluciones tecnológicas para empresas en Perú.',
            canonical: 'https://mylcom.com.pe/about'
        }
    },
    {
        path: "/partners",
        name: "PartnerPage",
        component: PartnerPage,
        meta: {
            title: 'Mylcom - Partners',
            description: 'Descubre a nuestros socios estratégicos y colaboradores que nos ayudan a proporcionar soluciones tecnológicas de vanguardia. Conoce a las empresas con las que trabajamos para ofrecerte lo mejor.',
            canonical: 'https://mylcom.com.pe/partners'
        }
    },
    {
        path: "/blog",
        name: "BlogPage",
        component: BlogPage,
        meta: {
            title: 'Mylcom - Blog',
            description: 'Lee las últimas noticias, artículos y actualizaciones sobre tecnología y soluciones empresariales en el blog de MYL Comunicaciones. Mantente informado sobre las tendencias y avances tecnológicos.',
            canonical: 'https://mylcom.com.pe/blog'
        }
    },
    {
        path: "/contact-us",
        name: "ContactPage",
        component: ContactPage,
        meta: {
            title: 'Mylcom - Contact',
            description: 'Ponte en contacto con MYL Comunicaciones para obtener más información sobre nuestras soluciones tecnológicas. Estamos aquí para ayudarte con cualquier consulta o soporte que necesites.',
            canonical: 'https://mylcom.com.pe/contact-us'
        }
    },
    {
        path: "/portfolio",
        name: "PortafolioPage",
        component: PortafolioPage,
        meta: {
            title: 'Mylcom - Portfolio',
            description: 'Explora nuestro portafolio de proyectos exitosos y soluciones implementadas por MYL Comunicaciones. Conoce cómo hemos ayudado a diversas empresas a mejorar su infraestructura tecnológica.',
            canonical: 'https://mylcom.com.pe/portfolio'
        }
    },
    {
        path: '/portfolio/:slug',
        name: "CardDetail",
        component: CardDetail,
        meta: {
            title: 'Mylcom - Portfolio',
            description: 'Detalles del proyecto en el portafolio de MYL Comunicaciones. Descubre más sobre nuestras soluciones tecnológicas y casos de éxito.',
        },
        props: true
    },
    {
        path: "/solutions",
        name: "SolutionPage",
        component: SolutionPage,
        meta: {
            title: 'Mylcom - Solutions',
            description: 'Descubre nuestras soluciones tecnológicas avanzadas diseñadas para mejorar la comunicación y la eficiencia operativa de tu empresa. Desde IoT hasta IA, MYL Comunicaciones tiene la solución perfecta para ti.',
            canonical: 'https://mylcom.com.pe/solutions'
        }
    },
    {
        path: "/blog/:tipo/:slug",
        name: "BlogSearch",
        component: BlogSearch,
        meta: {
            title: 'Mylcom - Blog',
            description: 'Resultados de búsqueda en el blog de MYL Comunicaciones. Encuentra artículos y noticias relevantes sobre tecnología y soluciones empresariales.',
        },
        props: true
    },
    {
        path: "/blog-post/:slug",
        name: "BlogPost",
        component: BlogPost,
        meta: {
            title: 'Mylcom - Blog',
            description: 'Lectura del artículo seleccionado en el blog de MYL Comunicaciones. Obtén información detallada sobre temas tecnológicos y empresariales.',
        },
        props: true
    },
    {
        path: "/products",
        name: "StorePage",
        component: StorePage,
        meta: {
            title: 'Mylcom - Products',
            description: 'Explora nuestra gama de productos tecnológicos de alta calidad diseñados para satisfacer las necesidades de tu empresa. MYL Comunicaciones ofrece lo mejor en tecnología.',
            canonical: 'https://mylcom.com.pe/products'
        },
    },
    {
        path: "/products/:marca",
        name: "StorePageWithBrand",
        component: StorePage,
        meta: {
            title: 'Mylcom - Productos Marca',
            description: 'Descubre los productos tecnológicos de tus marcas favoritas en MYL Comunicaciones. Ofrecemos una amplia variedad de equipos y accesorios de las mejores marcas.',
        },
    },
    {
        path: "/shop-cart",
        name: "ShopCart",
        component: ShopCart,
        meta: {
            title: 'Mylcom - Shop Cart',
            description: 'Revisa y gestiona los productos que has agregado a tu carrito de compras en MYL Comunicaciones. Finaliza tu compra de manera rápida y segura.',
            canonical: 'https://mylcom.com.pe/shop-cart'
        }
    },
    {
        path: "/producto/:slug",
        name: "ProductInfo",
        component: ProductInfo,
        meta: {
            title: 'Mylcom - Products',
            description: 'Obtén información detallada sobre los productos tecnológicos que ofrece MYL Comunicaciones. Conoce sus características y beneficios para tu empresa.',
        },
        props: true
    },
    {
        path: "/solution-details/:slug",
        name: "SolutionDetails",
        component: SolutionDetails,
        meta: {
            title: 'Mylcom - Detalles de la Solución',
            description: 'Detalles específicos de nuestras soluciones tecnológicas avanzadas. Aprende cómo nuestras soluciones pueden mejorar la eficiencia y comunicación en tu empresa.',
        },
        props: true
    },
    {
        path: '/verify/:id/:hash',
        name: 'EmailVerification',
        component: VerifyPage,
        meta: {
            title: 'Mylcom - Verificar - Correo',
            description: 'Verifica tu dirección de correo electrónico para completar tu registro en MYL Comunicaciones.',
            canonical: 'https://mylcom.com.pe/verify/:id/:hash'
        },
        props: true
    },
    {
        path: "/news",
        name: "NewsPage",
        component: NewsPage,
        meta: {
            title: 'Mylcom - Noticias',
            description: 'Mantente al día con las últimas noticias y actualizaciones de MYL Comunicaciones. Lee sobre nuestros nuevos proyectos, soluciones y eventos.',
            canonical: 'https://mylcom.com.pe/news'
        },
    },
    {
        path: "/news/:slug",
        name: "NewsDetails",
        component: NewsDetails,
        meta: {
            title: 'Mylcom - Noticias',
            description: 'Detalles de las noticias más recientes de MYL Comunicaciones. Infórmate sobre nuestros logros, nuevos proyectos y más.',
        },
        props: true
    },
    {
        path: "/unsubscribe",
        name: "UnsubscribePage",
        component: UnsubscribePage,
        meta: {
            title: 'Mylcom - Unsubscribe',
            description: 'Gestiona tu suscripción a nuestras comunicaciones. Si deseas dejar de recibir correos electrónicos de MYL Comunicaciones, realiza el proceso de baja aquí.',
            canonical: 'https://mylcom.com.pe/unsubscribe'
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active",
});


router.beforeEach((to, from, next) => {
    updateMetaTags(to);
    next();
});

export default router;
