<template>
    <div class="sub-banner">
        <NavbarMenu></NavbarMenu>
        <figure class="sub-bannersideshape mb-0">
            <Image src="/banner/sub-bannersideshape.png" alt="" class="img-fluid" loading="lazy" />
        </figure>
        <section class="banner-section banner-section-about">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7 col-md-6 col-sm-12 col-12">
                        <div class="banner_content" data-aos="fade-right">
                            <h1 class="text-white  custom-standard-title" >Contáctanos</h1>
                            <p class="text-white custom-standard-subtitle">¿Preguntas o comentarios? Estamos aquí para ayudarte. ¡Envíanos un
                                mensaje
                                o llámanos!</p>
                            <div class="box custom-standard-box">
                                <span class="mb-0 ">Inicio</span><i class="first fa-regular fa-angle-right"></i><i
                                    class="second fa-regular fa-angle-right"></i><span
                                    class="mb-0 box_span">Contáctanos</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-6 col-sm-12 col-12">
                        <div class="banner_wrapper">
                            <figure class="mb-0  d-flex justify-content-center align-items-center sub-bannerimage-about">
                                <Image src="/banner/contactanos.png" alt="" class="img-fluid fixed-size-blog" loading="lazy" />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
            <figure class="sub-bannersideshape2 mb-0">
                <Image src="/sub-bannersideshape2.png" alt="" class="img-fluid" loading="lazy" />
            </figure>
        </section>
    </div>
    <section class="contact-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="contact_content" data-aos="fade-right">
                        <h5>Nuestros Detalles</h5>
                        <h2>Información de contacto</h2>
                        <div class="contact-box">
                            <div class="box-image">
                                <figure class="contact-location">
                                    <Image src="/contacto/contact-location.png" alt="" class="img-fluid"
                                        loading="lazy" />
                                </figure>
                            </div>
                            <div class="box-content">
                                <h4>Dirección:</h4>
                                <p class="text-size-18">Jr. Washington 1206 - Cercado de Lima</p>
                            </div>
                        </div>
                        <div class="contact-box box-mb">
                            <div class="box-image">
                                <figure class="contact-phone">
                                    <Image src="/contacto/contact-phone.png" alt="" class="img-fluid" loading="lazy" />
                                </figure>
                            </div>
                            <div class="box-content">
                                <h4 class="heading">Teléfono:</h4>
                                <p>
                                    <a href="tel:+61383766284" class="text-decoration-none text text-size-18">+51 989303
                                        208</a>
                                </p>
                            </div>
                        </div>
                        <div class="contact-box">
                            <div class="box-image">
                                <figure class="contact-email">
                                    <Image src="/contacto/contact-email.png" alt="" class="img-fluid" />
                                </figure>
                            </div>
                            <div class="box-content">
                                <h4 class="heading">Email:</h4>
                                <p>
                                    <a href="mailto:info@artelligence.com"
                                        class="text-decoration-none text-size-18">ventas@mylcom.com.pe</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="need-section m-top">
                        <div v-if="mostrarPreloader" class="preloader-overlay">
                            <div class="preloader"></div>
                        </div>
                        <div class="need_content">
                            <h3>Necesita ayuda!</h3>
                            <p class="text-size-16">Nos pondremos en contacto con usted lo antes posible para ayudarlo
                                con su pregunta o solicitud</p>
                            <form id="contactpage" @submit.prevent="enviarmail">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <input type="text" class="form_style" placeholder="Ingrese su nombre:"
                                                name="name" v-model="contactData.nombre" required>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <input type="email" class="form_style" placeholder="Ingrese su correo:"
                                                name="email" v-model="contactData.correo" required>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <input type="text" class="form_style" placeholder="Ingrese su teléfono:"
                                                @keydown="preventInvalidChars" name="phone" maxlength="15" required
                                                v-model="contactData.telefono">
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class=" form-group">
                                            <textarea class="form_style" placeholder="Ingrese su mensaje" rows="3"
                                                name="message" v-model="contactData.mensaje" required></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="mostrarPreloader" class="preloader-overlay">
                                    <div class="preloader">
                                        <div class="spinner"></div>
                                    </div>
                                </div>
                                <div class="manage-button text-center">
                                    <button type="submit" class="submit_now text-decoration-none">Enviar<i
                                            class="circle fa-regular fa-angle-right"></i></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="contact_map_section">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d547.4585981785334!2d-77.0395799687764!3d-12.054435762665925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c9bcb2f6c3a3%3A0xbca336f067fc8f11!2sMYLCOM!5e0!3m2!1ses-419!2spe!4v1710273705527!5m2!1ses-419!2spe"
                    width="1920" height="556" style="border:0;" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>
    <FooterMenu></FooterMenu>
</template>

<script>

import NavbarMenu from '../components/NavbarMenu.vue'
import FooterMenu from '../components/FooterMenu.vue'
import Image from '@/components/ImagePublic.vue'
import Swal from 'sweetalert2';


export default {
    name: 'ContactPage',

    data() {
        return {
            contactData: {
                nombre: '',
                correo: '',
                telefono: '',
                mensaje: ''
            },
            formSubmitted: false, // Variable para controlar el estado del formulario
            mostrarPreloader: false // Variable para controlar el preloader
        }
    },
    components: {
        NavbarMenu,
        FooterMenu,
        Image,
    },

    methods: {
        preventInvalidChars(event) {
            const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', '+'];
            const isNumber = /[0-9]/.test(event.key); // Solo permite dígitos
            const isAllowed = allowedKeys.includes(event.key); // Permitir teclas especiales

            if (!isNumber && !isAllowed) {
                event.preventDefault(); // Evitar caracteres no permitidos
            }
        },
        async enviarmail() {
            this.mostrarPreloader = true; // Activar el preloader

            try {
                // Usa fetch para enviar datos a la API
                const response = await fetch(`${this.$apiUrl}/enviar-correo`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json', // Especifica que el cuerpo es JSON
                    },
                    body: JSON.stringify(this.contactData), // Convierte el objeto a JSON para el cuerpo de la solicitud
                });

                if (!response.ok) {
                    throw new Error(`Error al obtener datos: ${response.statusText}`);
                }

                // Limpiar el formulario después de enviar los datos
                this.contactData = {
                    nombre: '',
                    correo: '',
                    telefono: '',
                    mensaje: ''
                };
                this.formSubmitted = true;
                // Mostrar SweetAlert de éxito
                this.mostrarSweetAlertExito();
            } catch (error) {
                // console.error('Ocurrió un error:', error);
                this.mostrarSweetAlertError();
            } finally {
                // Desactivar el preloader después de completar la solicitud
                this.mostrarPreloader = false;
            }
        },

        mostrarSweetAlertExito() {
            Swal.fire({
                icon: 'success',
                title: '¡Éxito!',
                text: 'El formulario se envió correctamente.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK'
            });
        },

        // Método para mostrar SweetAlert de error
        mostrarSweetAlertError() {
            Swal.fire({
                icon: 'error',
                title: '¡Error!',
                text: 'Hubo un problema al enviar el formulario. Inténtalo de nuevo más tarde.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK'
            });
        }
    },

}

</script>

<style>
.m-top {
    margin-top: 7rem !important;
}

@media (max-width: 767px) {
    .m-top {
        margin-top: 1rem !important;
    }
}


.preloader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 9999;
}

.preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.spinner {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
