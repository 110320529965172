<template>
    <Preloader v-if="loading" />
    <div v-else>
        <div class="sub-banner">
            <NavbarMenu></NavbarMenu>
            <figure class="sub-bannersideshape mb-0">
                <Image src="/banner/sub-bannersideshape.png" alt="" class="img-fluid" />
            </figure>
            <section class="banner-section banner-section-about">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 col-md-6 col-sm-12 col-12">
                            <div class="banner_content" data-aos="fade-right">
                                <h1 class="text-white custom-standard-title">{{ solucion.titulo }}</h1>
                                <p class="text-white custom-standard-subtitle">{{ solucion.subtitulo }}</p>
                                <div class="box custom-standard-box">
                                    <span class="mb-0">Inicio</span><i class="first fa-regular fa-angle-right"></i><i
                                        class="second fa-regular fa-angle-right"></i><span class="mb-0 box_span">{{
                                        solucion.titulo }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-sm-12 col-12">
                            <div class="banner_wrapper">
                                <figure
                                    class="mb-0 d-flex justify-content-center align-items-center sub-bannerimage-about">
                                    <ImageWeb :src="'/' + solucion.imagen" alt="" class="img-fluid fixed-size-blog" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <figure class="sub-bannersideshape2 mb-0">
                    <Image src="/banner/sub-bannersideshape2.png" alt="" class="img-fluid" />
                </figure>
            </section>
        </div>
        <section class="section-container">
            <div class="container-cards-images">
                <ArticleCard v-for="(article, index) in paginatedArticles" :key="index" :postTitle="article.titulo"
                    :postLink="'/solution-details/' + article.slug" :postImage="article.imagen"
                    :postContent="article.descripcion" />
            </div>
        </section>
        <div class="techmax-pagination">
            <ul class="pagination justify-content-center">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                    <button class="page-link" @click="previousPage" aria-label="Página anterior"><i
                            class="flaticon-back"></i></button>
                </li>
                <li class="page-item" v-for="pageNumber in visiblePages" :key="pageNumber"
                    :class="{ active: pageNumber === currentPage }">
                    <button class="page-link" @click="changePage(pageNumber)"
                        :aria-label="'Ir a la página ' + pageNumber">{{ pageNumber }}</button>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                    <button class="page-link" @click="nextPage" aria-label="Página siguiente"><i
                            class="flaticon-next"></i></button>
                </li>
            </ul>
        </div>
        <FooterMenu></FooterMenu>
    </div>
</template>

<script>
import ArticleCard from './components/widgets/ArticleCard.vue'
import NavbarMenu from '@/components/NavbarMenu.vue'
import FooterMenu from '@/components/FooterMenu.vue'
import Image from '@/components/ImagePublic.vue'
import ImageWeb from '@/components/ImagePublicWeb.vue'
import Preloader from '@/components/Preloader.vue';
import { updateMetaTags } from '../metaUtils';

export default {
    name: 'SolutionPage',
    components: {
        NavbarMenu,
        FooterMenu,
        ArticleCard,
        Image,
        ImageWeb,
        Preloader,
    },
    data() {
        return {
            solucion: {
                titulo: '',
                subtitulo: '',
                imagen: ''
            },
            articles: [],
            currentPage: 1,
            articlesPerPage: 8,
            loading: true,
            loadingFinished1: false,
            loadingFinished2: false,
            minLoadingTime: null,
            pageRange: 5,  // Número de páginas visibles
            pageStart: 1,  // Página inicial visible
        };
    },
    created() {
        // Inicia el temporizador de 2 segundos
        this.minLoadingTime = new Promise((resolve) => {
            setTimeout(resolve, 750);
        });
        this.cargarHeader();
        this.cargarSecciones();
    },
    methods: {
        updateMeta(solucion) {
            const routeMeta = {
                title: 'Mylcom - Solutions',
                description: solucion.subtitulo,
                image: `${this.$apiImgWeb}/images/web/${solucion.imagen}`, // Construye la URL completa de la imagen
                canonical: `${window.location.origin}${this.$route.fullPath}`,

            };
            updateMetaTags({ meta: routeMeta });
        },
        cargarHeader() {
            fetch(`${this.$apiUrl}/mostrar-solucion/1`)
                .then((response) => { return response.json(); })
                .then((data) => {
                    this.solucion.titulo = data.titulo;
                    this.solucion.subtitulo = data.subtitulo;
                    this.solucion.imagen = data.imagen;
                    this.updateMeta(data); // Actualiza las etiquetas meta dinámicamente

                })
                .catch((error) => {
                    console.error('Error al cargar datos:', error);
                })
                .finally(() => {
                    this.loadingFinished1 = true;
                    this.checkLoading();
                });
        },
        cargarSecciones() {
            fetch(`${this.$apiUrl}/listar-cards-solucion-all`)
                .then((response) => { return response.json(); })
                .then((data) => {
                    this.articles = data;
                })
                .catch((error) => {
                    console.error('Error al cargar datos:', error);
                })
                .finally(() => {
                    this.loadingFinished2 = true;
                    this.checkLoading();
                });
        },
        checkLoading() {
            if (this.loadingFinished1 && this.loadingFinished2) {
                this.minLoadingTime.then(() => {
                    this.loading = false;
                });
            }
        },
        changePage(pageNumber) {
            this.currentPage = pageNumber;
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        }
    },
    computed: {
        totalPages() {
            return Math.ceil(this.articles.length / this.articlesPerPage);
        },
        paginatedArticles() {
            const startIndex = (this.currentPage - 1) * this.articlesPerPage;
            const endIndex = startIndex + this.articlesPerPage;
            return this.articles.slice(startIndex, endIndex);
        },
        visiblePages() {
            const totalPagesToShow = this.pageRange;
            let startPage = this.currentPage - Math.floor(totalPagesToShow / 2);
            startPage = Math.max(startPage, 1);
            let endPage = startPage + totalPagesToShow - 1;
            if (endPage > this.totalPages) {
                endPage = this.totalPages;
                startPage = Math.max(endPage - totalPagesToShow + 1, 1);
            }
            const pages = [];
            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }
            return pages;
        }
    },
}
</script>
