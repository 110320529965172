<template>
    <div class="preloader-overlay">
        <div class="preloader">
            <img src="/logo-favicon.png" alt="Cargando..." class="loader-image" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyPreloader',
}
</script>

<style scoped>
.preloader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 9999;
}

.preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loader-image {
    width: 100px; 
    height: 100px;
    animation: spin 2s linear infinite; 
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
