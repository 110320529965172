<template>
    <div class="background-blur" v-if="show">
        <div class="container-center">
            <div class="custom-container white z-depth-2">
                <ul class="custom-tabs teal">
                    <li class="custom-tab col s3" :class="{ 'active': selectedTab === 'login' }" @click="selectTab('login')">
                        <a class="white-text" href="#">Iniciar sesión</a>
                    </li>
                    <li class="custom-tab col s3" :class="{ 'active': selectedTab === 'register' }" @click="selectTab('register')">
                        <a class="white-text" href="#">Crear cuenta</a>
                    </li>
                </ul>
                <button class="close-btn" @click="handleClose">&times;</button>
                <div class="custom-form-container">
                    <h3 class="teal-text">{{ selectedTab === 'login' ? 'Iniciar sesión' : 'Crear cuenta' }}</h3>
                    <div v-if="selectedTab === 'login'">
                        <form @submit.prevent="handleLogin">
                            <div class="row">
                                <div class="input-field col s12">
                                    <label for="login-email">Correo</label>
                                    <input id="login-email" type="email" class="validate" v-model="email" required>
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s12">
                                    <label for="login-password">Contraseña</label>
                                    <input id="login-password" type="password" class="validate" v-model="password" required>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12">
                                    <button class="btn waves-effect waves-light teal" type="submit" :disabled="loading">
                                        <span v-if="loading">Cargando...</span>
                                        <span v-else>Iniciar sesión</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div v-if="selectedTab === 'register'">
                        <form @submit.prevent="handleRegister">
                            <div class="row">
                                <div class="input-field col s12">
                                    <label for="register-first-name">Nombres</label>
                                    <input id="register-first-name" type="text" class="validate" v-model="firstName" required>
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s12">
                                    <label for="register-email">Correo</label>
                                    <input id="register-email" type="email" class="validate" v-model="registerEmail" required>
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s12">
                                    <label for="register-password">Contraseña</label>
                                    <input id="register-password" type="password" class="validate" v-model="registerPassword" required>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12">
                                    <button class="btn waves-effect waves-light teal" type="submit" :disabled="loading">
                                        <span v-if="loading">Cargando...</span>
                                        <span v-else>Registrarse</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <Preloader v-if="loading" />
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2';
import Preloader from '@/components/Preloader.vue'; // Importa el componente Preloader

export default {
    name: 'LoginPage',
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Preloader // Declara el componente Preloader
    },
    data() {
        return {
            selectedTab: 'login', // Inicia con el formulario de inicio de sesión seleccionado
            email: '',
            password: '',
            firstName: '',
            registerEmail: '',
            registerPassword: '',
            loading: false // Estado de carga
        };
    },
    watch: {
        show(val) {
            if (!val) {
                this.resetForm();
            }
        }
    },
    methods: {
        async handleLogin() {
            this.loading = true;
            try {
                const response = await fetch(`${this.$apiUrl}/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify({ email: this.email, password: this.password })
                });

                const data = await response.json();
                if (data.success) {
                    localStorage.setItem('user', JSON.stringify(data.user));
                    localStorage.setItem('authToken', data.token);

                    this.$emit('loginSuccess', data);
                    this.closeForm();
                    if (!data.verify) {
                        this.showResendVerificationNotification(data.message);
                    }
                } else {
                    this.showErrorNotification(data.message);
                }
            } catch (error) {
                console.error(error);
                this.showErrorNotification('Error de inicio de sesión', error.message);
            } finally {
                this.loading = false;
            }
        },
        async handleRegister() {
            this.loading = true;
            try {
                const response = await fetch(`${this.$apiUrl}/register`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify({
                        name: this.firstName,
                        email: this.registerEmail,
                        password: this.registerPassword
                    })
                });

                const data = await response.json();
                if (response.ok) {
                    localStorage.setItem('user', JSON.stringify(data.user));
                    localStorage.setItem('authToken', data.token);

                    this.showSuccessNotification(data.message);
                    this.$emit('loginSuccess', data);
                    this.closeForm();
                } else {
                    let errorMessage = 'Error de registro';
                    if (data.errors) {
                        errorMessage = Object.values(data.errors).flat().join(', ');
                    }
                    throw new Error(errorMessage);
                }
            } catch (error) {
                console.error(error);
                this.showErrorNotification('Error de registro', error.message);
            } finally {
                this.loading = false;
            }
        },
        selectTab(tab) {
            this.selectedTab = tab;
        },
        closeForm() {
            this.$emit('close'); // Emitir un evento para indicar que se cerró el formulario
        },
        handleClose() {
            this.closeForm();
            this.resetForm(); // Restablecer el formulario al cerrarlo
        },
        resetForm() {
            this.email = '';
            this.password = '';
            this.firstName = '';
            this.registerEmail = '';
            this.registerPassword = '';
            this.loading = false; // Restablecer el estado de carga
        },
        showSuccessNotification(message) {
            Swal.fire({
                icon: 'success',
                title: '¡Registro Exitoso!',
                text: message,
                confirmButtonText: 'Aceptar'
            });
        },
        showErrorNotification(title, message) {
            Swal.fire({
                icon: 'error',
                title: title,
                text: message,
                confirmButtonText: 'Aceptar'
            });
        },
        showResendVerificationNotification(message) {
            Swal.fire({
                icon: 'warning',
                title: 'Correo no verificado',
                text: message,
                showCancelButton: true,
                confirmButtonText: 'Reenviar verificación',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.resendVerificationEmail();
                }
            });
        },
        resendVerificationEmail() {
            const token = localStorage.getItem('authToken');
            fetch(`${this.$apiUrl}/email/notification`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ email: this.email })
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        this.showSuccessNotification('Se ha reenviado el correo de verificación.');
                    } else {
                        this.showErrorNotification('Error', data.message || 'No se pudo reenviar el correo de verificación.');
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.showErrorNotification('Error', error.message);
                });
        }
    }
};
</script>

<style scoped>
.background-blur {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.container-center {
    z-index: 2;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-container {
    width: 400px;
    padding: 40px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
    color: #888;
}

.close-btn:hover {
    color: #555;
}

.custom-tabs {
    background: linear-gradient(to right, #54abc8, var(--e-global-color-principal-alternative));
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-around;
    border-radius: 5px;
}

.custom-tab {
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.custom-tab:hover {
    background-color: #1A3E89;
}

.custom-tab.active {
    background-color: linear-gradient(to right, #54abc8, var(--e-global-color-principal-alternative));
}

.custom-tabs a {
    text-decoration: none;
    color: white;
}

.custom-form-container {
    padding-top: 20px;
}

input[type="text"],
input[type="password"],
input[type="email"] {
    width: calc(100% - 0px);
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus {
    border-color: #00bcd4;
}

.btn {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: linear-gradient(to right, #54abc8, var(--e-global-color-principal-alternative));
    color: white;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background: #1A3E89;
}

.teal-text {
    color: #1A3E89;

}

@media (max-width: 600px) {
    .custom-container {
        width: 90%;
    }
}
</style>
