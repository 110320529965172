<!-- SidebarBlog.vue -->
<template>
    <div class="sidebar-widget sidebar-widget-1">
        <form class="search-form" @submit.prevent>
            <input type="text" v-model="searchQuery" placeholder="Ejemplo: Audio IP..." />
            <button type="submit" aria-label="Buscar"><i class="fas fa-search"></i></button>
        </form>
    </div>
    <div class="sidebar-widget">
        <div class="widget-title" @click="toggleSection('temas')">
            <h3 class="title">Temas</h3>
        </div>
        <TemasNavigation 
            v-if="(selectedSection === 'temas' || !isMobile) || searchQuery" 
            :search-query="searchQuery" 
            @select-tema="selectTema">
        </TemasNavigation>
    </div>

    <div class="sidebar-widget">
        <div class="widget-title" @click="toggleSection('categorias')">
            <h3 class="title">Categorías</h3>
        </div>
        <CategorieNavigation 
            v-if="(selectedSection === 'categorias' || !isMobile) || searchQuery" 
            :search-query="searchQuery" 
            @select-categoria="selectCategoria" 
            :routeName="'BlogSearch'">
        </CategorieNavigation>
    </div>

    <div class="sidebar-widget" v-if="!isMobile || selectedSection === 'publicaciones'">
        <div class="widget-title" @click="toggleSection('publicaciones')">
            <h3 class="title">Publicaciones Recientes</h3>
        </div>
        <PublicationNavigation v-if="selectedSection === 'publicaciones' || !isMobile"></PublicationNavigation>
    </div>
</template>

<script>
import TemasNavigation from './navigation/TemasNavigation';
import CategorieNavigation from './navigation/CategorieNavigation';
import PublicationNavigation from './navigation/PublicationNavigation';

export default {
    name: 'SidebarBlog',
    components: {
        TemasNavigation,
        CategorieNavigation,
        PublicationNavigation
    },
    data() {
        return {
            searchQuery: '',
            selectedSection: null,
            isMobile: window.innerWidth <= 768
        }
    },
    emits: ['select-tema', 'select-categoria'],
    methods: {
        toggleSection(section) {
            this.selectedSection = this.selectedSection === section ? null : section;
        },
        selectTema(temaId) {
            this.$emit('select-tema', temaId);
        },
        selectCategoria(categoriaId) {
            this.$emit('select-categoria', categoriaId);
        },
        handleResize() {
            this.isMobile = window.innerWidth <= 768;
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    }
}
</script>

<style scoped>
.search-bar {
    margin-bottom: 20px;
}

.search-bar input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
}
</style>
