<template>
    <div class="sidebar-widget sidebar-widget-1">
        <form class="search-form" @submit.prevent>
            <input type="text" v-model="searchQuery" placeholder="Ejemplo: Audio IP..." />
            <button type="submit" aria-label="Buscar"><i class="fas fa-search"></i></button>
        </form>
    </div>
    <div class="sidebar-widget">
        <div class="widget-title" @click="toggleSection('marcas')">
            <h3 class="title" aria-level="2">Marcas</h3>
        </div>
        <BrandNavigation v-if="(selectedSection === 'marcas' || !isMobile) || searchQuery" :search-query="searchQuery" @select-tema="selectTema"></BrandNavigation>
    </div>

    <div class="sidebar-widget">
        <div class="widget-title" @click="toggleSection('categorias')">
            <h3 class="title" aria-level="3">Categorías</h3>
        </div>
        <CategorieNavigation v-if="(selectedSection === 'categorias' || !isMobile) || searchQuery" :search-query="searchQuery" @select-categoria="selectCategoria" :routeName="'StorePage'"></CategorieNavigation>
    </div>

    <!-- Ocultar completamente la sección de Publicaciones Recientes en móviles -->
    <div class="sidebar-widget" v-if="!isMobile || selectedSection === 'productos'">
        <div class="widget-title" @click="toggleSection('productos')">
            <h3 class="title">Productos agregados</h3>
        </div>
        <ProductAdd v-if="selectedSection === 'productos' || !isMobile"></ProductAdd>
    </div>
</template>

<script>
import BrandNavigation from './navigation/BrandNavigation';
import CategorieNavigation from './navigation/CategorieNavigation';
import ProductAdd from './navigation/ProductAdd';

export default {
    name: 'SidebarStoreLarge',
    components: {
        BrandNavigation,
        CategorieNavigation,
        ProductAdd
    },
    data() {
        return {
            searchQuery: '',
            selectedSection: null,
            isMobile: window.innerWidth <= 768
        }
    },
    emits: ['select-tema', 'select-categoria'],
    methods: {
        toggleSection(section) {
            this.selectedSection = this.selectedSection === section ? null : section;
        },
        selectTema(temaId) {
            this.$emit('select-tema', temaId);
        },
        selectCategoria(categoriaId) {
            this.$emit('select-categoria', categoriaId);
        },
        handleResize() {
            this.isMobile = window.innerWidth <= 768;
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    }
}
</script>

<style scoped>
.search-bar {
    margin-bottom: 20px;
}

.search-bar input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
}
</style>
