<template>
    <Preloader v-if="loading" />
    <div v-else>
        <div class="sub-banner">
            <NavbarMenu @logout="handleLogout" @loginSuccess="checkVerification" />
            <figure class="sub-bannersideshape mb-0">
                <Image src="/banner/sub-bannersideshape.png" alt="image" />
            </figure>
            <section class="banner-section banner-section-about">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 col-md-6 col-sm-12 col-12">
                            <div class="banner_content" data-aos="fade-right">
                                <h1 class="text-white custom-standard-title">{{ noticeHead.titulo }}</h1>
                                <p class="text-white custom-standard-subtitle">
                                    {{ noticeHead.subtitulo }}
                                </p>
                                <div class="box custom-standard-box">
                                    <span class="mb-0">Inicio</span><i class="first fa-regular fa-angle-right"></i><i
                                        class="second fa-regular fa-angle-right"></i><span
                                        class="mb-0 box_span">Noticias</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-sm-12 col-12">
                            <div class="banner_wrapper">
                                <figure
                                    class="mb-0  d-flex justify-content-center align-items-center sub-bannerimage-about">
                                    <ImageWeb :src="'/' + noticeHead.imagen" :alt="noticeHead.imagen"
                                        class="img-fluid fixed-size-blog" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <figure class="sub-bannersideshape2 mb-0">
                    <Image src="/banner/sub-bannersideshape2.png" alt="img-fluid" />
                </figure>
            </section>
        </div>
        <div class="section unsubscribe-section section-padding">
            <div class="container">
                <div class="unsubscribe-wrap">
                    <h2 class="unsubscribe-title">Lamentamos que te vayas</h2>
                    <p class="unsubscribe-text">Por favor, selecciona la razón de tu desuscripción:</p>
                    <form @submit.prevent="submitUnsubscribe">
                        <div class="unsubscribe-form__group">
                            <label>
                                <input type="radio" name="reason" value="1" v-model="selectedReason"> No encuentro las
                                noticias relevantes
                            </label>
                        </div>
                        <div class="unsubscribe-form__group">
                            <label>
                                <input type="radio" name="reason" value="2" v-model="selectedReason"> Recibo demasiados
                                correos
                            </label>
                        </div>
                        <div class="unsubscribe-form__group">
                            <label>
                                <input type="radio" name="reason" value="3" v-model="selectedReason"> Las noticias no
                                son interesantes
                            </label>
                        </div>
                        <div class="unsubscribe-form__group">
                            <label>
                                <input type="radio" name="reason" value="4" v-model="selectedReason"> Otro
                            </label>
                            <textarea v-if="selectedReason === '4'" placeholder="Por favor, especifique"
                                v-model="customReason" class="unsubscribe-form-control"></textarea>
                        </div>
                        <div class="unsubscribe-form__group unsubscribe-submit-group">
                            <input type="submit" value="Enviar" class="btn-default">
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <FooterMenu></FooterMenu>
    </div>
</template>

<script>
import NavbarMenu from '@/components/NavbarMenu.vue';
import FooterMenu from '@/components/FooterMenu.vue';
import Image from '@/components/ImagePublic.vue';
import Preloader from '@/components/Preloader.vue';
import Swal from 'sweetalert2';

export default {
    name: 'UnsubscribePage',
    components: {
        NavbarMenu,
        FooterMenu,
        Image,
        Preloader,
    },
    data() {
        return {
            selectedReason: '',
            customReason: '',
            subscriberEmail: '',
            noticeHead: {
                id: '',
                titulo: '',
                subtitulo: '',
                imagen: ''
            },
            loading: true,
            loadingFinished1: false,
            loadingFinished2: false,
            minLoadingTime: null,
        };
    },
    created() {
        this.minLoadingTime = new Promise((resolve) => {
            setTimeout(resolve, 750);
        });
        this.cargarHeader();
    },
    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        this.subscriberEmail = urlParams.get('email');
        this.loadingFinished2 = true;
        this.checkLoading();
    },
    methods: {
        cargarHeader() {
            fetch(`${this.$apiUrl}/mostrar-noticia/1`)
                .then((response) => response.json())
                .then((data) => {
                    this.noticeHead.titulo = data.titulo;
                    this.noticeHead.subtitulo = data.subtitulo;
                    this.noticeHead.imagen = data.imagen;
                })
                .catch((error) => {
                    console.error('Error al cargar datos:', error);
                })
                .finally(() => {
                    this.loadingFinished1 = true;
                    this.checkLoading();
                });
        },
        submitUnsubscribe() {
            let data = {
                correo: this.subscriberEmail,
                razon_id: this.selectedReason
            };

            if (this.selectedReason === '4') {
                data.razon_secundaria = this.customReason;
            }

            fetch(`${this.$apiUrl}/unsubscribe`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(result => {
                    Swal.fire({
                        title: '¡Éxito!',
                        text: result.message,
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    }).then(() => {
                        window.location.href = '/';
                    });
                })
                .catch(error => {
                    console.error('Error al desuscribirse', error);
                    Swal.fire({
                        title: 'Inténtelo más tarde',
                        text: 'No se realizó la desuscripción',
                        icon: 'warning',
                        confirmButtonText: 'Aceptar'
                    });
                });
        },
        checkLoading() {
            if (this.loadingFinished1 && this.loadingFinished2) {
                this.minLoadingTime.then(() => {
                    this.loading = false;
                });
            }
        },
    }
}
</script>

<style scoped>
.unsubscribe-section {
    background-color: #f8f9fa;
}

.unsubscribe-wrap {
    background: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 0 auto;
}

.unsubscribe-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-align: center;
}

.unsubscribe-text {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
    text-align: center;
}

.unsubscribe-form__group {
    margin-bottom: 20px;
}

.unsubscribe-form__group label {
    font-size: 16px;
    color: #333;
}

.unsubscribe-form-control {
    width: 100%;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 10px;
    resize: none;
    height: 100px;
    overflow: hidden;
}

.unsubscribe-submit-group {
    display: flex;
    justify-content: center;
}
</style>
