<template>
    <Preloader v-if="loading" />
    <div v-else>
        <div class="sub-banner">
            <NavbarMenu></NavbarMenu>
            <figure class="sub-bannersideshape mb-0">
                <Image src="/banner/sub-bannersideshape.png" alt="" class="img-fluid" />
            </figure>
            <section class="banner-section banner-section-about">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 col-md-6 col-sm-12 col-12">
                            <div class="banner_content" data-aos="fade-right">
                                <h1 class="text-white custom-standard-title">{{ about.titulo }}</h1>
                                <p class="text-white custom-standard-subtitle">{{ about.subtitulo }}</p>
                                <div class="box custom-standard-box">
                                    <span class="mb-0">Inicio</span>
                                    <i class="first fa-regular fa-angle-right"></i>
                                    <i class="second fa-regular fa-angle-right"></i>
                                    <span class="mb-0 box_span">Nosotros</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-sm-12 col-12">
                            <div class="banner_wrapper">
                                <figure class="mb-0 d-flex justify-content-center align-items-center sub-bannerimage-about">
                                    <ImageWeb :src="'/' + about.imagen" alt="" class="img-fluid fixed-size-blog" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <figure class="sub-bannersideshape2 mb-0">
                    <Image src="/banner/sub-bannersideshape2.png" alt="" class="img-fluid" />
                </figure>
            </section>
        </div>
        <section class="aboutpage-section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
                        <div class="aboutpage_wrapper">
                            <figure class="mb-0">
                                <Image src="/nosotros/logo_degra.png" alt="" class="img-fluid" />
                            </figure>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 col-12 ">
                        <div class="aboutpage_content" data-aos="fade-right" ref="divhistoria">
                            <h5 aria-level="2">Nuestra Historia</h5>
                            <div>
                                <p class="text-size-18" style="text-align: left;">
                                    {{ mostrarTexto }}
                                </p>
                                <span class="text-about" v-if="!mostrarMas" @click="mostrarMas = true"
                                    style="cursor: pointer;">Ver más</span>
                                <span class="text-about" v-else @click="mostrarMas = false" style="cursor: pointer;">Ver
                                    menos</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <section class="aboutpage-section-mision">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div class="aboutpage_content" data-aos="fade-right">
                            <h2>Misión</h2>
                            <p class="text-size-18" style="text-align: left;">{{ about.mision }}</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div class="aboutpage_wrapper">
                            <figure class="mb-0 aboutpage-image">
                                <Image src="/nosotros/MISION.png" alt="" class="img-fluid" />
                            </figure>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <section class="aboutpage-section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div class="aboutpage_wrapper">
                            <figure class="mb-0 aboutpage-image">
                                <Image src="/nosotros/VISION.png" alt="" class="img-fluid" />
                            </figure>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div class="aboutpage_content" data-aos="fade-right">
                            <h2>Visión</h2>
                            <p class="text-size-18" style="text-align: left;">{{ about.vision }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="service-section position-relative">
            <div class="container">
                <figure class="service-image mb-0">
                    <Image src="/banner/service-image.png" class="img-fluid" alt="" />
                </figure>
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div class="service_content" data-aos="fade-right">
                            <br><br>
                            <!-- <h5> Politicas de la empresa </h5> -->
                            <h2>Políticas de la empresa</h2>
                            <p class="text-size-18" style="text-align: left">
                                En Mylcom, nuestras políticas reflejan nuestros valores fundamentales: integridad,
                                igualdad y seguridad. Nos comprometemos a mantener la transparencia y ética en nuestras
                                interacciones comerciales, priorizando la seguridad de nuestro equipo y promoviendo un
                                ambiente laboral libre de hostigamiento y sustancias que afecten el rendimiento. Estas
                                políticas son esenciales para una cultura organizacional responsable.</p>
                            <!-- <a class="read_more text-decoration-none" style="cursor: pointer;">
                                Redactar Denuncia
                                <i class="circle fa-regular fa-angle-right"> </i></a> -->
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div id="fixed-form-container-report" v-if="mostrarFormulario">
                            <div class="body" style="display: block;">
                                <form id="contactpage1" method="POST" action="./contact-form.php">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Tu Nombre:" name="name">
                                    </div>
                                    <div class="form-group">
                                        <input type="email" class="form-control" placeholder="Tu Correo:"
                                            name="emailid">
                                    </div>
                                    <div class="form-group">
                                        <input type="tel" class="form-control" placeholder="Tu Celular:" name="phone">
                                    </div>
                                    <div class="form-group">
                                        <textarea class="form_style" placeholder="Mensaje" rows="3"
                                            name="msg"></textarea>
                                    </div>
                                    <button type="submit" class="submit_now text-decoration-none">Enviar</button>
                                </form>
                            </div>
                        </div>
                        <div class="service_contentbox" v-if="mostrarPdfs">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <a href="#" @click.prevent="descargarPDF(`${this.$apiUrl}/${about.pol_obsequio}`)"
                                        target="_blank">
                                        <div class="service-box box-mb">
                                            <figure class="service-reboticon">
                                                <Image src="/nosotros/ACEPTACION-O-ENTREGA-DE-OBSEQUIOS.svg"
                                                    class="img-fluid" alt="" />
                                            </figure>
                                            <h4></h4>
                                            <p class="text-size-16 mb-2">POLÍTICA QUE REGULA LA EVENTUAL ACEPTACION Y
                                                ENTREGA DE OBSEQUIOS</p>
                                        </div>
                                    </a>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <a href="#" @click.prevent="descargarPDF(`${this.$apiUrl}/${about.pol_negativa}`)"
                                        target="_blank">
                                        <div class="box-top">
                                            <div class="service-box box-mb">
                                                <figure class="service-learningicon">
                                                    <Image src="/nosotros/TRABAJO-INSEGURO.svg" class="img-fluid"
                                                        alt="" />
                                                </figure>
                                                <h4>
                                                </h4>
                                                <p class="text-size-16 mb-2">POLÍTICA DE NEGATIVA A REALIZAR UN TRABAJO
                                                    INSEGURO
                                                </p>
                                                <!-- <a class="read_more text-decoration-none" href="./service.html"> Read
                                                More </a> -->
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <a href="#" @click.prevent="descargarPDF(`${this.$apiUrl}/${about.pol_prevencion}`)"
                                        target="_blank">
                                        <div class="service-box">
                                            <figure class="service-scienceicon">
                                                <Image src="/nosotros/HOSTIGAMIENTO-SEXUAL.svg" class="img-fluid"
                                                    alt="" />
                                            </figure>
                                            <h4></h4>
                                            <p class="text-size-16 mb-2">POLÍTICA DE PREVENCION Y SANCION FRENTE AL
                                                HOSTIGAMIENTO
                                                SEXUAL</p>
                                            <!-- <a class="read_more text-decoration-none" href="./service.html">Read
                                            More</a> -->
                                        </div>
                                    </a>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <a href="#" @click.prevent="descargarPDF(`${this.$apiUrl}/${about.pol_alcohol}`)"
                                        target="_blank">
                                        <div class="box-top">
                                            <div class="service-box">
                                                <figure class="service-analysicon">
                                                    <Image src="/nosotros/POLITICA-DE-ALCOHOL-Y-DROGAS.svg"
                                                        class="img-fluid" alt="" />
                                                </figure>
                                                <h4>
                                                </h4>
                                                <br>
                                                <p class="text-size-16 mb-2">POLÍTICA INTEGRAL DE ALCOHOL Y DROGAS
                                                </p>
                                                <!-- <a class="read_more text-decoration-none" href="./service.html">Read
                                                More</a> -->
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-space">
            <div class="container">
                <div class="row v-center">
                    <div class="col-lg-6">
                        <Image src="/svg/our-team.png" alt="ourteam" />
                    </div>
                    <div class="col-lg-6 mmt40">
                        <!-- <span class="scriptheading dashbefore mb15">Why Blueket?</span> -->
                        <h2>Valores de la empresa</h2>
                        <div class="medialist mt40">
                            <div class="mediablock">
                                <div class="icondiv">
                                    <Image src="/valores/INTEGRIDAD.png" alt="icon" />
                                </div>
                                <div class="mediainfoblock">
                                    <h5 class="mb10" aria-level="3">Integridad</h5>
                                    <p>Nos comprometemos a actuar con honestidad y transparencia en todas nuestras
                                        operaciones, manteniendo altos estándares éticos en cada interacción con
                                        clientes,
                                        empleados y socios comerciales.</p>
                                </div>
                            </div>
                            <div class="mediablock mt30">
                                <div class="icondiv">
                                    <Image src="/valores/INNOVACION.png" alt="icon" />
                                </div>
                                <div class="mediainfoblock">
                                    <h5 class="mb10">Innovación</h5>
                                    <p>Fomentamos la creatividad y el espíritu innovador en todo lo que hacemos,
                                        buscando
                                        constantemente nuevas formas de mejorar y desarrollar soluciones tecnológicas
                                        que
                                        impulsen el éxito de nuestros clientes.</p>
                                </div>
                            </div>
                            <div class="mediablock mt30">
                                <div class="icondiv">
                                    <Image src="/valores/EXCELENCIA.png" alt="icon" />
                                </div>
                                <div class="mediainfoblock">
                                    <h5 class="mb10">Excelencia</h5>
                                    <p>Nos esforzamos por alcanzar la excelencia en todo lo que hacemos, buscando la
                                        perfección en la calidad de nuestros servicios, la atención al cliente y el
                                        cumplimiento de nuestros compromisos.
                                    </p>
                                </div>
                            </div>
                            <div class="mediablock mt30">
                                <div class="icondiv">
                                    <Image src="/valores/COMPROMISO-CON-EL-CLIENTE.png" alt="icon" />
                                </div>
                                <div class="mediainfoblock">
                                    <h5 class="mb10">Compromiso con el cliente</h5>
                                    <p>Colocamos las necesidades y el éxito de nuestros clientes en el centro de todo lo
                                        que
                                        hacemos, brindando un servicio personalizado y enfocado en satisfacer sus
                                        requerimientos de manera efectiva y oportuna.
                                    </p>
                                </div>
                            </div>
                            <div class="mediablock mt30">
                                <div class="icondiv">
                                    <Image src="/valores/TRABAJO-EN-EQUIPO.png" alt="icon" />
                                </div>
                                <div class="mediainfoblock">
                                    <h5 class="mb10">Trabajo en equipo</h5>
                                    <p>Valoramos la colaboración y el trabajo en equipo, reconociendo que juntos podemos
                                        lograr más. Fomentamos un ambiente de respeto mutuo, confianza y apoyo, donde
                                        cada
                                        miembro del equipo pueda contribuir con su talento y experiencia hacia el logro
                                        de
                                        nuestros objetivos comunes.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <FooterMenu></FooterMenu>
    </div>
</template>

<script>


import NavbarMenu from '../components/NavbarMenu.vue'
import FooterMenu from '../components/FooterMenu.vue'
import Image from '@/components/ImagePublic.vue';
import ImageWeb from '@/components/ImagePublicWeb.vue';
import Preloader from '@/components/Preloader.vue';
import { updateMetaTags } from '../metaUtils';

export default {
    name: 'AboutPage',
    components: {
        NavbarMenu,
        FooterMenu,
        Image,
        ImageWeb,
        Preloader,
    },

    data() {
        return {
            mostrarPdfs: true,
            mostrarFormulario: false,
            mostrarMas: false,
            primeraParte: "",
            segundaparte: '',
            about: {
                titulo: '',
                subtitulo: '',
                imagen: '',
                historia: '',
                mision: '',
                vision: '',
                pol_obsequio: '',
                pol_prevencion: '',
                pol_negativa: '',
                pol_alcohol: ''
            },
            loading: true,
            loadingFinished1: false,
            minLoadingTime: null,
        };
    },
    computed: {
        mostrarTexto() {
            if (this.mostrarMas) {
                return this.primeraParte + this.segundaParte;
            } else {
                return this.primeraParte + '...';
            }
        }
    },
    created() {
        this.minLoadingTime = new Promise((resolve) => {
            setTimeout(resolve, 750);
        });
        this.cargarDatos();
    },
    methods: {
        updateMeta(about) {
            const routeMeta = {
                title: 'Mylcom - About',
                description: about.subtitulo,
                image: `${this.$apiImgWeb}/images/web/${about.imagen}`, // Construye la URL completa de la imagen
                canonical: `${window.location.origin}${this.$route.fullPath}`,

            };
            updateMetaTags({ meta: routeMeta });
        },
        cargarDatos() {
            fetch(`${this.$apiUrl}/nosotros/1`)
                .then((response) => { return response.json(); })
                .then((data) => {
                    this.about.titulo = data.titulo;
                    this.about.subtitulo = data.subtitulo;
                    this.about.imagen = data.imagen;
                    this.about.mision = data.mision;
                    this.about.vision = data.vision;
                    this.about.pol_obsequio = data.pol_obsequio;
                    this.about.pol_prevencion = data.pol_prevencion;
                    this.about.pol_negativa = data.pol_negativa;
                    this.about.pol_alcohol = data.pol_alcohol;
                    this.updateMeta(data); // Actualiza las etiquetas meta dinámicamente

                    const partes = this.dividirTexto(data.historia);
                    this.primeraParte = partes.primeraParte;
                    this.segundaParte = partes.segundaParte;
                })
                .catch((error) => {
                    console.error('Error al cargar datos:', error);
                })
                .finally(() => {
                    this.loadingFinished1 = true;
                    this.checkLoading();
                });

        },
        toggleFormulario() {
            this.mostrarFormulario = !this.mostrarFormulario;
            this.mostrarPdfs = !this.mostrarPdfs;
        },
        descargarPDF(rutaPDF) {
            // Aquí va tu lógica para descargar el PDF automáticamente
            fetch(rutaPDF)
                .then(response => response.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', rutaPDF.split('/').pop()); // Obtener el nombre del archivo desde la ruta
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(error => {
                    console.error('Error al descargar el archivo PDF:', error);
                });
        },
        dividirTexto(texto) {
            const primeraParte = texto.substring(0, 400);
            const segundaParte = texto.substring(400);
            return { primeraParte, segundaParte };
        },
        checkLoading() {
            if (this.loadingFinished1) {
                this.minLoadingTime.then(() => {
                    this.loading = false;
                });
            }
        },
    }
};
</script>

<style scoped>

.section-space {
    padding-top: 100px;
    padding-bottom: 100px;
}

.v-center {
    align-items: center;
}

.dashbefore {
    position: relative;
    padding-left: 25px;
}

.scriptheading {
    color: var(--orange-color);
    letter-spacing: 2px;
    display: block;
}

.mb15 {
    margin-bottom: 15px;
}

.dashbefore:before {
    content: "";
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 12px;
    height: 2px;
    background: var(--orange-color);
}

.mt40 {
    margin-top: 40px;
}

.mediablock {
    display: flex;
    grid-gap: 25px;
    align-items: center;
}

.mediablock div:first-child {
    flex-shrink: 0;
}

.icondiv {
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mb10 {
    margin-bottom: 10px;
}

.mediainfoblock p {
    font-size: 16px;
}

.mt30 {
    margin-top: 30px;
}


.text-about {
    font-size: 15px;
    line-height: 28px;
    font-weight: 400;
    text-transform: uppercase;
    color: var(--e-global-color-secondary);
}

.service-section .service_content .read_more:hover {
    color: var(--e-global-color-white);
    background-image: linear-gradient(to right, #052b45 0%, #052b45 51%, #052b45 100%);
}

.service-section .service_content .read_more {
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    font-family: "Manrope", sans-serif;
    padding: 4px 2px 4px 42px;
    text-align: center;
    display: inline-block;
    border-radius: 30px 30px 30px 0;
    color: var(--e-global-color-white) !important;
    transition: all 0.3s ease-in-out;
    background: linear-gradient(to right, var(--e-global-color-principal-alternative), var(--e-global-color-alternative));
}
</style>