<template>
    <Preloader v-if="loading" />
    <div v-else>
        <div class="sub-banner">
            <NavbarMenu></NavbarMenu>
            <figure class="sub-bannersideshape mb-0">
                <Image src="/banner/sub-bannersideshape.png" alt="" class="img-fluid" />
            </figure>
            <section class="banner-section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 col-md-6 col-sm-12 col-12">
                            <div class="banner_content" data-aos="fade-right">
                                <h1 class="text-white"> {{ blogHead.titulo }} </h1>
                                <p class="text-white" style="text-align: justify"> {{ blogHead.subtitulo }} </p>
                                <div class="box">
                                    <span class="mb-0">Inicio</span><i class="first fa-regular fa-angle-right"></i><i
                                        class="second fa-regular fa-angle-right"></i><span class="mb-0 box_span">{{
                                            blogHead.titulo }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-sm-12 col-12">
                            <div class="banner_wrapper">
                                <figure
                                    class="mb-0 d-flex justify-content-center align-items-center sub-bannerimage-about">
                                    <ImageWeb :src="'/' + blogHead.imagen" :alt="blogHead.imagen"
                                        class=" img-fluid fixed-size-blog" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <figure class="sub-bannersideshape2 mb-0">
                    <Image src="/banner/sub-bannersideshape2.png" alt="" class="img-fluid" />
                </figure>
            </section>
        </div>
        <div class="section blog-details-section section-padding">
            <div class="container">
                <div class="blog-details-wrap">
                    <div class="filters">
                        <div class="filter-labels">
                            <span>Filtrado por:</span>
                            <span v-if="filteredTema" class="filter-item">
                                Tema: {{ getTemaNombre(filteredTema) }}
                                <button @click="clearFilter" class="clear-filter-btn">x</button>
                            </span>
                            <span v-if="filteredCategoria" class="filter-item">
                                Categoría: {{ getCategoriaNombre(filteredCategoria) }}
                                <button @click="clearFilter" class="clear-filter-btn">x</button>
                            </span>
                        </div>
                    </div>
                    <div class="row sidebar-row">
                        <div class="col-lg-8">
                            <div class="blog-post-wrap">
                                <div class="blog-container">
                                    <div v-for="post in paginatedPosts" :key="post.id"
                                        class="single-blog single-blog-post">
                                        <div class="blog-img">
                                            <router-link :to="{ name: 'BlogPost', params: { slug: post.slug } }">
                                                <ImageWeb :src="'/' + post.imagen" :alt="post.imagen" />
                                            </router-link>
                                            <div class="top-meta">
                                                <span class="date"><span>{{ getDay(post.created_at) }}</span>{{
                                                    getMonth(post.created_at) }}</span>
                                            </div>
                                        </div>
                                        <div class="blog-content">
                                            <div class="blog-meta">
                                                <span><i class="fas fa-user"></i> <a href="#">{{ post.autor
                                                        }}</a></span>
                                                <span class="post-year">{{ getYear(post.created_at) }}</span>
                                            </div>
                                            <h3 class="title">
                                                <router-link :to="{ name: 'BlogPost', params: { slug: post.slug } }">
                                                    {{ post.titulo }}
                                                </router-link>
                                            </h3>
                                            {{ post.resumen }}
                                        </div>
                                        <div class="blog-btn">
                                            <router-link :to="{ name: 'BlogPost', params: { slug: post.slug } }"
                                                class="blog-btn-link">
                                                Leer el artículo completo<i class="fas fa-long-arrow-alt-right"></i>
                                            </router-link>
                                        </div>
                                    </div>
                                    <div v-if="paginatedPosts.length === 0" class="no-results-message">
                                        No se encontraron resultados para tu búsqueda.
                                    </div>
                                </div>
                            </div>
                            <div class="techmax-pagination" v-if="paginatedPosts.length > 0">
                                <ul class="pagination justify-content-center">
                                    <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                        <a class="page-link" href="#" @click.prevent="prevPage"><i
                                                class="flaticon-back"></i></a>
                                    </li>
                                    <li class="page-item" :class="{ active: page === currentPage }"
                                        v-for="page in visiblePages" :key="page">
                                        <a class="page-link" href="#" @click.prevent="goToPage(page)">{{ page }}</a>
                                    </li>
                                    <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                        <a class="page-link" href="#" @click.prevent="nextPage"><i
                                                class="flaticon-next"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="blog-sidebar">
                                <SidebarBlog>
                                </SidebarBlog>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterMenu></FooterMenu>
    </div>
</template>
<script>
import NavbarMenu from '@/components/NavbarMenu.vue';
import SidebarBlog from '@/components/SidebarBlog.vue';
import FooterMenu from '@/components/FooterMenu.vue';
import Image from '@/components/ImagePublic.vue';
import ImageWeb from '@/components/ImagePublicWeb.vue';
import Preloader from '@/components/Preloader.vue';
export default {
    props: ['slug', 'tipo'],
    name: 'BlogSearch',
    components: {
        NavbarMenu,
        FooterMenu,
        SidebarBlog,
        Image,
        ImageWeb,
        Preloader,
    },
    data() {
        return {
            blogHead: {
                id: '',
                titulo: '',
                subtitulo: '',
                imagen: ''
            },
            posts: [],
            temas: [],
            categorias: [],
            filteredTema: null,
            filteredCategoria: null,
            currentPage: 1,
            commentsPerPage: 5,
            loading: false,
            loadingFinished1: false,
            loadingFinished2: false,
            minLoadingTime: null,
            pageRange: 5,
            pageStart: 1,
        }
    },
    created() {
        this.minLoadingTime = new Promise((resolve) => {
            setTimeout(resolve, 750);
        });
        this.cargarHeader();
        this.cargarTemas();
        this.cargarCategorias();
        this.updateFilterLabels();
        this.filterPosts();
    },
    watch: {
        slug(newSlug) {
            this.updateFilterLabels();
            this.filterPosts(newSlug);
        },
        tipo(newTipo) {
            this.updateFilterLabels();
            this.filterPosts(newTipo);
        }
    },
    methods: {
        cargarHeader() {
            fetch(`${this.$apiUrl}/verblog/1`)
                .then((response) => response.json())
                .then((data) => {
                    this.blogHead.titulo = data.titulo;
                    this.blogHead.subtitulo = data.subtitulo;
                    this.blogHead.imagen = data.imagen;
                })
                .catch((error) => {
                    console.error('Error al cargar datos:', error);
                })
                .finally(() => {
                    this.loadingFinished1 = true;
                    this.checkLoading();
                });
        },
        cargarTemas() {
            fetch(`${this.$apiUrl}/listar-temas-post`)
                .then((response) => response.json())
                .then((data) => {
                    this.temas = data;
                    this.updateFilterLabels();
                })
                .catch((error) => {
                    console.error('Error al cargar temas:', error);
                });
        },
        cargarCategorias() {
            fetch(`${this.$apiUrl}/listar-categorias-post`)
                .then((response) => response.json())
                .then((data) => {
                    this.categorias = data;
                    this.updateFilterLabels();
                })
                .catch((error) => {
                    console.error('Error al cargar categorías:', error);
                });
        },
        filterPosts() {
            this.loading = true;
            let url = `${this.$apiUrl}/listar-posts/${this.tipo}/${this.slug}`;
            // let url = `${this.$apiUrl}/listar-posts`;
            let params = [];
            if (this.filteredTema) {
                params.push(`tema=${this.filteredTema}`);
            }
            if (this.filteredCategoria) {
                params.push(`categoria=${this.filteredCategoria}`);
            }
            if (params.length) {
                url += `?${params.join('&')}`;
            }
            fetch(url)
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        this.posts = data.data;
                        this.currentPage = 1; // Reset page to 1 after filtering
                    } else {
                        console.error('Error en la respuesta del servidor:', data.message);
                    }
                })
                .catch((error) => {
                    console.error("Error al cargar datos filtrados", error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        clearFilter() {
            this.filteredTema = null;
            this.filteredCategoria = null;
            this.$router.push({ name: 'BlogPage' }); // Redirigir a la vista que lista todos los posts
            this.filterPosts(); // Llamar a filterPosts para actualizar el contenido
        },
        getTemaNombre(id) {
            const tema = this.temas.find(t => t.id === id);
            return tema ? tema.nom_temas : 'Desconocido';
        },
        getCategoriaNombre(id) {
            const categoria = this.categorias.find(c => c.id === id);
            return categoria ? categoria.nom_categoria : 'Desconocido';
        },
        checkLoading() {
            if (this.loadingFinished1 && this.loadingFinished2) {
                this.minLoadingTime.then(() => {
                    this.loading = false;
                });
            }
        },
        getDay(dateString) {
            const date = new Date(dateString);
            return date.getUTCDate();
        },
        getMonth(dateString) {
            const date = new Date(dateString);
            const months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
            return months[date.getUTCMonth()];
        },
        getYear(dateString) {
            const date = new Date(dateString);
            return date.getUTCFullYear();
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                if (this.currentPage < this.pageStart) {
                    this.pageStart = Math.max(this.currentPage - this.pageRange + 1, 1);
                }
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                if (this.currentPage >= this.pageStart + this.pageRange) {
                    this.pageStart++;
                }
            }
        },
        goToPage(page) {
            this.currentPage = page;
            if (page >= this.pageStart + this.pageRange) {
                this.pageStart = page - this.pageRange + 1;
            } else if (page < this.pageStart) {
                this.pageStart = page;
            }
        },
        filterByTema(temaId) {
            this.filteredTema = temaId;
            this.filteredCategoria = null;
            this.$router.push({ name: 'BlogSearch', params: { tipo: 'tema', slug: this.getSlugById(temaId, 'tema') } });
        },
        filterByCategoria(categoriaId) {
            this.filteredCategoria = categoriaId;
            this.filteredTema = null;
            this.$router.push({ name: 'BlogSearch', params: { tipo: 'categoria', slug: this.getSlugById(categoriaId, 'categoria') } });
        },
        getSlugById(id, tipo) {
            if (tipo === 'tema') {
                const tema = this.temas.find(t => t.id === id);
                return tema ? tema.slug : '';
            } else if (tipo === 'categoria') {
                const categoria = this.categorias.find(c => c.id === id);
                return categoria ? categoria.slug : '';
            }
            return '';
        },
        updateFilterLabels() {
            if (this.tipo === 'tema') {
                this.filteredTema = this.temas.find(t => t.slug === this.slug)?.id || null;
                this.filteredCategoria = null;
            } else if (this.tipo === 'categoria') {
                this.filteredCategoria = this.categorias.find(c => c.slug === this.slug)?.id || null;
                this.filteredTema = null;
            } else {
                this.filteredTema = null;
                this.filteredCategoria = null;
            }
        }
    },
    computed: {
        totalPages() {
            return Math.ceil(this.posts.length / this.commentsPerPage);
        },
        paginatedPosts() {
            const start = (this.currentPage - 1) * this.commentsPerPage;
            const end = start + this.commentsPerPage;
            return this.posts.slice(start, end);
        },
        visiblePages() {
            const totalPagesToShow = 5;
            let startPage = this.currentPage - Math.floor(totalPagesToShow / 2);
            startPage = Math.max(startPage, 1);
            let endPage = startPage + totalPagesToShow - 1;
            if (endPage > this.totalPages) {
                endPage = this.totalPages;
                startPage = Math.max(endPage - totalPagesToShow + 1, 1);
            }
            const pages = [];
            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }
            return pages;
        }
    }
}
</script>

<style scoped>
.no-results-message {
    text-align: center;
    font-size: 18px;
    color: #777;
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    margin-top: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.filter-labels {
    font-size: 16px;
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 5px;
}

.filter-labels span {
    display: inline-block;
    margin-right: 10px;
    padding: 5px 10px;
    border-radius: 4px;
    background-color: #e9ecef;
    color: #333;
}

.filter-labels span:first-child {
    font-weight: bold;
    background-color: #4D9EC1;
    color: #ffffff;
    padding: 5px 10px;
}

.comment-form {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 40px;
}

.comment-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.ejemplo {
    font-size: 14px;
    color: #777;
    margin-bottom: 20px;
}

.single-form {
    margin-bottom: 20px;
}

.form-label {
    display: block;
    font-weight: 600;
    margin-bottom: 5px;
    color: #555;
}

.form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    color: #555;
    background-color: #fff;
    transition: border-color 0.3s ease;
}

.form-control:focus {
    border-color: #007bff;
    outline: none;
}

.form-btn {
    text-align: right;
}

.btn-submit {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-submit:hover {
    background-color: #0056b3;
}

.single-comment {
    margin-bottom: 20px;
}

.comment-author {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.author-info h5 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
}

.author-info span {
    font-size: 14px;
    color: #999;
}

.login-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.login-form-container {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.filter-item {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    padding: 5px 10px;
    border-radius: 4px;
    background-color: #e9ecef;
    color: #333;
}

.clear-filter-btn {
    position: absolute;
    top: -3px;
    right: -3px;
    background: none;
    border: none;
    color: black;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    padding: 0 5px;
}

.clear-filter-btn:hover {
    color: darkred;
}
</style>
