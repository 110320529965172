<template>
    <div class="sidebar-widget-list">
        <ul class="category" style="padding: 0; list-style: none; margin: 0;">
            <li v-if="filteredCategorias.length === 0">No hay resultados</li>
            <li class="cate-item" v-for="(categoria, index) in paginatedCategorias" :key="index">
                <!-- <router-link :to=" { name: 'BlogSearch', params: { tipo: 'categoria',  slug: categoria.slug } }">
                    <i class="flaticon-next"></i>
                    {{ categoria.nom_categoria }}
                </router-link> -->

                <router-link :to="generateLink(categoria)">
                    <i class="flaticon-next"></i>
                    {{ categoria.nom_categoria }}
                </router-link>
            </li>
        </ul>
        <div class="pagination-controls">
            <span @click="previousPage" :class="{ disabled: currentPage === 1 }">
                <i class="fas fa-chevron-left"></i>
            </span>
            <span @click="nextPage" :class="{ disabled: currentPage === totalPages }">
                <i class="fas fa-chevron-right"></i>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CategorieNavigation',
    //props: ['seachQuery'],
    props: {
        searchQuery: {
            type: String
        },
        routeName: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            categorias: [],
            allCategorias: [],
            currentPage: 1,
            categoriaPage: 5,
        };
    },
    computed: {
        filteredCategorias() {
            const query = this.normalizeText(this.searchQuery.toLowerCase());
            return this.allCategorias.filter(categoria => this.normalizeText(categoria.nom_categoria.toLowerCase()).includes(query));
        },
        paginatedCategorias() {
            const start = (this.currentPage - 1) * this.categoriaPage;
            const end = start + this.categoriaPage;
            return this.filteredCategorias.slice(start, end);
        },
        totalPages() {
            return Math.ceil(this.filteredCategorias.length / this.categoriaPage);
        },
    },
    created() {
        this.cargarTodasCategorias();
    },
    methods: {
        clearBlogSearch(event) {
            event.preventDefault();
            const targetUrl = event.currentTarget.getAttribute('href');
            window.location.href = targetUrl;
        },
        cargarTodasCategorias() {
            let page = 1;
            const fetchPage = (page) => {
                fetch(`${this.$apiUrl}/listar-categorias-post-web?page=${page}&categoria_page=${this.categoriaPage}`)
                    .then((response) => response.json())
                    .then((data) => {
                        this.allCategorias = [...this.allCategorias, ...data.data];
                        if (page < data.last_page) {
                            fetchPage(page + 1);
                        }
                    })
                    .catch((error) => {
                        console.error('Error al cargar datos:', error);
                    });
            };
            fetchPage(page);
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        normalizeText(text) {
            return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        },
        selectCategoria(categoriaId) {
            this.$emit('select-categoria-tema', categoriaId);
        },
        generateLink(categoria) {
            let route = { name: this.routeName };
            route.params = { tipo: 'categoria', slug: categoria.slug };
            if (this.routeName === 'BlogSearch') {
                route.query = { ...this.$route.query};
            } else if (this.routeName === 'StorePage') {
                route.query = { ...this.$route.query, categoria: categoria.slug };
            }
            return route;
        },
    },
};
</script>

<style scoped>
.pagination-controls {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.pagination-controls span {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.pagination-controls span i {
    font-size: 1em;
}

.pagination-controls span.disabled {
    cursor: not-allowed;
    color: gray;
}
</style>
