<template>
    <Preloader v-if="loading" />
    <div v-else>
        <div class="sub-banner">
            <NavbarMenu></NavbarMenu>
            <figure class="sub-bannersideshape mb-0">
                <Image src="/banner/sub-bannersideshape.png" alt="" class="img-fluid" />
            </figure>
            <section class="banner-section banner-section-about">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 col-md-6 col-sm-12 col-12">
                            <div class="banner_content" data-aos="fade-right">
                                <h1 class="text-white custom-standard-title">{{ headproducto.titulo }}</h1>
                                <p class="text-white custom-standard-subtitle">{{ headproducto.subtitulo }}</p>
                                <div class="box custom-standard-box">
                                    <span class="mb-0">Inicio</span><i class="first fa-regular fa-angle-right"></i><i
                                        class="second fa-regular fa-angle-right"></i><span
                                        class="mb-0 box_span">{{ headproducto.titulo }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-sm-12 col-12">
                            <div class="banner_wrapper">
                                <figure
                                    class="mb-0 d-flex justify-content-center align-items-center sub-bannerimage-about">
                                    <ImageWeb :src="'/' + headproducto.imagen" :alt="headproducto.imagen"
                                        class="img-fluid fixed-size-blog" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <figure class="sub-bannersideshape2 mb-0">
                    <Image src="/banner/sub-bannersideshape2.png" alt="" class="img-fluid" />
                </figure>
            </section>
        </div>
        <div class="section blog-details-section section-padding">
            <div class="container">
                <div class="blog-details-wrap">
                    <div class="row">
                        <div class="col-lg-12 position-relative">
                            <div class="blog-sidebar">
                                <SidebarStore :onCategorySelect="handleCategorySelect"
                                    :onFrameTypeSelect="handleFrameTypeSelect" :onSearch="handleSearch"
                                    :selectedCategory="filteredCategoria ? filteredCategoria.slug : null"
                                    :selectedFrameType="filteredMarca ? filteredMarca.slug : null"
                                    :selectedBrand="marca">
                                </SidebarStore>
                                <div class="filters" v-if="filteredCategoria || filteredMarca">
                                    <div class="filter-labels">
                                        <span>Filtrado por:</span>
                                        <span v-if="filteredCategoria" class="filter-item">
                                            Categoría: {{ filteredCategoria.nombre }}
                                            <button @click="clearFilter('categoria')"
                                                class="clear-filter-btn">x</button>
                                        </span>
                                        <span v-if="filteredMarca" class="filter-item">
                                            Marca: {{ filteredMarca.nombre }}
                                            <button @click="clearFilter('marca')" class="clear-filter-btn">x</button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="blog-post-wrap">
                                <div class="products-container">
                                    <div v-if="paginatedProducts.length > 0" class="products-grid">
                                        <ProductItem v-for="product in paginatedProducts" :key="product.slug"
                                            :image-src="product.pathUrl" :product-name="product.nombre"
                                            :product-slug="product.slug"></ProductItem>
                                    </div>
                                    <div v-else class="no-products">
                                        No hay productos disponibles.
                                    </div>
                                </div>
                            </div>
                            <div class="techmax-pagination">
                                <ul class="pagination justify-content-center">
                                    <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                        <a class="page-link" href="#" @click.prevent="prevPage"
                                            aria-label="Página anterior">
                                            <i class="flaticon-back"></i>
                                        </a>
                                    </li>
                                    <li class="page-item" :class="{ active: page === currentPage }"
                                        v-for="page in visiblePages" :key="page">
                                        <a class="page-link" href="#" @click.prevent="goToPage(page)"
                                            :aria-label="'Ir a la página ' + page">
                                            {{ page }}
                                        </a>
                                    </li>
                                    <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                        <a class="page-link" href="#" @click.prevent="nextPage"
                                            aria-label="Página siguiente">
                                            <i class="flaticon-next"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterMenu></FooterMenu>
    </div>
</template>

<script>
import NavbarMenu from '../components/NavbarMenu.vue';
import FooterMenu from '../components/FooterMenu.vue';
import SidebarStore from '../components/SidebarStore.vue';
import ProductItem from "./components/widgets/ProductItem.vue";
import Image from '@/components/ImagePublic.vue'
import ImageWeb from '@/components/ImagePublicWeb.vue';
import Preloader from '@/components/Preloader.vue';
import { updateMetaTags } from '../metaUtils';

export default {
    props: ['slug', 'tipo'],
    name: 'StorePage',
    components: {
        NavbarMenu,
        FooterMenu,
        SidebarStore,
        ProductItem,
        ImageWeb,
        Preloader,
        Image
    },
    data() {
        return {
            headproducto: {
                titulo: '',
                subtitulo: '',
                imagen: ''
            },
            products: [],
            filteredProducts: [],
            currentPage: 1,
            commentsPerPage: 12,
            pageRange: 5,
            pageStart: 1,
            loading: true,
            loadingFinished1: false,
            loadingFinished2: false,
            minLoadingTime: new Promise(resolve => setTimeout(resolve, 2000)),
            filteredCategoria: null,
            filteredMarca: null,
            searchQuery: '',
            marca: null // Asegúrate de definir marca

        }
    },
    async created() {
        this.minLoadingTime = new Promise((resolve) => {
            setTimeout(resolve, 750);
        });
        this.cargarHeader();
        this.fetchProducts();


        // *** Inicializar filteredMarca y filteredCategoria con los nombres en lugar de los slugs ***
        const categoriaSlug = this.$route.query.categoria || null;
        const marcaSlug = this.$route.query.marca || null;

        if (categoriaSlug) {
            this.filteredCategoria = await this.searchCategory(categoriaSlug);
        }
        if (marcaSlug) {
            this.filteredMarca = await this.searchBrand(marcaSlug);
        }
        if (categoriaSlug || marcaSlug) {
            this.filterProducts();
        }
    },
    methods: {
        updateMeta(headproducto) {
            const routeMeta = {
                title: 'Mylcom - Solutions',
                description: headproducto.subtitulo,
                image: `${this.$apiImgWeb}/images/web/${headproducto.imagen}`, // Construye la URL completa de la imagen
                canonical: `${window.location.origin}${this.$route.fullPath}`,

            };
            updateMetaTags({ meta: routeMeta });
        },
        cargarHeader() {
            fetch(`${this.$apiUrl}/mostrar-producto/1`)
                .then(response => response.json())
                .then(data => {
                    this.headproducto.titulo = data.titulo;
                    this.headproducto.subtitulo = data.subtitulo;
                    this.headproducto.imagen = data.imagen;
                    this.updateMeta(data); // Actualiza las etiquetas meta dinámicamente

                })
                .catch(error => {
                    console.error('Error al cargar datos:', error);
                })
                .finally(() => {
                    this.loadingFinished1 = true;
                    this.checkLoading();
                });
        },
        fetchProducts() {
            fetch(`${this.$apiUrl}/listar-productos-web`)
                .then(response => response.json())
                .then(data => {
                    this.products = data;
                    this.filteredProducts = data;
                })
                .catch(error => {
                    console.error('Error al cargar datos', error);
                })
                .finally(() => {
                    this.loadingFinished2 = true;
                    this.checkLoading();
                });
        },
        filterProducts() {
            let url = `${this.$apiUrl}/listar-productos`;

            if (this.filteredCategoria && this.filteredMarca) {
                url += `/categoria/marca/${encodeURIComponent(this.filteredCategoria.slug)}/${encodeURIComponent(this.filteredMarca.slug)}`;
            } else if (this.filteredCategoria) {
                url += `/categoria/${encodeURIComponent(this.filteredCategoria.slug)}`;
            } else if (this.filteredMarca) {
                url += `/marca/${encodeURIComponent(this.filteredMarca.slug)}`;
            } else {
                // Sin filtros, restablecer la lista completa de productos
                this.filteredProducts = this.products;
                this.loading = false;
                return;
            }

            fetch(url)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    this.filteredProducts = data;
                    this.currentPage = 1; // Reset page to 1 after filtering
                })
                .catch(error => {
                    console.error("Error al cargar datos filtrados", error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        handleCategorySelect(category) {
            this.filteredCategoria = category;
            this.$router.push({ name: 'StorePage', query: { ...this.$route.query, categoria: this.filteredCategoria.slug } });
            this.filterProducts();
        },
        handleFrameTypeSelect(frameType) {
            this.filteredMarca = frameType;
            this.$router.push({ name: 'StorePage', query: { ...this.$route.query, marca: this.filteredMarca.slug } });
            this.filterProducts();
        },
        handleSearch(query) {
            this.searchQuery = query;
            this.filteredProducts = this.products.filter(product => {
                const nombre = product.nombre ? product.nombre.toLowerCase() : '';
                const descripcion = product.descripcion ? product.descripcion.toLowerCase() : '';
                return nombre.includes(query.toLowerCase()) || descripcion.includes(query.toLowerCase());
            });
        },
        clearFilter(filterType) {
            const query = { ...this.$route.query };
            if (filterType === 'categoria') {
                this.filteredCategoria = null;
                delete query.categoria;

            } else if (filterType === 'marca') {
                this.filteredMarca = null;
                delete query.marca;
            }
            this.$router.push({ name: 'StorePage', query });
            this.filterProducts();
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                if (this.currentPage < this.pageStart) {
                    this.pageStart = Math.max(this.currentPage - this.pageRange + 1, 1);
                }
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                if (this.currentPage >= this.pageStart + this.pageRange) {
                    this.pageStart++;
                }
            }
        },
        goToPage(page) {
            this.currentPage = page;
            if (page >= this.pageStart + this.pageRange) {
                this.pageStart = page - this.pageRange + 1;
            } else if (page < this.pageStart) {
                this.pageStart = page;
            }
        },
        checkLoading() {
            if (this.loadingFinished1 && this.loadingFinished2) {
                this.minLoadingTime.then(() => {
                    this.loading = false;
                });
            }
        },
        async searchCategory(slugCategory) {
            const response = await fetch(`${this.$apiUrl}/buscar-categoria/${slugCategory}`);
            if (!response.ok) {
                throw new Error(`Network response was not ok`);
            }
            const data = await response.json();
            return data;
        },

        async searchBrand(slugBrand) {
            const response = await fetch(`${this.$apiUrl}/buscar-marca/${slugBrand}`);
            if (!response.ok) {
                throw new Error(`Network response was not ok`);
            }
            const data = await response.json();
            return data;
        },

    },
    computed: {
        totalPages() {
            return Math.ceil(this.filteredProducts.length / this.commentsPerPage);
        },
        paginatedProducts() {
            const start = (this.currentPage - 1) * this.commentsPerPage;
            const end = start + this.commentsPerPage;
            return this.filteredProducts.slice(start, end);
        },
        visiblePages() {
            const end = Math.min(this.pageStart + this.pageRange - 1, this.totalPages);
            const pages = [];
            for (let i = this.pageStart; i <= end; i++) {
                pages.push(i);
            }
            return pages;
        },
    },
}
</script>


<style scoped>
.products-container {
    margin-top: 30px;
}

.products-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

@media (max-width: 768px) {
    .products-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

.blog-sidebar {
    position: relative;
    margin-left: 0;
}

.btn-default-product {
    height: 38px;
    line-height: 36px;
    font-size: 16px;
    padding: 0 40px;
    transition: all 0.3s ease-in-out;
    background: linear-gradient(to right, #193C88, #4D9EC1);
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.button-container {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
}

.filter-labels {
    font-size: 16px;
    /* background-color: #f8f9fa; */
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
}

.filter-labels span {
    display: inline-block;
    margin-right: 10px;
    padding: 5px 10px;
    border-radius: 4px;
    background-color: #e9ecef;
    color: #333;
}

.filter-labels span:first-child {
    font-weight: bold;
    background-color: #4D9EC1;
    color: #ffffff;
    padding: 5px 10px;
}

.clear-filter-btn {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    margin-left: 5px;
}

.no-products {
    text-align: center;
    font-size: 18px;
    color: #333;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 5px;
}
</style>
