<template>
    <Preloader v-if="loading" />
    <div v-else>
        <div class="sub-banner">
            <NavbarMenu></NavbarMenu>
            <figure class="sub-bannersideshape mb-0">
                <Image src="/banner/sub-bannersideshape.png" alt="" class="img-fluid" />
            </figure>
            <section class="banner-section banner-section-about">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 col-md-6 col-sm-12 col-12">
                            <div class="banner_content" data-aos="fade-right">
                                <h1 class="text-white custom-standard-title">{{ headproducto.titulo }}</h1>
                                <p class="text-white custom-standard-subtitle">{{ headproducto.subtitulo }}</p>
                                <div class="box custom-standard-box">
                                    <span class="mb-0">Inicio</span><i class="first fa-regular fa-angle-right"></i><i
                                        class="second fa-regular fa-angle-right"></i><span class="mb-0 box_span">{{
                                            headproducto.titulo }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-sm-12 col-12">
                            <div class="banner_wrapper">
                                <figure
                                    class="mb-0 d-flex justify-content-center align-items-center sub-bannerimage-about">
                                    <ImageWeb :src="'/' + headproducto.imagen" :alt="headproducto.imagen"
                                        class="img-fluid fixed-size-blog" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <figure class="sub-bannersideshape2 mb-0">
                    <Image src="/banner/sub-bannersideshape2.png" alt="" class="img-fluid" />
                </figure>
            </section>
        </div>
        <div class="section blog-details-section section-padding">
            <div class="container">
                <div class="blog-details-wrap">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="blog-sidebar">
                                <SidebarStoreLarge></SidebarStoreLarge>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="product-single-item">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="product-single-slider">
                                            <div class="product-thumb">
                                                <div class="zoom zoom-hover" @mouseenter="activateZoom"
                                                    @mouseleave="deactivateZoom" :class="{ 'zoomed': isZoomed }"
                                                    ref="zoomContainer" @mousemove="moveImage">
                                                    <div class="image-container">
                                                        <a class="lightbox-image" :href="largeImageSrc">
                                                            <ImageWeb :src="smallImageSrc" alt="Image-HasTech"
                                                                ref="image" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="product-nav-grid">
                                                <div class="grid-item" v-for="(image, index) in productImages"
                                                    :key="index" @click="changeMainImage('/' + image.pathUrl)">
                                                    <ImageWeb :src="'/' + image.pathUrl" :alt="image.nombre" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="product-single-info">
                                            <h4 class="title">{{ productName }}</h4>
                                            <p class="product-info">{{ productSummary }}</p>
                                            <div class="product-action-simple">
                                                <div class="buy-now-btn">
                                                    <a :href="`https://api.whatsapp.com/send?phone=${socialNetworks.whatsapp}&text=¡Hola! Estoy interesado en el producto *${productName}*. ¿Podrías proporcionarme más detalles? ¡Gracias!`"
                                                        target="_blank" class="btn btn-Buy">Cotizar ahora</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="product-review-tabs-content">
                                            <div class="nav nav-tabs product-tab-nav" role="tablist">
                                                <button class="nav-link" @click="showDescription"
                                                    :class="{ active: activeTab === 'description' }" role="tab"
                                                    :aria-selected="activeTab === 'description'"
                                                    aria-controls="description-tab">Descripción</button>
                                                <button class="nav-link" @click="showSpecifications"
                                                    :class="{ active: activeTab === 'especificaciones' }" role="tab"
                                                    :aria-selected="activeTab === 'especificaciones'"
                                                    aria-controls="especificaciones-tab">Especificaciones</button>
                                                <button class="nav-link" @click="showDatasheet"
                                                    :class="{ active: activeTab === 'ficha' }" role="tab"
                                                    :aria-selected="activeTab === 'ficha'"
                                                    aria-controls="ficha-tab">Ficha Técnica</button>
                                            </div>
                                            <div class="tab-content product-tab-content" id="ReviewTabContent">
                                                <div v-if="activeTab === 'description'"
                                                    class="tab-pane fade show active" id="description-tab"
                                                    role="tabpanel">
                                                    <div class="product-description ql-editor"
                                                        v-html="productDescription"></div>
                                                </div>
                                                <div v-if="activeTab === 'especificaciones'"
                                                    class="tab-pane fade show active" id="especificaciones-tab"
                                                    role="tabpanel">
                                                    <div class="product-review-content">
                                                        <table class="table table-striped fixed-table">
                                                            <tbody>
                                                                <tr v-for="(spec, index) in productSpecifications"
                                                                    :key="index">
                                                                    <td class="spec-key"><strong>{{ spec.key
                                                                            }}:</strong></td>
                                                                    <td class="spec-value">{{ spec.value }}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div v-if="activeTab === 'ficha'" class="tab-pane fade show active"
                                                    id="ficha-tab" role="tabpanel">
                                                    <div class="product-comment-content">
                                                        <table class="table table-striped">
                                                            <tbody>
                                                                <tr v-for="(pdf, index) in productPdfs" :key="index">
                                                                    <td>
                                                                        <a :href="pdf.pathUrl" target="_blank"
                                                                            class="pdf-link d-flex align-items-center">
                                                                            <i class="fa fa-file-pdf mr-2"></i> {{
                                                                                pdf.nombre }}
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterMenu></FooterMenu>
    </div>
</template>

<script>
import NavbarMenu from '@/components/NavbarMenu.vue';
import FooterMenu from '@/components/FooterMenu.vue';
import SidebarStoreLarge from '@/components/SidebarStoreLarge.vue';
import ImageWeb from '@/components/ImagePublicWeb.vue';
import Preloader from '@/components/Preloader.vue';
import Image from '@/components/ImagePublic.vue';
import { updateMetaTags } from '../../metaUtils';




export default {
    props: ['slug', 'tipo'],
    name: 'ProductInfo',
    components: {
        NavbarMenu,
        FooterMenu,
        SidebarStoreLarge,
        ImageWeb,
        Preloader,
        Image
    },
    data() {
        return {
            activeTab: 'description', // Inicialmente mostrando la descripción
            quantity: 1,
            isZoomed: false,
            smallImageSrc: '', // Ruta de la imagen pequeña
            socialNetworks: {
                whatsapp: ''
            },
            headproducto: {
                titulo: '',
                subtitulo: '',
                imagen: ''
            },
            productName: '',
            productSummary: '',
            productDescription: '',
            productSpecifications: [],
            productImages: [],
            productPdfs: [],
            loading: true,
            loadingFinished1: false,
            loadingFinished2: false,
        };
    },
    created() {
        this.minLoadingTime = new Promise((resolve) => {
            setTimeout(resolve, 750);
        });
        this.cargarHeader();
        this.cargarProducto();
    },
    watch: {
        slug: 'cargarProducto' // Observa los cambios en el slug
    },
    methods: {
        updateMeta(product) {
            const routeMeta = {
                title: 'Mylcom - Products',
                description: product.resumen,
                image: product.imagenes && product.imagenes[0] ? `${this.$apiImgWeb}/images/web/${product.imagenes[0].pathUrl}` : `${this.$apiImgWeb}/default-product.png`, // Imagen dinámica o fallback
                canonical: `${window.location.origin}${this.$route.fullPath}`,

            };
            updateMetaTags({ meta: routeMeta });
        },
        fetchProductsBy() {
            this.loading = true;
            let url = `${this.$apiUrl}/listar-productos/${this.tipo}/${this.slug}`;
            // let url = `${this.$apiUrl}/listar-posts`;
            let params = [];
            if (this.filteredMarca) {
                params.push(`marca=${this.filteredMarca}`);
            }
            if (this.filteredCategoria) {
                params.push(`categoria=${this.filteredCategoria}`);
            }
            if (params.length) {
                url += `?${params.join('&')}`;
            }
            fetch(url)
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        this.posts = data.data;
                        this.currentPage = 1; // Reset page to 1 after filtering
                    } else {
                        console.error('Error en la respuesta del servidor:', data.message);
                    }
                })
                .catch((error) => {
                    console.error("Error al cargar datos filtrados", error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        cargarHeader() {
            fetch(`${this.$apiUrl}/mostrar-producto/1`)
                .then((response) => response.json())
                .then((data) => {
                    this.headproducto.titulo = data.titulo;
                    this.headproducto.subtitulo = data.subtitulo;
                    this.headproducto.imagen = data.imagen;
                })
                .catch((error) => {
                    console.error('Error al cargar datos:', error);
                })
                .finally(() => {
                    this.loadingFinished1 = true;
                    this.checkLoading();
                });
        },
        cargarProducto() {
            fetch(`${this.$apiUrl}/buscar-producto/${this.slug}`)
                .then((response) => response.json())
                .then((data) => {
                    this.productName = data.nombre;
                    this.productSummary = data.resumen;
                    this.productDescription = data.descripcion;
                    this.productSpecifications = data.especificaciones;
                    this.productImages = data.imagenes;
                    this.productPdfs = data.pdfs;
                    this.smallImageSrc = '/' + data.imagenes[0].pathUrl;
                    this.updateMeta(data); // Actualiza las etiquetas meta dinámicamente

                })
                .catch((error) => {
                    console.error('Error al cargar datos:', error);
                })
                .finally(() => {
                    this.loadingFinished2 = true;
                    this.checkLoading();
                });
        },
        fetchSocialNetworks(id) {
            fetch(`${this.$apiUrl}/mostrar-redes/${id}`)
                .then(response => response.json())
                .then(data => {
                    this.socialNetworks = data;
                })
                .catch(error => {
                    console.error('Error al cargar los datos:', error);
                });
        },
        checkLoading() {
            if (this.loadingFinished1 && this.loadingFinished2) {
                this.minLoadingTime.then(() => {
                    this.loading = false;
                });
            }
        },
        showDescription() {
            this.activeTab = 'description';
        },
        showSpecifications() {
            this.activeTab = 'especificaciones';
        },
        showDatasheet() {
            this.activeTab = 'ficha';
        },
        changeMainImage(imageSrc) {
            this.smallImageSrc = imageSrc; // Actualiza la ruta de la imagen principal
        },
        activateZoom() {
            this.isZoomed = true;
        },
        deactivateZoom() {
            this.isZoomed = false;
        },
        moveImage(event) {
            if (this.isZoomed) {
                const containerRect = this.$refs.zoomContainer.getBoundingClientRect();
                const offsetX = event.clientX - containerRect.left;
                const offsetY = event.clientY - containerRect.top;
                const ratioX = offsetX / containerRect.width;
                const ratioY = offsetY / containerRect.height;
                const image = this.$refs.image;

                if (image && image.$el && image.$el.style) {
                    image.$el.style.transformOrigin = `${ratioX * 100}% ${ratioY * 100}%`;
                }
            }
        }
    },
    mounted() {
        this.fetchSocialNetworks(1);
    },
}
</script>

<style scoped>
.fixed-table {
    table-layout: fixed;
    width: 100%;
    font-size: 15px
}

.fixed-table .spec-key {
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
}

.fixed-table .spec-value {
    width: 70%;
    word-wrap: break-word;
}

.fixed-table td {
    padding: 1px;
    vertical-align: middle;
}

/* Otros estilos existentes */
.product-nav-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 20px;
}

.grid-item {
    cursor: pointer;
}

.grid-item img {
    width: 100%;
    height: auto;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: transform 0.3s ease;
}

.grid-item img:hover {
    transform: scale(1.05);
}

.product-action .addto-wrap a {
    text-decoration: none;
}

.product-info {
    min-height: 200px;
}

.zoomed img {
    transform: scale(2.5);
    transition: transform 0.3s ease;
}

.image-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.zoom {
    width: 100%;
    height: 100%;
}

.btn-default-product-details {
    height: 38px;
    line-height: 36px;
    font-size: 16px;
    padding: 0 40px;
    transition: all 0.3s ease-in-out;
    background: linear-gradient(to right, #193C88, #4D9EC1);
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .product-single-item .product-single-info .title {
        text-align: center;
    }

    .product-single-item .product-single-info .product-action-simple .buy-now-btn {
        display: flex;
        justify-content: center;
    }

    .product-single-item .product-single-info .product-action-simple .buy-now-btn .btn-Buy {
        margin: auto;
        margin-bottom: 30px;
    }

    .product-single-slider {
        margin-top: 10px;
        width: auto;
    }
}
</style>
