<template>
    <div class="sidebar-widget-list">
        <div class="recent-posts">
            <ul style="padding: 0; list-style: none; margin: 0;">
                <li v-for="(post, index) in recentPosts" :key="index">
                    <router-link :to="{ name: 'BlogPost', params: { slug: post.slug } }" class="post-link">
                        <div class="post-thumb">
                            <Image :src="'/' + post.image" :alt="post.titulo" width="70px" />
                        </div>
                        <div class="post-text">
                            <h4 class="title">{{ post.titulo }}</h4>
                            <span class="post-meta"><i class="far fa-calendar-alt"></i> {{ post.date }}</span>
                        </div>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Image from '@/components/ImagePublicWeb.vue';

export default {
    components: {
        Image
    },
    data() {
        return {
            recentPosts: []
        };
    },
    created() {
        this.fetchRecentPosts();
    },
    methods: {
        fetchRecentPosts() {
            fetch(`${this.$apiUrl}/listar-posts-ultimos`)
                .then(response => response.json())
                .then(data => {
                    this.recentPosts = data.map(post => ({
                        titulo: post.titulo,
                        date: new Date(post.created_at).toLocaleDateString('es-ES', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric'
                        }),
                        image: post.imagen,
                        slug: post.slug
                    }));
                })
                .catch(error => {
                    console.error('Error fetching recent posts:', error);
                });
        }
    }
};
</script>
