<template>
    <div class="col-lg-4 col-md-4 col-sm-6 col-12">
        <a :href="linkUrl">
            <div class="case-box overlay" @mouseover="showHoverContent = true" @mouseleave="showHoverContent = false">
                <figure class="image mb-0">
                    <ImageWeb :src="imageUrl" alt="" class="img-fluid imagen-card" />
                </figure>
                <div class="content">
                    <h4 role="heading" aria-level="3" class="text-white">{{ subtitle }}</h4>
                    <i class="circle fa-regular fa-angle-right"></i>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
//import Image from '@/components/ImagePublic.vue';
import ImageWeb from '@/components/ImagePublicWeb.vue';


export default {
    components: {
        ImageWeb,
    },
    props: {
        imageUrl: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            required: true
        },
        additionalInfo: {
            type: String,
            required: true
        },
        linkUrl: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            showHoverContent: false
        };
    }
};
</script>

<style scoped>
.study-section .overlay:hover img {
    filter: blur(3px);
}

.img-fluid{
    border-radius: 1.85rem;
}

.imagen-card{
    width: 100%;
}
</style>
