<template>
    <a :class="[buttonClass, { 'button1': button1 }, { 'text-decoration-none': removeTextDecoration }]" :href="href">
        {{ buttonText }}
        <i class="circle fa-regular fa-angle-right"></i>
    </a>
</template>

<script>
export default {
    props: {
        buttonText: String,
        button1: {
            type: Boolean,
            default: false // Por defecto, button1 está desactivado
        },
        buttonClass: {
            type: String,
            default: 'lets_talk' // Clase de estilo por defecto
        },
        removeTextDecoration: {
            type: Boolean,
            default: false // Por defecto, no se elimina la decoración de texto
        },
        href: String
    }
};
</script>

<style scoped>
a {
    text-decoration: none;
}
</style>
