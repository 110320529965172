import router from "./router";
import Swal from "sweetalert2";

const originalFetch = fetch;

window.fetch = async (url, options = {}) => {
  try {
    const token = localStorage.getItem("authToken");

    if (!options.headers) {
        options.headers = {};
    }
   
    if (options.method && options.method.toUpperCase() === "POST" && token) {
        options.headers["Authorization"] = `Bearer ${token}` ;
    }

    const response = await originalFetch(url, options);

    if (response.status === 401) {
      await Swal.fire({
        icon: "warning",
        title: "Sesión Expirada",
        text: "Tu sesión ha expirado. Por favor, inicia sesión nuevamente.",
        confirmButtonText: "Aceptar",
      });

      localStorage.removeItem("user");
      localStorage.removeItem("authToken");

     
      if (router.currentRoute.value.path === "/") {
        window.location.reload();
      } else {
        router.push("/");
      }

      throw new Error("Token inválido");
    }

    return response;
  } catch (error) {
    console.error("Error en la solicitud fetch:", error);
    return Promise.reject(error);
  }
};