<template>
    <img :src="`${$apiImg}${src}`" :alt="alt" :class="className" loading="lazy"/>
  </template>
  
  <script>
  export default {
    props: {
      src: String,
      alt: String,
      className: String
    }
  };
  </script>