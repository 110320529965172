<template>
    <Preloader v-if="loading" />
    <div v-else>
        <div class="sub-banner">
            <NavbarMenu @logout="handleLogout" @loginSuccess="checkVerification" />
            <figure class="sub-bannersideshape mb-0">
                <Image src="/banner/sub-bannersideshape.png" alt="image" />
            </figure>
            <section class="banner-section banner-section-about">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 col-md-6 col-sm-12 col-12">
                            <div class="banner_content" data-aos="fade-right">
                                <h1 class="text-white custom-standard-title">{{ noticeHead.titulo }}</h1>
                                <p class="text-white custom-standard-subtitle">
                                    {{ noticeHead.subtitulo }}
                                </p>
                                <div class="box custom-standard-box">
                                    <span class="mb-0">Inicio</span><i class="first fa-regular fa-angle-right"></i><i
                                        class="second fa-regular fa-angle-right"></i><span
                                        class="mb-0 box_span">Noticias</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-sm-12 col-12">
                            <div class="banner_wrapper">
                                <figure
                                    class="mb-0  d-flex justify-content-center align-items-center sub-bannerimage-about">
                                    <ImageWeb :src="'/' + noticeHead.imagen" :alt="noticeHead.imagen"
                                        class="img-fluid fixed-size-blog" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <figure class="sub-bannersideshape2 mb-0">
                    <Image src="/banner/sub-bannersideshape2.png" alt="img-fluid" />
                </figure>
            </section>
        </div>
        <div class="section blog-details-section section-padding">
            <div class="container">
                <div class="blog-details-wrap">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="blog-details-post">
                                <div class="single-blog">
                                    <div class="blog-img">
                                        <a href="#">
                                            <ImageWeb :src="'/' + noticia.imagen" id="notice-image" alt="img-fluid" />
                                        </a>
                                        <div class="top-meta">
                                            <span class="date"><span id="date-day">{{ getDay(noticia.created_at)
                                                    }}</span>
                                                <div id="date-month">{{ getMonth(noticia.created_at) }}</div>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="blog-content">
                                        <div class="blog-meta">
                                            <span><i class="fas fa-user"></i> <a href="#" id="post-author"> Mylcom
                                                </a></span>
                                        </div>
                                        <h3 class="title" aria-level="2">{{ noticia.titulo }}</h3>
                                        <div class="ql-editor" v-html="noticia.contenido"></div>

                                        <div class="subscribe">
                                            <p class="title">Suscríbase a las noticias de Mylcom</p>
                                            <p class="text">Suscríbete para recibir las últimas innovaciones y
                                                actualizaciones en tecnología IoT e IA. Lleva tu empresa al siguiente
                                                nivel con nuestras soluciones tecnológicas más avanzadas.
                                            </p>
                                            <div class="form-wrapper">
                                                <form class="form form__blog" @submit.prevent="submitForm"
                                                    data-hs-cf-bound="true">
                                                    <div class="form__group">
                                                        <div class="form__input">
                                                            <i class="fas fa-envelope"></i>
                                                            <input type="email" v-model="correo"
                                                                pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                                                                required placeholder="Email*">
                                                        </div>
                                                    </div>
                                                    <div class="form__group" style="justify-content: center;">
                                                        <input type="submit" value="Enviar">
                                                    </div>
                                                    <div class="hidden" hidden="">
                                                        <input type="text" name="group" value="IoT">
                                                        <input type="text" name="gclid">
                                                        <input type="text" name="url"
                                                            value="https://www.tusitio.com/company/news/event/">
                                                        <input type="text" name="pageTitle"
                                                            value="Evento de Innovación y Soluciones IoT">
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="blog-sidebar">
                                <SidebarNotice></SidebarNotice>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterMenu></FooterMenu>
    </div>
</template>

<script>
import NavbarMenu from '@/components/NavbarMenu.vue';
import FooterMenu from '@/components/FooterMenu.vue';
import Image from '@/components/ImagePublic.vue';
import Preloader from '@/components/Preloader.vue';
import ImageWeb from '@/components/ImagePublicWeb.vue';
import Swal from 'sweetalert2';
import SidebarNotice from '@/components/SidebarNotice.vue';
import { updateMetaTags } from '../../metaUtils';


export default {
    props: {
        slug: {
            type: String,
            required: true
        }
    },
    name: 'NewsDetails',
    components: {
        NavbarMenu,
        FooterMenu,
        Image,
        Preloader,
        ImageWeb,
        SidebarNotice
    },
    data() {
        return {
            loading: true,
            loadingFinished1: false,
            loadingFinished2: false,
            minLoadingTime: null,
            noticia: {},
            latestNews: [], // Array para almacenar las últimas noticias
            correo: '', // Para almacenar el correo ingresado
            noticeHead: {
                id: '',
                titulo: '',
                subtitulo: '',
                imagen: ''
            },
        };
    },
    watch: {
        slug(newSlug) {
            this.cargarCardDetalle(newSlug);
        }
    },
    created() {
        // const id = this.$route.params.id; 
        this.minLoadingTime = new Promise((resolve) => {
            setTimeout(resolve, 750);
        });
        this.cargarNoticia();
        this.cargarUltimasNoticias();
        this.cargarHeader();
    },
    methods: {
        updateMeta(noticia) {
            const routeMeta = {
                title: 'Mylcom - Products',
                description: noticia.titulo,
                image: `${this.$apiImgWeb}/images/web/${noticia.imagen}`, // Construye la URL completa de la imagen
                canonical: `${window.location.origin}${this.$route.fullPath}`,

            };
            updateMetaTags({ meta: routeMeta });
        },
        cargarHeader() {
            fetch(`${this.$apiUrl}/mostrar-noticia/1`)
                .then((response) => response.json())
                .then((data) => {
                    this.noticeHead.titulo = data.titulo;
                    this.noticeHead.subtitulo = data.subtitulo;
                    this.noticeHead.imagen = data.imagen;
                })
                .catch((error) => {
                    console.error('Error al cargar datos:', error);
                })
                .finally(() => {
                    this.loadingFinished1 = true;
                    this.checkLoading();
                });
        },
        cargarNoticia() {
            fetch(`${this.$apiUrl}/buscar-noticia/${this.slug}`)
                .then(response => response.json())
                .then(data => {
                    this.noticia = data;
                    this.updateMeta(data); // Actualiza las etiquetas meta con los datos cargados
                })
                .catch(error => {
                    console.error('Error al cargar datos:', error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        cargarUltimasNoticias() {
            fetch(`${this.$apiUrl}/listar-noticias-ultimos`)
                .then(response => response.json())
                .then(data => {
                    this.latestNews = data.slice(0, 3); // Limitar a 3 noticias
                })
                .catch(error => {
                    console.error('Error al cargar últimas noticias:', error);
                });
        },
        submitForm() {
            fetch(`${this.$apiUrl}/subscribe`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({ correo: this.correo })
            })
                .then(async response => {
                    if (!response.ok) {
                        const errorMessage = await response.text();
                        throw new Error(errorMessage);
                    }
                    return response.json();
                })
                .then(data => {
                    if (data.success) {
                        Swal.fire({
                            icon: 'success',
                            title: '¡Éxito!',
                            text: data.message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: data.message,
                            footer: data.errors?.correo?.[0] || ''
                        });
                    }
                })
                .catch(error => {
                    let errorMessage = 'Ocurrió un error al enviar el formulario. Por favor, inténtelo de nuevo más tarde.';
                    try {
                        const errorData = JSON.parse(error.message);
                        errorMessage = errorData.message;
                    } catch (e) {
                        console.error('Error al analizar el mensaje de error:', e);
                    }
                    Swal.fire({
                        icon: 'warning',
                        // title: 'Advertencia',
                        text: errorMessage
                    });
                });
        },
        checkLoading() {
            if (this.loadingFinished1 && this.loadingFinished2) {
                this.minLoadingTime.then(() => {
                    this.loading = false;
                });
            }
        },
        getDay(dateString) {
            const date = new Date(dateString);
            return date.getUTCDate();
        },
        getMonth(dateString) {
            const date = new Date(dateString);
            const months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
            return months[date.getUTCMonth()];
        },
        handleLogout() {
            // Manejo de logout
        },
        checkVerification() {
            // Manejo de verificación
        },
        formatDate(dateString) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return new Date(dateString).toLocaleDateString('es-ES', options);
        }
    },
    computed: {
        limitedLatestNews() {
            return this.latestNews.slice(0, 3);
        }
    }
}
</script>

<style scoped>
.subscribe {
    margin-top: 3.75rem;
}

.subscribe .title {
    color: #333;
    font-weight: 600;
    font-size: 1.5rem;
}

/* .subscribe .text {
    color: #666;
    font-weight: 300;
    font-size: max(1.125rem, 16px);
} */

.form-wrapper {
    width: 100%;
    margin-top: 1.25rem;
    padding: 3rem 1.75rem 0;
    border-radius: .5rem;
}

.form__group {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1.5rem;
    justify-content: center;
}

.form__input {
    flex: 1;
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    border-radius: .5rem;
    padding: 0 .75rem;
}

.form__input i {
    color: #333;
    margin-right: .75rem;
}

.form input[type=email],
.form input[type=text] {
    flex-grow: 1;
    padding: .75rem;
    border: none;
    border-radius: .5rem;
    outline: none;
    font-size: 1rem;
    color: #333;
}

.form input[type=submit] {
    background: linear-gradient(to right, #193C88, #4D9EC1);
    color: #fff;
    border: none;
    border-radius: .5rem;
    padding: .75rem 40px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-align: center;
    margin-top: 1.5rem;
}

.form input[type=submit]:hover {
    background: linear-gradient(to right, #052b45, #052b45);
}

.recommendation {
    width: 100%;
    background-color: #f7f7f7;
    border-radius: .5rem;
    padding: 1.875rem 1.875rem 2.5rem;
    position: -webkit-sticky;
    /* For Safari */
    position: sticky;
    margin-top: 1.5rem;
    top: 0;
}

.recommendation .title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #333;
    text-align: center;
}

.recommendation .card:first-of-type {
    padding-top: 0;
}

.recommendation .card {
    border-bottom: 1px solid #e8e8e8;
    padding: 1.25rem 0 1rem;
    display: block;
    text-align: center;
    transition: all 0.3s ease;
}

/* 
.recommendation .card__img {
    width: 100%;
    min-height: 10.938rem;
} */

.recommendation .card__img img {
    width: 100%;
    height: auto;
    max-height: 200px;
    object-fit: contain;
    padding: 10px 15px 20px 25px;
}

.recommendation .card__title {
    font-size: 1.1rem;
    color: var(--e-global-color-secondary);
    line-height: 1.3;
    margin-top: .625rem;
    transition: color 0.3s ease;
    font-weight: bold;

}

.recommendation .card:hover .card__title {
    color: var(--e-global-color-principal);
}

.recommendation .card__time {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    margin-top: .625rem;
    line-height: 1.3;
    font-size: 14px;
    font-weight: 600;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.recommendation .card__time i {
    margin-right: 0.5rem;
    color: #193C88;
    font-size: 1.2rem;
}

svg.symbol {
    width: 1em;
    height: 1em;
    vertical-align: -.15em;
    fill: currentcolor;
    overflow: hidden;
    font-size: inherit;
}
</style>
