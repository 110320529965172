<template>
    <div class="product-item">
        <div class="inner-content">
            <div class="product-thumb">
                <ImageWeb class="w-100" :src=" '/' + imageSrc" :alt="productName" />
            </div>
            <div class="product-desc">
                <div class="product-info">
                    <h4 class="title" aria-level="3">{{ productName }}</h4>
                    <router-link :to="{ name: 'ProductInfo', params: { slug: productSlug } }">
                        <div class="button-container">
                            <button class="btn-default-product">Más info</button>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImageWeb from '@/components/ImagePublicWeb.vue';

export default {
    components: {
        ImageWeb
    },
    name: 'ProductItem',
    props: {
        imageSrc: String,
        productName: String,
        productSlug: String
    }
};
</script>

<style scoped>
.product-item {
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    height: 430px;
    width: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 1rem;
    position: relative;
}

/* .product-item .inner-content {
    padding: 15px;
} */

.product-item .product-thumb img {
    width: 100%;
    height: auto;
}

.button-container {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
}

.btn-default-product {
    height: 38px;
    line-height: 36px;
    font-size: 16px;
    padding: 0 40px;
    transition: all 0.3s ease-in-out;
    background: linear-gradient(to right, #193C88, #4D9EC1);
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
</style>
