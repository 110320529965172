<template>
    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
        <router-link :to="route">
            <div class="counter-box">
                <figure :class="'counter-' + counterClass">
                    <ImageWeb :src="imageSrc" :alt="getAltText(title)" class="img-fluid" style="width: 67%;"/>
                </figure>
                <h4 class="mb-0" role="heading" aria-level="2" :style="applyCustomStyle ? null : null">{{ title }}</h4>
            </div>
        </router-link>
    </div>
</template>

<script>
//import Image from '@/components/ImagePublic.vue';
import ImageWeb from '@/components/ImagePublicWeb.vue';

export default {
    components: {
        //Image,
        ImageWeb
    },
    props: {
        counterClass: String,
        imageSrc: String,
        title: String,
        applyCustomStyle: Boolean,
        route: String
    },
    methods: {
        getAltText(title) {
            return `Icono de ${title}`;
        }
    }
}
</script>

<style scoped>
</style>
