<template>
    <Preloader v-if="loading" />
    <div v-else>
        <div class="sub-banner">
            <NavbarMenu></NavbarMenu>
            <figure class="sub-bannersideshape mb-0">
                <Image src="/banner/sub-bannersideshape.png" alt="" class="img-fluid" />
            </figure>
            <section class="banner-section banner-section-about">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 col-md-6 col-sm-12 col-12">
                            <div class="banner_content" data-aos="fade-right">
                                <h1 class="text-white custom-standard-title">{{ partner.titulo }}</h1>
                                <p class="text-white custom-standard-subtitle">{{ partner.subtitulo }}</p>
                                <div class="box custom-standard-box">
                                    <span class="mb-0">Inicio</span><i class="first fa-regular fa-angle-right"></i><i
                                        class="second fa-regular fa-angle-right"></i><span class="mb-0 box_span">{{
                                        partner.titulo }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-sm-12 col-12">
                            <div class="banner_wrapper">
                                <figure class="mb-0  d-flex justify-content-center align-items-center sub-bannerimage-about">
                                    <ImageWeb :src="'/' + partner.imagen" alt="" class="img-fluid fixed-size-blog" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <figure class="sub-bannersideshape2 mb-0">
                    <Image src="/banner/sub-bannersideshape2.png" alt="" class="img-fluid" />
                </figure>
            </section>
        </div>
        <section class="contact-section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="contact_content" data-aos="fade-right">
                            <h2>Nuestros Colaboradores</h2>
                            <p>Nuestros partners son parte integral de nuestra misión. Juntos, trabajamos en
                                colaboración
                                para ofrecerte soluciones innovadoras y de calidad. Explora nuestra red de colaboradores
                                comprometidos con la excelencia.</p>
                            <br>
                            <div class="custom-border">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1"
                                            role="tab" aria-controls="tab1" aria-selected="true">Hardware Partners</a>
                                    </li>
                                    <!-- Añadir más elementos li si es necesario -->
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="tab1" role="tabpanel"
                                        aria-labelledby="tab1-tab">
                                        <div class="cards-partner">
                                            <ImageWeb v-for="(partner, index) in partnerData" :key="index"
                                                :src="'/' + partner.imagen" loading="lazy"
                                                class="size-full wp-image-41232" :title="partner.nombre"
                                                :alt="partner.nombre" width="264" height="136" style="padding: 1rem;" />
                                        </div>
                                    </div>
                                    <!-- Añadir más divs para el contenido de cada pestaña si es necesario -->
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <FooterMenu></FooterMenu>
    </div>
</template>

<script>
import NavbarMenu from '../components/NavbarMenu.vue'
import FooterMenu from '../components/FooterMenu.vue'
import Image from '@/components/ImagePublic.vue';
import ImageWeb from '@/components/ImagePublicWeb.vue';
import Preloader from '@/components/Preloader.vue';
import { updateMetaTags } from '../metaUtils';


export default {
    name: 'PartnerPage',
    components: {
        NavbarMenu,
        FooterMenu,
        Image,
        ImageWeb,
        Preloader
    },
    data() {
        return {
            partner: {
                titulo: '',
                subtitulo: '',
                imagen: ''
            },
            partnerData: [],
            loading: true,
            loadingFinished1: false,
            loadingFinished2: false,
            minLoadingTime: null,
        }
    },
    created() {
        this.minLoadingTime = new Promise((resolve) => {
            setTimeout(resolve, 750);
        });
        this.cargarDatosPartner();
        this.cargarDatosPartnersInfo();
    },
    methods: {
        updateMeta(partner) {
            const routeMeta = {
                title: 'Mylcom - Partners',
                description: partner.subtitulo,
                image: `${this.$apiImgWeb}/images/web/${partner.imagen}`, // Construye la URL completa de la imagen
                canonical: `${window.location.origin}${this.$route.fullPath}`,

            };
            updateMetaTags({ meta: routeMeta });
        },
        cargarDatosPartner() {
            fetch(`${this.$apiUrl}/partners/1`)
                .then((response) => { return response.json(); })
                .then((data) => {
                    this.partner.titulo = data.titulo;
                    this.partner.subtitulo = data.subtitulo;
                    this.partner.imagen = data.imagen;
                    this.updateMeta(data); // Actualiza las etiquetas meta dinámicamente

                })
                .catch((error) => {
                    console.error('Error al cargar datos:', error);
                })
                .finally(() => {
                    this.loadingFinished1 = true;
                    this.checkLoading();
                });

        },
        cargarDatosPartnersInfo() {
            fetch(`${this.$apiUrl}/lista-partners`)
                .then((response) => { return response.json(); })
                .then((data) => {
                    this.partnerData = data;
                })
                .catch((error) => {
                    console.error('Error al cargar datos:', error);
                })
                .finally(() => {
                    this.loadingFinished2 = true;
                    this.checkLoading();
                });

        },
        checkLoading() {
            if (this.loadingFinished1 && this.loadingFinished2) {
                this.minLoadingTime.then(() => {
                    this.loading = false;
                });
            }
        },
    }
}
</script>

<style scoped>
.custom-border {
    border: 1px solid #ced4da;
}

.nav-tabs .nav-link {
    border: none;
    border-bottom: 2px solid transparent;
    color: var(--e-global-color-text);
    padding: 1em 1.5em;
}

.nav-tabs .nav-link.active {
    color: #193c88;
    border-style: solid;
    border-width: 0px 0px 3px 0px;
    border-color: #193c88;
}

.tab-pane {
    background-color: #F4F5F8;
}

.cards-partner {
    color: #333;
    padding: 1em 0.75em 1em 0.75em;
    border-style: solid;
    border-width: 1px 0px 0px 0px;
    border-color: #E0E5F9;
}

.cards-partner>img {
    display: inline-block !important;
    max-width: 264px;
    margin: 0 3px 6px;
    transition: all 0.3s ease;
    background-color: white;
}
</style>
