<template>
    <div class="sidebar-widget-list">
        <div class="recent-posts">
            <ul style="padding: 0; list-style: none; margin: 0;">
                <li v-for="(product, index) in recentProducts" :key="index">
                    <router-link :to="{ name: 'ProductInfo', params: { slug: product.slug } }" class="post-link">
                        <div class="post-thumb">
                            <Image :src="'/' + product.pathUrl" :alt="product.nombre" width="70px" />
                        </div>
                        <div class="post-text">
                            <h4 class="title">{{ product.nombre }}</h4>
                            <span class="post-meta"><i class="far fa-calendar-alt"></i> {{ product.fecha }}</span>
                        </div>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Image from '@/components/ImagePublicWeb.vue';

export default {
    components: {
        Image
    },
    data() {
        return {
            recentProducts: []
        };
    },
    created() {
        this.fetchRecentProducts();
    },
    methods: {
        fetchRecentProducts() {
            fetch(`${this.$apiUrl}/listar-productos-ultimos`)
                .then(response => response.json())
                .then(data => {
                    this.recentProducts = data.map(product => ({
                        nombre: product.nombre,
                        fecha: product.fecha,
                        pathUrl: product.pathUrl,
                        slug: product.slug
                    }));
                })
                .catch(error => {
                    console.error('Error fetching recent products:', error);
                });
        }
    }
};
</script>
