export function updateMetaTags(to) {
    // Actualiza el título
    document.title = to.meta.title || 'Mylcom';

    // Actualiza la meta descripción
    const description = document.querySelector('meta[name="description"]');
    if (description) {
        description.setAttribute('content', to.meta.description || 'Descripción predeterminada.');
    }

    // Actualiza el enlace canonical
    let link = document.querySelector('link[rel="canonical"]');
    if (!link) {
        link = document.createElement('link');
        link.setAttribute('rel', 'canonical');
        document.head.appendChild(link);
    }
    link.setAttribute('href', to.meta.canonical || 'https://mylcom.com.pe/');

    // Actualiza Open Graph
    const ogTitle = document.querySelector('meta[property="og:title"]');
    if (ogTitle) {
        ogTitle.setAttribute('content', to.meta.title || 'Mylcom');
    }
    const ogDescription = document.querySelector('meta[property="og:description"]');
    if (ogDescription) {
        ogDescription.setAttribute('content', to.meta.description);
    }
    const ogUrl = document.querySelector('meta[property="og:url"]');
    if (ogUrl) {
        ogUrl.setAttribute('content', to.meta.canonical || window.location.href);
    }
    const ogImage = document.querySelector('meta[property="og:image"]');
    if (!ogImage) {
        const newOgImage = document.createElement('meta');
        newOgImage.setAttribute('property', 'og:image');
        newOgImage.setAttribute('content', to.meta.image );
        document.head.appendChild(newOgImage);
    } else {
        ogImage.setAttribute('content', to.meta.image);
    }
}
