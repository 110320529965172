<template>
        <div class="select-box" @click="toggleDropdown">
            <span>{{ selectedOption ? selectedOption.nombre : placeholder }}</span>
            <i class="arrow"></i>
        </div>
        <div class="dropdown" v-if="dropdownOpen">
            <ul>
                <li v-for="option in options" :key="option.slug" :data-slug="option.slug" @click="selectOption(option)">
                    {{  option.nombre }}
                </li>
            </ul>
        </div>
</template>

<script>
export default {
    emits: ['select'], // Declarar el evento emitido
    name: 'CustomSelect',
    props: {
        options: {
            type: Array,
            required: true
        },
        placeholder: {
            type: String,
            default: 'Select an option'
        },
        selectedOption: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            dropdownOpen: false
        };
    },
    methods: {
        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen;
        },
        selectOption(option) {
            this.$emit('select', option);
            this.dropdownOpen = false;
        }
    }
};
</script>

<style scoped>
.custom-select {
    position: relative;
    width: 100%;
}

.select-box {
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.select-box .arrow {
    border: solid #333;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 10;
    max-height: 200px;
    overflow-y: auto;
}

.dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dropdown li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.dropdown li:hover {
    background: linear-gradient(to right, #193C88, #4D9EC1);
    color: white;
}
</style>
