<template>
     <div class="sidebar-widget sidebar-widget-1">
        <form class="search-form" @submit.prevent>
            <input type="text" v-model="searchQuery" placeholder="Buscar" />
            <button type="submit" aria-label="Buscar"><i class="fas fa-search"></i></button>
        </form>
    </div>
    <CardListNavigation :search-query="searchQuery"></CardListNavigation>
</template>

<script>

import CardListNavigation from './navigation/CardListNavigation.vue';


export default {
    name: 'SidebarCards',
    components: {
        CardListNavigation
    },
    data() {
        return {
            searchQuery: ''
        }
    },
}

</script>

<style scoped>
.search-bar {
    margin-bottom: 20px;
}

.search-bar input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
}
</style>