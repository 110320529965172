<template>
    <Preloader v-if="loading" />
    <div v-else>
        <div class="sub-banner">
            <NavbarMenu @logout="handleLogout" @loginSuccess="checkVerification" />
            <figure class="sub-bannersideshape mb-0">
                <Image src="/banner/sub-bannersideshape.png" alt="image" />
            </figure>
            <section class="banner-section banner-section-about">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 col-md-6 col-sm-12 col-12">
                            <div class="banner_content" data-aos="fade-right">
                                <h1 class="text-white custom-standard-title"> {{ blogHead.titulo }} </h1>
                                <p class="text-white custom-standard-subtitle"> {{ blogHead.subtitulo }} </p>
                                <div class="box custom-standard-box">
                                    <span class="mb-0">Inicio</span><i class="first fa-regular fa-angle-right"></i><i
                                        class="second fa-regular fa-angle-right"></i><span class="mb-0 box_span">{{
                                            blogHead.titulo }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-sm-12 col-12">
                            <div class="banner_wrapper">
                                <figure
                                    class="mb-0 d-flex justify-content-center align-items-center sub-bannerimage-about">
                                    <ImageWeb :src="'/' + blogHead.imagen" :alt="blogHead.imagen"
                                        class="img-fluid fixed-size-blog" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <figure class="sub-bannersideshape2 mb-0">
                    <Image src="/banner/sub-bannersideshape2.png" alt="" class="img-fluid" />
                </figure>
            </section>
        </div>
        <div class="section blog-details-section section-padding">
            <div class="container">
                <div class="blog-details-wrap">
                    <div class="row sidebar-row">
                        <div class="col-lg-8">
                            <div class="blog-details-post">
                                <div class="single-blog">
                                    <div class="blog-img">
                                        <a href="#">
                                            <ImageWeb :src="'/' + post.imagen" id="post-image" alt="img-fluid" />
                                        </a>
                                        <div class="top-meta">
                                            <span class="date"><span id="date-day">{{ getDay(post.created_at) }}</span>
                                                <div id="date-month">{{ getMonth(post.created_at) }}</div>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="blog-content">
                                        <div class="blog-meta">
                                            <span><i class="fas fa-user"></i> <a href="#">{{ post.autor }}</a></span>
                                            <span class="post-year">{{ getYear(post.created_at) }}</span>

                                        </div>
                                        <h3 class="title" id="post-title" aria-level="2">{{ post.titulo }}</h3>
                                        <div class="ql-editor" v-html="post.contenido"></div>
                                    </div>
                                </div>
                                <div class="blog-details-content">
                                    <div class="blog-details-tag-share">
                                        <div class="blog-details-tag">
                                            <div class="sidebar-widget">
                                                <span class="label">Tags :</span>
                                                <ul class="sidebar-tag">
                                                    <li><router-link
                                                            :to="{ name: 'BlogSearch', params: { tipo: 'tema', slug: post.slug_tema } }">{{
                                                                post.nombre_tema }}</router-link></li>
                                                    <li><router-link
                                                            :to="{ name: 'BlogSearch', params: { tipo: 'categoria', slug: post.slug_categoria } }">{{
                                                                post.nombre_categoria }}</router-link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="comment-wrap">
                                        <div class="comment-box">
                                            <h3 class="comment-title">Comentarios ({{ comments.length }})</h3>
                                        </div>
                                        <div v-for="comment in paginatedComments" :key="comment.id"
                                            class="single-comment">
                                            <div class="comment-author">
                                                <img class="avatar" src="/logo-favicon.png" alt="Avatar">
                                                <div class="author-info">
                                                    <h5 aria-level="3">{{ comment.nombre }}</h5>
                                                    <span>{{ comment.fecha_texto }}</span>
                                                </div>
                                            </div>
                                            <p class="ql-editor">{{ comment.comentario }}</p>
                                        </div>
                                        <div class="techmax-pagination">
                                            <ul class="pagination justify-content-center">
                                                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                                    <a class="page-link" href="#" @click.prevent="prevPage"
                                                        aria-label="Página anterior">
                                                        <i class="flaticon-back"></i>
                                                    </a>
                                                </li>
                                                <li class="page-item" :class="{ active: page === currentPage }"
                                                    v-for="page in visiblePages" :key="page">
                                                    <a class="page-link" href="#" @click.prevent="goToPage(page)"
                                                        :aria-label="'Ir a la página ' + page">
                                                        {{ page }}
                                                    </a>
                                                </li>
                                                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                                    <a class="page-link" href="#" @click.prevent="nextPage"
                                                        aria-label="Página siguiente">
                                                        <i class="flaticon-next"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="comment-form">
                                        <h3 class="comment-title">Deja tu comentario</h3>
                                        <p class="ejemplo">Su dirección de correo electrónico no será publicada. Los
                                            campos obligatorios están marcados *</p>
                                        <form class="comment-form-wrap" @submit.prevent="submitComment">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="single-form">
                                                        <label for="name" class="form-label">Nombre</label>
                                                        <input class="form-control" type="text" id="name"
                                                            placeholder="Ingrese su nombre" v-model="newComment.nombre"
                                                            :disabled="isLoggedIn" required>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="single-form">
                                                        <label for="email" class="form-label">Correo</label>
                                                        <input class="form-control" type="email" id="email"
                                                            placeholder="Ingrese su correo" v-model="newComment.correo"
                                                            :disabled="isLoggedIn" required>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="single-form">
                                                        <label for="message" class="form-label">Mensaje</label>
                                                        <textarea class="form-control" id="message"
                                                            placeholder="Ingrese su mensaje" rows="4"
                                                            v-model="newComment.mensaje" required></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-btn">
                                                        <button class="btn btn-submit" type="submit">Enviar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="blog-sidebar">
                                <SidebarBlog></SidebarBlog>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showLoginForm" class="login-overlay">
            <div class="login-form-container">
                <LoginPage :show="showLoginForm" @close="closeLoginForm" @loginSuccess="handleLoginSuccess" />
                <button class="close-btn" @click="closeLoginForm">&times;</button>
            </div>
        </div>
        <FooterMenu></FooterMenu>
    </div>
</template>

<script>
import Swal from 'sweetalert2';
import { updateMetaTags } from '../../metaUtils';
import NavbarMenu from '@/components/NavbarMenu.vue';
import SidebarBlog from '@/components/SidebarBlog.vue';
import FooterMenu from '@/components/FooterMenu.vue';
import Image from '@/components/ImagePublic.vue';
import ImageWeb from '@/components/ImagePublicWeb.vue';
import Preloader from '@/components/Preloader.vue';
import LoginPage from '../components/login/LoginPage.vue';

export default {
    props: ['slug'],
    name: 'BlogPost',
    components: {
        NavbarMenu,
        SidebarBlog,
        FooterMenu,
        Image,
        ImageWeb,
        Preloader,
        LoginPage
    },
    data() {
        return {
            postId: null,
            post: {
                imagen: '',
                created_at: '',
                title: '',
                contenido: '',
                nombre_tema: '',
                nombre_categoria: '',
            },
            blogHead: {
                id: '',
                titulo: '',
                subtitulo: '',
                imagen: ''
            },
            loading: true,
            comments: [],
            newComment: {
                nombre: '',
                correo: '',
                mensaje: ''
            },
            loadingFinished1: false,
            loadingFinished2: false,
            minLoadingTime: null,
            currentPage: 1, // Página actual
            commentsPerPage: 5, // Cantidad de comentarios por página
            showLoginForm: false,
            isVerified: false, // Estado de verificación del usuario
            isLoggedIn: false, // Estado de sesión del usuario
        };
    },
    created() {
        this.minLoadingTime = new Promise((resolve) => {
            setTimeout(resolve, 750);
        });
        // this.postId = this.$route.params.postId;
        this.BuscarPost(this.slug);
        // this.Startcomment();
        this.checkVerification(); // Comprobar la verificación del usuario al cargar la página
        this.cargarHeader();
        const user = localStorage.getItem('user');
        if (user) {
            this.isLoggedIn = true;
            this.userName = JSON.parse(user).name;
        }
    },
    methods: {
        updateMeta(post) {
            const routeMeta = {
                title: 'Mylcom - Blog',
                description: post.titulo,
                image: `${this.$apiImgWeb}/images/web/${post.imagen}`, // Construye la URL completa de la imagen
                canonical: `${window.location.origin}${this.$route.fullPath}`,

            };
            updateMetaTags({ meta: routeMeta });
        },
        cargarHeader() {
            fetch(`${this.$apiUrl}/verblog/1`)
                .then((response) => response.json())
                .then((data) => {
                    this.blogHead.titulo = data.titulo;
                    this.blogHead.subtitulo = data.subtitulo;
                    this.blogHead.imagen = data.imagen;
                })
                .catch((error) => {
                    console.error('Error al cargar datos:', error);
                })
                .finally(() => {
                    this.loadingFinished1 = true;
                    this.checkLoading();
                });
        },
        BuscarPost(slug) {
            fetch(`${this.$apiUrl}/posts/details/${slug}`)
                .then((response) => response.json())
                .then((data) => {
                    this.post = data;
                    this.postId = data.id;
                    this.Startcomment(this.postId);
                    this.updateMeta(data); // Actualiza las etiquetas meta dinámicamente

                })
                .catch((error) => {
                    console.error('Error al cargar datos:', error);
                })
                .finally(() => {
                    this.loadingFinished2 = true;
                    this.checkLoading();
                });
        },
        // async BuscarPost(slug) {
        //     try {
        //         const response = await fetch(`${this.$apiUrl}/posts/details/${slug}`);
        //         const data = await response.json();
        //         this.post = data;
        //     } catch (error) {
        //         console.error('Error al cargar datos:', error);
        //     }
        // },
        Startcomment(id) {
            fetch(`${this.$apiUrl}/listar-comentarios/${id}`)
                .then((response) => response.json())
                .then((data) => {
                    this.comments = data.data;
                })
                .catch((error) => {
                    console.error('Error al cargar datos:', error);
                })
                .finally(() => {
                    this.loadingFinished1 = true;
                    this.checkLoading();
                });
        },
        getDay(dateString) {
            const date = new Date(dateString);
            return date.getUTCDate();
        },
        getMonth(dateString) {
            const date = new Date(dateString);
            const months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
            return months[date.getUTCMonth()];
        },
        getYear(dateString) {
            const date = new Date(dateString);
            return date.getUTCFullYear();
        },
        checkLoading() {
            if (this.loadingFinished1 && this.loadingFinished2) {
                this.minLoadingTime.then(() => {
                    this.loading = false;
                });
            }
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            return date.toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' });
        },
        async submitComment() {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = localStorage.getItem('authToken');
            if (!user) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Debe iniciar sesión',
                    text: 'Para dejar un comentario, debe iniciar sesión.',
                    confirmButtonText: 'Aceptar'
                }).then(() => {
                    this.showLoginForm = true;
                });
                return;
            }
            if (!this.isVerified) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Usuario no verificado',
                    text: 'Por favor, verifica tu correo electrónico antes de comentar.',
                    confirmButtonText: 'Aceptar'
                });
                return;
            }
            try {
                const response = await fetch(`${this.$apiUrl}/crear-comentario`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        comentario: this.newComment.mensaje,
                        user_id: user.id,
                        posts_id: this.postId
                    })
                });

                const data = await response.json();
                if (data.success) {
                    this.showSuccessNotification(data.message);
                    this.Startcomment(this.postId); // Volver a cargar los comentarios
                    this.resetCommentForm(true); // Reiniciar el mensaje, pero no el nombre ni el correo
                } else {
                    this.showErrorNotification('No se pudo enviar el comentario.', data.message || 'No se pudo enviar el comentario.');
                }
            } catch (error) {
                console.error(error);
                this.showErrorNotification('Error', error.message);
            }
        },
        handleLoginSuccess(response) {
            this.showLoginForm = false;
            localStorage.setItem('user', JSON.stringify(response.user));
            localStorage.setItem('authToken', response.token);
            this.checkVerification(); // Re-verificar el estado de verificación después de iniciar sesión
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        goToPage(page) {
            this.currentPage = page;
        },
        closeLoginForm() {
            this.showLoginForm = false; // Ocultar el formulario de inicio de sesión
        },
        checkVerification() {
            const user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                this.newComment.nombre = user.name;
                this.newComment.correo = user.email;
                this.isVerified = true; // Aquí podrías hacer una solicitud al servidor para verificar el estado real de verificación
                this.isLoggedIn = true;
            } else {
                this.isLoggedIn = false;
                this.isVerified = false;
            }
        },
        resetCommentForm(keepUserDetails = false) {
            if (keepUserDetails) {
                this.newComment.mensaje = '';
            } else {
                this.newComment = {
                    nombre: '',
                    correo: '',
                    mensaje: ''
                };
            }
        },
        handleLogout() {
            this.resetCommentForm();
            this.isLoggedIn = false;
            localStorage.removeItem('user'); // Eliminar los datos del usuario de localStorage
            localStorage.removeItem('authToken'); // Eliminar el token de localStorage
        },
        showSuccessNotification(message) {
            Swal.fire({
                icon: 'success',
                title: 'Éxito',
                text: message,
                confirmButtonText: 'Aceptar'
            });
        },
        showErrorNotification(title, message) {
            Swal.fire({
                icon: 'error',
                title: title,
                text: message,
                confirmButtonText: 'Aceptar'
            });
        }
    },
    beforeRouteUpdate(to, from, next) {
        if (to.params.slug !== from.params.slug) {
            this.loading = true;
            this.BuscarPost(to.params.slug);
            window.scrollTo(0, 0); // Desplazar al inicio de la ventana
        }
        next();
    },
    computed: {
        totalPages() {
            return Math.ceil(this.comments.length / this.commentsPerPage);
        },
        paginatedComments() {
            const start = (this.currentPage - 1) * this.commentsPerPage;
            const end = start + this.commentsPerPage;
            return this.comments.slice(start, end);
        },
        visiblePages() {
            const totalPagesToShow = 5;
            let startPage = this.currentPage - Math.floor(totalPagesToShow / 2);
            startPage = Math.max(startPage, 1);
            let endPage = startPage + totalPagesToShow - 1;
            if (endPage > this.totalPages) {
                endPage = this.totalPages;
                startPage = Math.max(endPage - totalPagesToShow + 1, 1);
            }
            const pages = [];
            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }
            return pages;
        }
    }
}
</script>

<style scoped>
.comment-form {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 40px;
}

.comment-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.ejemplo {
    font-size: 14px;
    color: #777;
    margin-bottom: 20px;
}

.single-form {
    margin-bottom: 20px;
}

.form-label {
    display: block;
    font-weight: 600;
    margin-bottom: 5px;
    color: #555;
}

.form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    color: #555;
    background-color: #fff;
    transition: border-color 0.3s ease;
}

.form-control:focus {
    border-color: #007bff;
    outline: none;
}

.form-btn {
    text-align: right;
}

.btn-submit {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-submit:hover {
    background-color: #0056b3;
}

.single-comment {
    margin-bottom: 20px;
}

.comment-author {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.author-info h5 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
}

.author-info span {
    font-size: 14px;
    color: #999;
}

.login-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.login-form-container {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
}
</style>
