<template>
    <section class="footer-section" id="footerstatic">
        <div class="container">
            <figure class="footer-sideshape mb-0">
                <Image src="/footer-sideshape.png" alt="" class="img-fluid" />
            </figure>
            <div class="middle-portion">
                <div class="row">
                    <div class="col-lg-4 col-md-5 col-sm-6 col-12">
                        <a href="/"  aria-label="Ir a la página principal">
                            <figure class="footer-logo">
                                <Image src="/LOGO-FV.png" alt="" class="img-fluid" />
                            </figure>
                        </a>
                        <p class="text-size-16 footer-text text-white" style="text-align: inherit ">En el competitivo
                            mundo B4B, las comunicaciones 4.0 son tu mejor aliado. Domina el juego con la tecnología que
                            lleva tu negocio más allá de los límites.</p>
                        <ul class="list-unstyled mb-0 social-icons">
                            <li class="circle">
                                <a :href="socialNetworks.facebook" target="_blank" aria-label="Visítanos en Facebook">
                                    <i class="fa-brands fa-facebook-f"></i>
                                    <span class="sr-only">Visítanos en Facebook</span>
                                </a>
                            </li>
                            <li class="circle">
                                <a :href="socialNetworks.youtube" target="_blank" aria-label="Nuestro canal de YouTube">
                                    <i class="fa-brands fa-youtube"></i>
                                    <span class="sr-only">Nuestro canal de YouTube</span>
                                </a>
                            </li>
                            <li class="circle">
                                <a :href="socialNetworks.tiktok" target="_blank" aria-label="Síguenos en TikTok">
                                    <i class="fa-brands fa-tiktok"></i>
                                    <span class="sr-only">Síguenos en TikTok</span>
                                </a>
                            </li>
                            <li class="circle">
                                <a :href="socialNetworks.instagram" target="_blank" aria-label="Síguenos en Instagram">
                                    <i class="fa-brands fa-instagram"></i>
                                    <span class="sr-only">Síguenos en Instagram</span>
                                </a>
                            </li>
                            <li class="circle">
                                <a :href="socialNetworks.x" target="_blank"
                                    aria-label="Síguenos en X (anteriormente Twitter)">
                                    <i class="fa-brands fa-twitter"></i>
                                    <span class="sr-only">Síguenos en X (anteriormente Twitter)</span>
                                </a>
                            </li>
                            <li class="circle">
                                <a :href="socialNetworks.linkedin" target="_blank" aria-label="Conéctate en LinkedIn">
                                    <i class="fa-brands fa-linkedin-in"></i>
                                    <span class="sr-only">Conéctate en LinkedIn</span>
                                </a>
                            </li>
                        </ul>

                    </div>
                    <div class="col-lg-2 col-md-3 col-sm-12 col-12 d-md-block d-none">
                        <div class="links">
                            <h4 class="heading text-white" aria-level="2">Enlaces</h4>
                            <ul class="list-unstyled mb-0">
                                <li><i class="fa-solid fa-angle-right"></i><a href="/"
                                        class=" text-size-16 text text-decoration-none">Inicio</a></li>
                                <li><i class="fa-solid fa-angle-right"></i><a href="/about"
                                        class=" text-size-16 text text-decoration-none">Nosotros</a></li>
                                <li><i class="fa-solid fa-angle-right"></i><a href="/blog"
                                        class=" text-size-16 text text-decoration-none">Blog</a></li>
                                <li><i class="fa-solid fa-angle-right"></i><a href="/portfolio"
                                        class=" text-size-16 text text-decoration-none">Portafolio</a></li>
                                <li><i class="fa-solid fa-angle-right"></i><a href="/contact-us"
                                        class=" text-size-16 text text-decoration-none">Contáctanos</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-2 col-sm-12 col-12 d-lg-block d-none">
                        <div class="links list-pd">
                            <h4 class="heading text-white" aria-level="2">Nuestros Portafolio</h4>
                            <ul class="list-unstyled mb-0">
                                <li v-for="service in latestServices" :key="service.id">
                                    <i class="fa-solid fa-angle-right"></i>
                                    <router-link :to="`/portfolio/${service.slug}`"
                                        class="text-size-16 text text-decoration-none">
                                        {{ service.titulo }}
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 d-sm-block">
                        <div class="icon">
                            <h4 class="heading text-white" aria-level="2">Contáctanos</h4>
                            <ul class="list-unstyled mb-0">
                                <li class="text">
                                    <i class="fa fa-phone fa-icon footer-location"></i>
                                    <a :href="`tel:+${socialNetworks.whatsapp}`"
                                        class="mb-0 text text-decoration-none text-size-16">+{{ socialNetworks.whatsapp
                                        }}</a>
                                </li>
                                <li class="text">
                                    <i class="fa fa-envelope fa-icon footer-location"></i>
                                    <a href="mailto:ventas@mylcom.com.pe"
                                        class="mb-0 text text-decoration-none text-size-16">ventas@mylcom.com.pe</a>
                                </li>
                                <li class="text">
                                    <i class="fa-solid fa-location-dot footer-location footer-location3"></i>
                                    <a href="https://www.google.com/maps/search/?api=1&query=Jr.+Washington+1206+-+Cercado+de+Lima"
                                        target="_blank" class="mb-0 text text-decoration-none text-size-16">
                                        Jr. Washington 1206 - Cercado de Lima
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div id="fixed-form-container">
                <SocialNetwork />
            </div>
            <div class="copyright">
                <div class="row">
                    <div class="col-12">
                        <p class="mb-0 text-white">Copyright 2024, MYL Comunicaciones Derechos Reservados.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Image from '@/components/ImagePublic.vue';
import SocialNetwork from '@/view/components/widgets/SocialNetwork.vue';

export default {
    components: {
        Image,
        SocialNetwork
    },
    data() {
        return {
            socialNetworks: {
                facebook: '',
                youtube: '',
                tiktok: '',
                instagram: '',
                x: '',
                linkedin: '',
                whatsapp: '',
                phone: '',
            },
            latestServices: []
        };
    },
    methods: {
        fetchSocialNetworks(id) {
            fetch(`${this.$apiUrl}/mostrar-redes/${id}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    this.socialNetworks = data;
                    this.error = null;
                })
                .catch(error => {
                    this.error = 'Error al cargar los datos. Por favor, intenta nuevamente más tarde.';
                    console.error('Error al cargar los datos:', error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        fetchLatestServices() {
            fetch(`${this.$apiUrl}/listar-cards-all`)
                .then(response => response.json())
                .then(data => {
                    this.latestServices = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).slice(0, 5);
                })
                .catch(error => {
                    console.error('Error al cargar los servicios:', error);
                });
        }
    },
    mounted() {
        this.fetchSocialNetworks(1);
        this.fetchLatestServices();
    }
};
</script>

<style scoped>
.whatsapp-btn {
    position: absolute;
    ;
    bottom: 64px;
    right: 90px;
    z-index: 1;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #25D366;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    animation: breathe 2s ease-in-out infinite;
    transition: all 0.3s ease-in-out;
}

.whatsapp-btn i {
    color: #fff;
    font-size: 30px;
    animation: beat 2s ease-in-out infinite;
    text-decoration: none;
}

@keyframes breathe {
    0% {
        box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
    }

    70% {
        box-shadow: 0 0 0 15px rgba(37, 211, 102, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

@keyframes beat {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

@media screen and (max-width: 768px) {
    .whatsapp-btn {
        width: 100px;
        height: 100px;
        bottom: 50px;
        right: 50px;
    }

    .whatsapp-btn i {
        font-size: 30px;
    }
}

@media screen and (max-width: 576px) {
    .whatsapp-btn {
        width: 70px;
        height: 70px;
        bottom: 40px;
        right: 40px;
    }

    .whatsapp-btn i {
        font-size: 30px;
    }
}
</style>
