<template>
    <article class="elemento-card">
        <div class="elemento-post-card">
            <router-link :to="postLink" class="elemento-post-thumbnail" tabindex="-1" data-wpel-link="internal">
                <div class="elemento-post-thumbnail-contain" style="width: 538px; height: 409px;">
                    <ImageWeb loading="lazy" :src="'/' + postImage" :alt="postTitle" />
                </div>
            </router-link>
            <div class="elemento-post-text">
                <h3 class="elemento-post-title" role="heading" aria-level="3">
                    <router-link :to="postLink" data-wpel-link="internal">
                        {{ postTitle }}
                    </router-link>
                </h3>
                <div class="elemento-post-content">
                    <p>{{ postContent }}</p>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
import ImageWeb from '@/components/ImagePublicWeb.vue';

export default {
    name: 'ArticleCard',
    components: {
        ImageWeb,
    },
    props: {
        postTitle: {
            type: String,
            required: true
        },
        postLink: {
            type: String,
            default: '#'
        },
        postImage: {
            type: String,
        },
        postContent: {
            type: String,
        }
    }
}
</script>

<style scoped>
</style>
