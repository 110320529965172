<template>
    <header class="header">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-lg navbar-light">
                <router-link class="navbar-brand" :to="{ name: 'HomePage' }">
                    <figure class="banner-logo">
                        <Image src="/LOGO-FV.png" alt="Logo FV" class="img-fluid" width="200" height="100" />
                    </figure>
                </router-link>
                <button class="navbar-toggler collapsed" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                    <span class="navbar-toggler-icon"></span>
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div v-if="isLoggedIn" class="nav-item d-lg-none ml-2">
                    <a class="nav-link" href="#" @click="toggleUserDropdown">
                        <i class="fa fa-user-circle" aria-hidden="true"></i>
                    </a>
                    <div v-show="isUserDropdownVisible" class="dropdown-menu dropdown-menu-right show">
                        <a class="dropdown-item disabled" href="#"><i class="fa fa-user"></i> {{ userName }}</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#" @click="logout"><i class="fa fa-sign-out-alt"></i> Salir</a>
                    </div>
                </div>
                <div v-else class="nav-item d-lg-none ml-2">
                    <a class="nav-link" href="#" @click.prevent="showLoginModal"><i class="fa fa-sign-in-alt"
                            aria-hidden="true"></i></a>
                </div>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item" :class="{ 'active': isCurrentRoute('/') }">
                            <a class="nav-link" href="/">Inicio</a>
                        </li>
                        <li class="nav-space nav-item dropdown" :class="{ 'active': isCurrentRoute('/about') }">
                            <a class="nav-link dropdown-toggle dropdown-color navbar-text-color" href="#"
                                id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false"> Nosotros </a>
                            <div class="dropdown-menu drop-down-content">
                                <ul class="list-unstyled drop-down-pages">
                                    <li class="nav-item">
                                        <a class="dropdown-item nav-link"
                                            :class="{ 'active': isCurrentRoute('/about') }" href="../about">Mylcom</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="dropdown-item nav-link"
                                            :class="{ 'active': isCurrentRoute('/partners') }"
                                            href="/partners">Partner</a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="nav-space nav-item dropdown" :class="{ 'active': isCurrentRoute('/blog') }">
                            <a class="nav-link dropdown-toggle dropdown-color navbar-text-color" href="#"
                                id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false"> Novedades </a>
                            <div class="dropdown-menu drop-down-content">
                                <ul class="list-unstyled drop-down-pages">
                                    <li class="nav-item">
                                        <a class="dropdown-item nav-link" :class="{ 'active': isCurrentRoute('/blog') }"
                                            href="/blog">Blog</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="dropdown-item nav-link"
                                            :class="{ 'active': isCurrentRoute('/news') }"
                                            href="/news">Noticias</a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="nav-space nav-item dropdown" :class="{ 'active': isCurrentRoute('/portfolio') }">
                            <a class="nav-link dropdown-toggle dropdown-color navbar-text-color" href="#"
                                id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false"> Servicios </a>
                            <div class="dropdown-menu drop-down-content">
                                <ul class="list-unstyled drop-down-pages">
                                    <li class="nav-item">
                                        <a class="dropdown-item nav-link" :class="{ 'active': isCurrentRoute('/portfolio') }"
                                            href="/portfolio">Portafolio</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="dropdown-item nav-link"
                                            :class="{ 'active': isCurrentRoute('/solutions') }"
                                            href="/solutions">Soluciones</a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <!-- <li class="nav-item" :class="{ 'active': isCurrentRoute('/portafolio') }">
                            <a class="nav-link" href="/portafolio">Portafolio</a>
                        </li> -->
                        <li class="nav-item" :class="{ 'active': isCurrentRoute('/products') }">
                            <a class="nav-link" href="/products">Productos</a>
                        </li>
                        <li class="nav-item" :class="{ 'active': isCurrentRoute('/contact-us') }">
                            <!-- <ButtonGradient buttonText="Contáctanos" href="/contact-us" /> -->
                             <a class="nav-link" href="/contact-us">Contáctanos</a>
                        </li> 
                        <li v-if="isLoggedIn" class="nav-item dropdown user-dropdown d-none d-lg-block">
                            <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-user-circle" aria-hidden="true"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                                <a class="dropdown-item disabled" href="#"><i class="fa fa-user"></i> {{ userName }}</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#" @click="logout"><i class="fa fa-sign-out-alt"></i>
                                    Salir</a>
                            </div>
                        </li>
                        <li v-else class="nav-item d-none d-lg-block" :class="{ 'active': isCurrentRoute('/login') }">
                            <a class="nav-link" href="#" @click.prevent="showLoginModal"><i class="fa fa-sign-in-alt"
                                    aria-hidden="true"></i> Iniciar Sesión</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
        <LoginPage :show="isLoginModalVisible" @close="isLoginModalVisible = false"
            @loginSuccess="handleLoginSuccess" />
    </header>
</template>

<script>
// import ButtonGradient from '@/view/components/widgets/ButtonGradient.vue';
import Image from '@/components/ImagePublic.vue';
import LoginPage from '@/view/components/login/LoginPage.vue';

export default {
    name: 'NavbarMenu',
    components: {
        // ButtonGradient,
        Image,
        LoginPage
    },
    data() {
        return {
            userName: '',
            isLoggedIn: false,
            isLoginModalVisible: false,
            isUserDropdownVisible: false
        }
    },
    methods: {
        isCurrentRoute(route) {
            return this.$route.path === route;
        },
        showLoginModal() {
            this.isLoginModalVisible = true;
        },
        handleLoginSuccess(response) {
            this.isLoggedIn = true;
            this.userName = response.user.name;
            this.isLoginModalVisible = false;
            this.$emit('loginSuccess');
        },
        toggleUserDropdown() {
            this.isUserDropdownVisible = !this.isUserDropdownVisible;
        },
        async logout() {
            const token = localStorage.getItem('authToken');
            console.log('Auth token:', token);  // Verificar el token
            if (!token) {
                console.error('No auth token found');
                return;
            }

            try {
                const response = await fetch(`${this.$apiUrl}/logout`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        // 'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Logout failed');
                }

                const data = await response.json();
                if (data.success) {
                    localStorage.removeItem('user');
                    localStorage.removeItem('authToken');
                    this.isLoggedIn = false;
                    this.isUserDropdownVisible = false;
                    this.$emit('logout');
                    this.$router.push(this.$route.path);
                } else {
                    throw new Error('Logout failed');
                }
            } catch (error) {
                console.error('Error al cerrar sesión:', error);
            }
        }
    },
    created() {
        const user = localStorage.getItem('user');
        if (user) {
            this.isLoggedIn = true;
            this.userName = JSON.parse(user).name;
        }
    }
}
</script>

<style scoped>
.navbar .fa-user-circle {
    font-size: 24px;
}

.fa-sign-in-alt{
    color: #fff;
}

.dropdown-menu-right {
    right: 0;
}

.dropdown-item.disabled {
    cursor: default;
    color: #6c757d;
    /* margin-top: 10px; */
}

.dropdown-divider {
    margin: 5px;
}

.user-dropdown .dropdown-menu {
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.user-dropdown .dropdown-item {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.user-dropdown .dropdown-item i {
    margin-right: 8px;
}


@media (max-width: 992px) {
    .nav-item.d-lg-none .navbar-nav li:last-child {
        font-size: 16px;
        padding: 0.5rem 1rem;
    }

    .dropdown-menu {
        position: absolute;
        top: 60%;
        z-index: 1000;
        display: none;
        float: left;
        min-width: 10rem;
        padding: .5rem 0;
        margin: .125rem 0 0;
        font-size: 1rem;
        color: #212529;
        text-align: left;
        list-style: none;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, .15);
        border-radius: .25rem;
    }

    .dropdown-menu.show {
        display: block;
    }
}
</style>
