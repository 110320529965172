<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  created() {
    // Mensaje de advertencia para la consola del navegador
    console.log('%c¡Detente!', 'color: red; font-size: 40px; font-weight: bold;');
    console.log('%cSi utilizas esta consola, otras personas podrían hacerse pasar por ti y robarte datos mediante un ataque Self-XSS.No escribas ni pegues ningún código que no entiendas.', 'color: white; font-size: 20px;');
  }
}
</script>

<style></style>