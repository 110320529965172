<template>
    <div class="tooltip-container">
        <a :href="`https://api.whatsapp.com/send?phone=${socialNetworks.whatsapp}&text=¡Hola! ¿Podrías proporcionarme más detalles sobre sus productos? ¡Gracias!`"
            target="_blank" class="whatsapp-btn" aria-label="Enviar mensaje por WhatsApp">
            <span class="text">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" class="bi bi-whatsapp icon"
                    viewBox="0 0 16 16">
                    <path
                        d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232">
                    </path>
                </svg>
                <span class="sr-only">Enviar mensaje por WhatsApp</span>
            </span>
        </a>
        <a :href="socialNetworks.facebook" target="_blank" aria-label="Visítanos en Facebook">
            <span class="tooltip2 tooltip">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" class="bi bi-facebook icon"
                    viewBox="0 0 16 16">
                    <path
                        d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951">
                    </path>
                </svg>
                <span class="sr-only">Visítanos en Facebook</span>
            </span>
        </a>
        <a :href="socialNetworks.instagram" target="_blank" aria-label="Síguenos en Instagram">
            <span class="tooltip3 tooltip">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="30" height="30"
                    class="instagram icon">
                    <path
                        d="M128 15.68c34.13 0 38.14.13 51.5.74 12.43.57 19.13 2.64 23.61 4.4 5.94 2.3 10.2 5.05 14.67 9.52 4.47 4.47 7.22 8.73 9.52 14.67 1.76 4.48 3.83 11.18 4.4 23.61.61 13.36.74 17.37.74 51.5s-.13 38.14-.74 51.5c-.57 12.43-2.64 19.13-4.4 23.61-2.3 5.94-5.05 10.2-9.52 14.67-4.47 4.47-8.73 7.22-14.67 9.52-4.48 1.76-11.18 3.83-23.61 4.4-13.36.61-17.37.74-51.5.74s-38.14-.13-51.5-.74c-12.43-.57-19.13-2.64-23.61-4.4-5.94-2.3-10.2-5.05-14.67-9.52-4.47-4.47-7.22-8.73-9.52-14.67-1.76-4.48-3.83-11.18-4.4-23.61-.61-13.36-.74-17.37-.74-51.5s.13-38.14.74-51.5c.57-12.43 2.64-19.13 4.4-23.61 2.3-5.94 5.05-10.2 9.52-14.67 4.47-4.47 8.73-7.22 14.67-9.52 4.48-1.76 11.18-3.83 23.61-4.4 13.36-.61 17.37-.74 51.5-.74M128 0c-34.55 0-38.82.15-52.38.76-13.54.61-22.75 2.78-30.88 5.94-8.4 3.27-15.52 7.64-22.62 14.74C15.4 28.53 11.03 35.65 7.76 44.05c-3.16 8.13-5.33 17.34-5.94 30.88C1.15 88.49 1 92.76 1 127.31s.15 38.82.76 52.38c.61 13.54 2.78 22.75 5.94 30.88 3.27 8.4 7.64 15.52 14.74 22.62 7.1 7.1 14.22 11.47 22.62 14.74 8.13 3.16 17.34 5.33 30.88 5.94 13.55.61 17.82.76 52.38.76s38.82-.15 52.38-.76c13.54-.61 22.75-2.78 30.88-5.94 8.4-3.27 15.52-7.64 22.62-14.74 7.1-7.1 11.47-14.22 14.74-22.62 3.16-8.13 5.33-17.34 5.94-30.88.61-13.55.76-17.82.76-52.38s-.15-38.82-.76-52.38c-.61-13.54-2.78-22.75-5.94-30.88-3.27-8.4-7.64-15.52-14.74-22.62-7.1-7.1-14.22-11.47-22.62-14.74-8.13-3.16-17.34-5.33-30.88-5.94-13.56-.61-17.83-.76-52.38-.76zm0 62.16c-36.29 0-65.84 29.55-65.84 65.84S91.71 193.84 128 193.84 193.84 164.29 193.84 128 164.29 62.16 128 62.16zm0 108.23c-23.35 0-42.39-19.04-42.39-42.39s19.04-42.39 42.39-42.39 42.39 19.04 42.39 42.39-19.04 42.39-42.39 42.39zm84.26-112.75c-8.48 0-15.37-6.89-15.37-15.37s6.89-15.37 15.37-15.37 15.37 6.89 15.37 15.37-6.89 15.37-15.37 15.37z">
                    </path>
                </svg>
                <span class="sr-only">Síguenos en Instagram</span>
            </span>
        </a>
        <a :href="socialNetworks.tiktok" target="_blank" aria-label="Síguenos en TikTok">
            <span class="tooltip4 tooltip">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" class="bi bi-tiktok icon"
                    viewBox="0 0 512 512" id="icons">
                    <path
                        d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z" />
                </svg>
                <span class="sr-only">Síguenos en TikTok</span>
            </span>
        </a>
        <a :href="socialNetworks.youtube" target="_blank" aria-label="Nuestro canal de YouTube">
            <span class="tooltip5 tooltip">
                <svg viewBox="0 0 576 512" width="30" height="30" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z">
                    </path>
                </svg>
                <span class="sr-only">Nuestro canal de YouTube</span>
            </span>
        </a>
    </div>
</template>

<script>
export default {
    name: 'SocialNetwork',
    data() {
        return {
            socialNetworks: {
                facebook: '',
                instagram: '',
                tiktok: '',
                youtube: '',
                whatsapp: ''
            },
            loadingFinished: false
        };
    },
    methods: {
        fetchSocialNetworks(id) {
            fetch(`${this.$apiUrl}/mostrar-redes/${id}`)
                .then(response => response.json())
                .then(data => {
                    this.socialNetworks = data;
                })
                .catch(error => {
                    console.error('Error al cargar los datos:', error);
                })
                .finally(() => {
                    this.loadingFinished = true;
                });
        }
    },
    mounted() {
        this.fetchSocialNetworks(1);
    }
};
</script>

<style scoped>
.tooltip-container {
    fill: #1db954;
    background: #1db954;
    position: relative;
    cursor: pointer;
    font-size: 17px;
    padding: 1.5em;
    position: absolute;
    bottom: 64px;
    right: 90px;
    z-index: 1;
    border-radius: 50%;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: beat 2s ease-in-out infinite;
}

.tooltip-container:hover {
    background: #1db954;
    transition: all 0.6s;
}

.tooltip-container .text {
    display: flex;
    align-items: center;
    justify-content: center;
    fill: #fff;
    transition: all 0.2s;
}

.tooltip-container:hover .text {
    fill: #fff;
    transition: all 0.6s;
}

.tooltip {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    padding: 10px;
    border-radius: 50px;
    transition: opacity 0.3s, visibility 0.3s;
    z-index: 1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
}

.tooltip2 {
    fill: #0462df;
}

.tooltip3 {
    fill: #E1306C;
}

.tooltip4 {
    fill: #000000;
}

.tooltip5 {
    fill: #FF0000;
}

.tooltip2 {
    bottom: calc(100% + 20px);
    left: 50%;
    transform: translateX(-50%);
}

.tooltip3 {
    bottom: calc(200% + 20px);
    left: 50%;
    transform: translateX(-50%);
}

.tooltip4 {
    bottom: calc(300% + 20px);
    left: 50%;
    transform: translateX(-50%);
}

.tooltip5 {
    bottom: calc(400% + 20px);
    left: 50%;
    transform: translateX(-50%);
}

.tooltip-container:hover .tooltip {
    opacity: 1;
    visibility: visible;
}

.tooltip-container:hover .tooltip:hover {
    background: #03a9f4;
    fill: #fff;
}

.tooltip-container:hover .tooltip2:hover {
    background: #0462df;
    fill: #fff;
}

.tooltip-container:hover .tooltip3:hover {
    background: #E1306C;
    fill: #fff;
}

.tooltip-container:hover .tooltip4:hover {
    background: #000000;
    fill: #fff;
}

.tooltip-container:hover .tooltip5:hover {
    background: #FF0000;
    fill: #fff;
}

.icon {
    width: 30px;
    height: 30px;
}

@keyframes beat {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

@media screen and (max-width: 768px) {
    .tooltip-container {
        width: 80px;
        height: 80px;
        bottom: 50px;
        right: 50px;
    }

    .whatsapp-btn i {
        font-size: 30px;
    }

    .tooltip-container a:nth-child(n+2) {
        display: none;
    }
}

@media screen and (max-width: 576px) {
    .tooltip-container {
        width: 70px;
        height: 70px;
        bottom: 40px;
        right: 40px;
    }

    .whatsapp-btn i {
        font-size: 30px;
    }
}
</style>