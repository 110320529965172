<template>
    <div class="email-verification">
        <div v-if="isLoading" class="loader">Cargando...</div>
        <template v-else>
            <h1>{{ title }}</h1>
            <p v-if="message">{{ message }}</p>
            <p v-if="text">{{ text }}</p>
        </template>
    </div>
</template>

<script>
export default {
    props: ['id', 'hash'],
    name: 'EmailVerification',
    data() {
        return {
            isLoading: true,
            title: '',
            message: '',
            text: ''
        };
    },
    mounted() {
        this.verifyEmail();
    },
    methods: {
        verifyEmail() {
            fetch(`${this.$apiUrl}/email/verify/${this.id}/${this.hash}`, {
                method: 'GET',
                headers: {
                    // 'Authorization': `Bearer ${authToken}`,
                    'Accept':'application/json'
                },
                //credentials: 'include',
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    if (data.success) {
                        this.title = 'Felicidades';
                        this.message = data.message;
                        this.text = 'Dentro de 3 segundos se te redireccionará a la página de inicio.';
                        setTimeout(() => {
                            this.$router.push('/');
                        }, 3000);
                    } else {
                        throw new Error('Verification failed');
                    }
                })
                .catch(error => {
                    this.message = 'Error al verificar el correo. Por favor, intenta nuevamente.';
                    this.title = 'Intente de nuevo';
                    console.error('Error during email verification:', error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }
}
</script>

<style scoped>
.email-verification {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    color: #5A5C60;
}

.email-verification h1 {
    font-size: 24px;
}

.email-verification p {
    font-size: 18px;
}

.loader {
    font-size: 18px;
}
</style>
