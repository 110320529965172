<template>
    <div class="sidebar-widget-list">
        <ul class="category" style="padding: 0; list-style: none; margin: 0;">
            <li v-if="filteredTemas.length === 0">No hay resultados</li>
            <li class="cate-item" v-for="(tema, index) in paginatedTemas" :key="index">
                <router-link :to="{ name: 'BlogSearch', params: { tipo: 'tema', slug: tema.slug } }">
                    <i class="flaticon-next"></i>
                    {{ tema.nom_temas }}
                </router-link>
            </li>
        </ul>
        <div class="pagination-controls">
            <span @click="previousPage" :class="{ disabled: currentPage === 1 }">
                <i class="fas fa-chevron-left"></i>
            </span>
            <span @click="nextPage" :class="{ disabled: currentPage === totalPages }">
                <i class="fas fa-chevron-right"></i>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TemasNavigation',
    props: ['searchQuery'],
    data() {
        return {
            temas: [],
            allTemas: [],
            currentPage: 1,
            temaPage: 5,
        };
    },
    computed: {
        filteredTemas() {
            const query = this.normalizeText(this.searchQuery.toLowerCase());
            return this.allTemas.filter(tema => this.normalizeText(tema.nom_temas.toLowerCase()).includes(query));
        },
        paginatedTemas() {
            const start = (this.currentPage - 1) * this.temaPage;
            const end = start + this.temaPage;
            return this.filteredTemas.slice(start, end);
        },
        totalPages() {
            return Math.ceil(this.filteredTemas.length / this.temaPage);
        }
    },
    created() {
        this.cargarTodosTemas();
    },
    methods: {
        cargarTodosTemas() {
            let page = 1;
            const fetchPage = (page) => {
                fetch(`${this.$apiUrl}/listar-temas-post-web?page=${page}&tema_page=${this.temaPage}`)
                    .then((response) => response.json())
                    .then((data) => {
                        this.allTemas = [...this.allTemas, ...data.data];
                        if (page < data.last_page) {
                            fetchPage(page + 1);
                        }
                    })
                    .catch((error) => console.error('Error al cargar datos:', error));
            };
            fetchPage(page);
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        normalizeText(text) {
            return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        },
        selectTema(temaId) {
            this.$emit('select-tema', temaId);
        }
    },
};
</script>

<style scoped>
.pagination-controls {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.pagination-controls span {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
    text-decoration: none;
}

.pagination-controls span i {
    font-size: 1em;
}

.pagination-controls span.disabled {
    cursor: not-allowed;
    color: gray;
}
</style>
