<template>
    <div class="sidebar-widget sidebar-widget-1">
        <form class="search-form" @submit.prevent>
            <input type="text" v-model="searchQuery" placeholder="Buscar" @input="handleSearch" />
            <button type="submit" aria-label="Buscar"><i class="fas fa-search"></i></button>
        </form>
        <div class="filter-section">
            <div class="filter-category filter-item">
                <CustomSelect :options="categories" placeholder="Categoría" @select="handleCategorySelect"
                :selectedOption="selectedCategoryObj" />
            </div>
            <div class="filter-frame-type filter-item">
                <CustomSelect :options="frameTypes" placeholder="Marca" @select="handleFrameTypeSelect"
                :selectedOption="selectedFrameTypeObj" />
            </div>
        </div>
    </div>
</template>

<script>
import CustomSelect from './navigation/CustomSelect.vue';

export default {
    emits: ['categorySelect', 'frameTypeSelect', 'search'],
    name: 'SidebarStore',
    components: {
        CustomSelect
    },
    props: {
        onCategorySelect: {
            type: Function,
            required: true
        },
        onFrameTypeSelect: {
            type: Function,
            required: true
        },
        onSearch: {
            type: Function,
            required: true
        },
        selectedCategory: {
            type: String,
            default: null
        },
        selectedFrameType: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            searchQuery: '',
            categories: [],
            frameTypes: [],
            isMobile: window.innerWidth <= 768
        }
    },
    computed: {
        selectedCategoryObj() {
            return this.categories.find(category => category.slug === this.selectedCategory) || null;
        },
        selectedFrameTypeObj() {
            return this.frameTypes.find(frameType => frameType.slug === this.selectedFrameType) || null;
        }
    },
    methods: {
        handleSearch() {
            this.onSearch(this.searchQuery);
        },
        handleCategorySelect(category) {
            this.onCategorySelect(category);
        },
        handleFrameTypeSelect(frameType) {
            this.onFrameTypeSelect(frameType);
        },
        handleResize() {
            this.isMobile = window.innerWidth <= 768;
        },
        fetchCategories() {
            let page = 1;
            const fetchPage = () => {
                fetch(`${this.$apiUrl}/listar-categorias`)
                    .then(response => response.json())
                    .then(data => {
                        this.categories = data;
                    })
                    .catch(error => {
                        console.error('Error al cargar datos:', error);
                    });
            };
            fetchPage(page);
        },
        fetchFrameTypes() {
            fetch(`${this.$apiUrl}/listar-marcas-productos`)
                .then(response => response.json())
                .then(data => {
                    this.frameTypes = data;
                })
                .catch(error => {
                    console.error('Error al cargar datos:', error);
                });
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.fetchCategories();
        this.fetchFrameTypes();
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    }
}
</script>

<style scoped>
.search-form {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.search-form input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin-right: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.filter-section {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    position: relative;
    margin-bottom: 20px;
}

.filter-item {
    flex: 1;
    position: relative;
}
</style>
